import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../../services/api";
import Modal from "../../../../components/modal";
import Table from "../../../../components/Table";
import Paginator from "../../../../components/Paginator";

import CompanyModal from "../../component/companyModal2";

export default ({ campaign }) => {
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;
    newFilters.campaign_id = campaign._id;

    const { data, total, ok } = await api.post("/s_company/search", newFilters);
    if (!ok) return toast.error("Error fetching companies");
    setCompanies(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <CreateCompany onDone={fetch} campaign={campaign} />
      </div>
      <div>{total} items</div>
      <CompanyModal
        company={selectedCompany}
        setCompany={(e) => {
          if(!e) return setSelectedCompany(null);
          const index = companies.findIndex((c) => c._id === e._id);
          companies[index] = e;
          setCompanies([...companies]);
        }}
      />
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },
          { title: "Social", key: "social" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {companies.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedCompany(item);
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex space-x-2">
                  {item.linkedin && (
                    <a href={item.linkedin} target="_blank" rel="noopener noreferrer">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-5" />
                    </a>
                  )}
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.created_at}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);
  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};

const CreateCompany = ({ onDone, campaign }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onSubmit() {
    try {
      const obj = { ...values, campaigns: [{ name: campaign.name, id: campaign._id }] };
      const res = await api.post("/s_company", obj);
      if (!res.ok) throw res;
      toast.success("Client created");

      setOpen(false);
      onDone();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }
  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={() => setOpen(true)}>
        Create a company
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div>
          <div className="flex flex-col justify-between flex-wrap">
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium	">Name</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="name"
                value={values.name}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="w-full flex justify-end">
          <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};
