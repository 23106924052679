import React, { useState, useEffect } from "react";

export default ({ quote }) => {
  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center ">
        <label className="text-lg font-semibold">Generated files</label>
      </div>

      <div className="gap-4 p-4">
        {quote.versions_files
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((v, i) => (
            <a href={v.file} target="_blank" key={i} item={v} className="flex items-center justify-center p-4 border rounded-md shadow-sm cursor-pointer hover:bg-gray-50 bg-white">
              <div className="flex flex-col items-center">
                <span className="mt-2 text-sm whitespace-nowrap">{new Date(v.created_at).toLocaleString()}</span>
                <span className="mt-2 text-sm whitespace-nowrap">{v.user_name}</span>
              </div>
            </a>
          ))}
      </div>
    </>
  );
};
