import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { toPng } from "html-to-image";

import api from "../../../../services/api";
import Loader from "../../../../components/loader";
// import SendModal from "../../../../components/SendModal";
import Tags from "../../../../components/Tags";
import SelectProject from "../../../../components/selectProject";
import { TEXT_BLOCK, TABLE_BLOCK, CARDS_BLOCK, TWO_COLS } from "../../constant";

import SelectUser from "../../../../components/selectUser";

export default ({ quote, pages, blocks, setQuote }) => {
  const [values, setValues] = useState(quote);
  const [projects, setProjects] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadProjects();
  }, []);

  const loadProjects = async () => {
    try {
      const { data } = await api.post("/project/search", { per_page: 1000 });
      setProjects(data.projects);
    } catch (error) {
      console.error("Error loading projects:", error);
      toast.error("Error loading projects");
    }
  };

  const handleDuplicate = async () => {
    try {
      const { ok, data } = await api.post(`/quote/duplicate`, { ...values });
      if (!ok) throw new Error("Error duplicating quote");
      navigate("/quote");
      toast.success("Quote duplicated");
    } catch (error) {
      console.error(error);
      toast.error("Error duplicating quote");
    }
  };

  const handleSubmit = async () => {
    try {
      values.thumbnail = await generateThumbnail(pages);
      const { ok, data } = await api.put(`/quote/${quote._id}`, values);
      if (!ok) throw new Error("Error updating quote");
      setQuote(data);
      toast.success("Quote updated");
    } catch (error) {
      console.error(error);
      toast.error("Error updating quote");
    }
  };

  const handleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this quote?")) return;
      const { ok } = await api.remove(`/quote/${quote._id}`);
      if (!ok) return toast.error("Error while deleting quote");
      toast.success("Quote deleted");
      navigate("/quote");
    } catch (error) {
      console.error(error);
      toast.error("Error while deleting quote");
    }
  };

  const handlePrint = async () => {
    setDownloading(true);
    try {
      const { ok, data } = await api.post(`/quote/${quote._id}/pdf`);
      if (!ok) throw new Error("Error generating PDF");
      setQuote(data);
      toast.success("PDF generated");
      window.open(data.file, "_blank");
    } catch (error) {
      console.error(error);
      toast.error("Failed to generate PDF");
    }
    setDownloading(false);
  };

  const handleAI = async () => {
    const lang = quote.lang;

    const getTexts = (blocks) => {
      const otherLang = values.lang === "fr" ? "en" : "fr";
      const texts = [];
      for (const block of blocks) {
        let toTranslate;
        let textCurrentLang;
        let textOtherLang;
        switch (block.type) {
          case "text":
            textCurrentLang = block.description[lang] ? block.description[lang].trim() : "";
            textOtherLang = block.description[lang] ? block.description[otherLang].trim() : "";
            if (textCurrentLang && textCurrentLang !== TEXT_BLOCK.description[lang]) toTranslate = textCurrentLang;
            else toTranslate = textOtherLang;
            texts.push({ id: block._id, field: "description", value: toTranslate });
            break;
          case "two_cols_presentation":
            if (block.layout === "text-image" || block.layout === "text-text") {
              textCurrentLang = block.text_left[lang] ? block.text_left[lang].trim() : "";
              textOtherLang = block.text_left[lang] ? block.text_left[otherLang].trim() : "";
              if (textCurrentLang && textCurrentLang !== TWO_COLS.text_left[lang]) toTranslate = textCurrentLang;
              else toTranslate = textOtherLang;
              texts.push({ id: block._id, field: "text_left", value: toTranslate });
            }
            if (block.layout === "image-text" || block.layout === "text-text") {
              textCurrentLang = block.text_right[lang] ? block.text_right[lang].trim() : "";
              textOtherLang = block.text_right[lang] ? block.text_right[otherLang].trim() : "";
              if (textCurrentLang && textCurrentLang !== TWO_COLS.text_right[lang]) toTranslate = textCurrentLang;
              else toTranslate = textOtherLang;
              texts.push({ id: block._id, field: "text_right", value: toTranslate });
            }
            break;
          case "table":
            for (let i = 0; i < block.arr.length; i++) {
              textCurrentLang = block.arr[i].description[lang] ? block.arr[i].description[lang].trim() : "";
              textOtherLang = block.arr[i].description[lang] ? block.arr[i].description[otherLang].trim() : "";
              if (textCurrentLang && textCurrentLang !== TABLE_BLOCK.arr[0].description[lang]) toTranslate = textCurrentLang;
              else toTranslate = textOtherLang;
              texts.push({ id: block._id, field: `arr[${i}].description`, value: toTranslate });
            }
            break;
          case "cards":
            for (let i = 0; i < block.cards.length; i++) {
              textCurrentLang = block.cards[i].title[lang] ? block.cards[i].title[lang].trim() : "";
              textOtherLang = block.cards[i].title[lang] ? block.cards[i].title[otherLang].trim() : "";
              if (textCurrentLang && textCurrentLang !== CARDS_BLOCK.cards[0].title[lang]) toTranslate = textCurrentLang;
              else toTranslate = textOtherLang;
              texts.push({ id: block._id, field: `cards[${i}].title`, value: toTranslate });
              textCurrentLang = block.cards[i].description[lang] ? block.cards[i].description[lang].trim() : "";
              textOtherLang = block.cards[i].description[lang] ? block.cards[i].description[otherLang].trim() : "";
              if (textCurrentLang && textCurrentLang !== CARDS_BLOCK.cards.description[0][lang]) toTranslate = textCurrentLang;
              else toTranslate = textOtherLang;
              texts.push({ id: block._id, field: `cards[${i}].description`, value: toTranslate });
            }
            break;
        }
      }

      return texts;
    };

    const setTexts = async (blocks, texts) => {
      for (const text of texts) {
        const block = blocks.find((b) => b._id === text.id);
        const type = block.type;
        switch (type) {
          case "text":
            block[text.field][lang] = text.translation;
            break;
          case "two_cols_presentation":
            block[text.field][lang] = text.translation;
            break;
          case "table":
            block.arr[text.field.split("[")[1].split("]")[0]].description[lang] = text.translation;
            break;
          case "cards":
            block.cards[text.field.split("[")[1].split("]")[0]][text.field.split(".")[1]][lang] = text.translation;
            break;
        }

        try {
          const { data } = await api.put(`/quote_block/${block._id}`, block);
        } catch (error) {
          console.error(error);
          toast.error("Error updating block");
        }
      }
    };

    const texts = getTexts(blocks);

    for (let [index, text] of texts.entries()) {
      try {
        const toastId = toast.loading(`Translating ${index + 1}/${texts.length} ...`);

        const { ok, data: answer } = await api.post(`/quote/translate`, { text: text.value, lang: lang });

        if (!ok) {
          toast.error("Error translating with AI", { id: toastId });
          throw new Error("Error translating with AI");
        }
        text.translation = answer;
        toast.success(`Block ${index + 1}/${texts.length} translated`, { id: toastId });
      } catch (error) {
        console.error(error);
        toast.error("Error translating with AI");
      }
    }

    await setTexts(blocks, texts);

    toast.success("AI translation done");
    navigate(0);
  };

  const handleProjectChange = async (projectId) => {
    try {
      const { data } = await api.put(`/quote/${quote._id}`, {
        ...quote,
        project_id: projectId,
        project_name: projects.find((p) => p._id === projectId)?.name,
      });
      setQuote(data);
      toast.success("Project updated successfully");
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("Error updating project");
    }
  };

  return (
    <>
      <div className="p-2 border-b border-gray-300 text-center">
        <label className="text-lg font-semibold">Info</label>
      </div>

      <div className="space-y-2 p-4">
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Project <span className="text-red-500">*</span>
          </label>
          <SelectProject value={quote.project_id} onChange={(e) => handleProjectChange(e?._id)} className="w-full" />
          {!quote.project_id && <p className="mt-1 text-sm text-red-600 p-2 rounded-md bg-red-100">⚠️ Please select a project to continue</p>}
        </div>
        <input id="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="input !mb-2" />
        <select
          id="category"
          value={values.category}
          onChange={async (e) => {
            const { data } = await api.put(`/quote/${quote._id}`, { category: e.target.value });
            setValues(data);
            toast.success("Category updated");
          }}
          className="input !mb-2">
          <option value="">Select a category</option>
          <option value="PROPAL">Propal</option>
          <option value="CONTRACT">Contract</option>
          <option value="USE_CASE">Use case</option>
          <option value="CV">CV</option>
          <option value="OTHER">Other</option>
        </select>
        <select
          id="lang"
          value={values.lang}
          onChange={async (e) => {
            const { data } = await api.put(`/quote/${quote._id}`, { lang: e.target.value });
            setValues(data);
            toast.success("Language updated");
            navigate(0);
          }}
          className="input !mb-2">
          <option value="fr">Français</option>
          <option value="en">Anglais</option>
        </select>
        <button className="blue-btn w-full !mb-2 flex flex-col h-14" onClick={handleAI}>
          <p>Traduire vers {quote.lang === "fr" ? "le français" : "l'anglais"} en </p>
          <p>utilisant la version {quote.lang === "fr" ? "anglaise" : "française"}</p>
        </button>
        <label className="text-sm font-semibold" htmlFor="description">
          Description
        </label>
        <textarea id="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} className="input" />
        <div>
          <label className="text-sm font-semibold" htmlFor="assign">
            Assign to
          </label>
          <SelectUser
            value={{ name: values.assigned_user_name, _id: values.assigned_user_id }}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { assigned_user_id: e._id, assigned_user_name: e.name, assigned_user_avatar: e.avatar });
              setValues(data);
              toast.success("Assign updated");
            }}
          />
        </div>
        <Tags
          value={values.tags}
          onChange={async (e) => {
            const { data } = await api.put(`/quote/${quote._id}`, { tags: e });
            setValues(data);
            toast.success("Tags updated");
          }}
          tags={[
            "fintech",
            "govtech",
            "healthtech",
            "edtech",
            "agritech",
            "sportech",
            "proptech",
            "heritagetech",
            "legaltech",
            "martech",
            "hrtech",
            "metabase",
            "teams",
            "mobile",
            "desktop",
            "extension",
            "ia",
            "urgent",
          ]}
        />

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_to_review">
            To review
          </label>
          <select
            id="action_to_review"
            value={values.action_to_review}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_to_review: e.target.value });
              setValues(data);
              toast.success("Action to review updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_blog">
            Blog
          </label>
          <select
            id="action_blog"
            value={values.action_blog}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_blog: e.target.value });
              setValues(data);
              toast.success("Action blog updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_sortlist">
            Sortlist
          </label>
          <select
            id="action_sortlist"
            value={values.action_sortlist}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_sortlist: e.target.value });
              setValues(data);
              toast.success("Action sortlist updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_trustpilot">
            Trustpilot
          </label>
          <select
            id="action_trustpilot"
            value={values.action_trustpilot}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_trustpilot: e.target.value });
              setValues(data);
              toast.success("Action updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_clutch">
            Clutch
          </label>
          <select
            id="action_clutch"
            value={values.action_clutch}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_clutch: e.target.value });
              setValues(data);
              toast.success("Action updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_goodfirms">
            Good Firms
          </label>
          <select
            id="action_goodfirms"
            value={values.action_goodfirms}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_goodfirms: e.target.value });
              setValues(data);
              toast.success("Action updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold" htmlFor="action_codeur">
            Codeur
          </label>
          <select
            id="action_codeur"
            value={values.action_codeur}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { action_codeur: e.target.value });
              setValues(data);
              toast.success("Action codeur updated");
            }}
            className="input">
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>
        <div className="flex flex-row items-center justify-start gap-4 py-4">
          <label className="text-sm font-semibold" htmlFor="archived">
            Archived
          </label>
          <input
            type="checkbox"
            id="archived"
            checked={values.archived}
            onChange={async (e) => {
              const { data } = await api.put(`/quote/${quote._id}`, { archived: e.target.checked });
              setValues(data);
              toast.success("Archived status updated");
            }}
          />
        </div>
        <button className="blue-btn w-full" onClick={handleSubmit}>
          Update
        </button>
        <button className="blue-btn w-full" onClick={handleDuplicate}>
          Duplicate
        </button>
        <button className="red-btn w-full" onClick={handleDelete}>
          Delete
        </button>
        <button className="blue-btn w-full" disabled={downloading} onClick={handlePrint}>
          {downloading ? <Loader size="small" color="white" /> : "Generate PDF"}
        </button>
        {/* {quote.file && (
          <>
            <a href={quote.file} target="_blank" className="empty-btn w-full" download={`${quote.name.replace(/ /g, "_")}.pdf`}>
              Download PDF
            </a>
            <SendModal quote={quote} />
          </>
        )} */}
      </div>
    </>
  );
};

const generateThumbnail = async (pages) => {
  try {
    const firstPageId = `page-${pages[0]._id}`;
    const firstPageElement = document.getElementById(firstPageId);

    if (!firstPageElement) {
      console.error("First page element not found");
      toast.error("First page element not found");
      return "";
    }

    let dataUrl = "";
    try {
      dataUrl = await toPng(firstPageElement);
    } catch (error) {
      console.error("Error creating thumbnail for template:", error);
      toast.error("Error creating thumbnail for template");
      return "";
    }

    if (!dataUrl) return "";

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = async () => {
        const MAX_WIDTH = 400;
        let scaleSize = 1;

        if (img.width > MAX_WIDTH) {
          scaleSize = MAX_WIDTH / img.width;
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width * scaleSize;
        canvas.height = img.height * scaleSize;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const resizedDataUrl = canvas.toDataURL("image/jpeg");

        try {
          const { data } = await api.post("/file", {
            file: { rawBody: resizedDataUrl, name: `quote_template_${new Date().toISOString()}` },
            folder: "quote_template",
          });

          resolve(data);
        } catch (error) {
          console.error("Error uploading thumbnail:", error);
          toast.error("Error uploading thumbnail");
          reject(error);
        }
      };

      img.onerror = (error) => {
        console.error("Error loading image for thumbnail:", error);
        toast.error("Error loading image for thumbnail");
        reject(error);
      };
    });
  } catch (error) {
    console.error("Error creating template:", error);
    toast.error("Error creating template");
    return "";
  }
};
