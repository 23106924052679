import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Loader from "../../../components/loader";
import api from "../../../services/api";
import { HiArrowLeft, HiClock, HiUsers, HiExternalLink, HiChat, HiClipboardList, HiQuestionMarkCircle } from "react-icons/hi";

const TABS = {
  DETAILS: "Détails",
  TRANSCRIPT: "Transcription",
  RAW: "Données brutes",
};

export default function MeetingAiDetails() {
  const [tab, setTab] = useState(TABS.DETAILS);
  const [meeting, setMeeting] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchMeeting();
  }, [id]);

  async function fetchMeeting() {
    try {
      const { data } = await api.get(`/meeting_ai/${id}`);
      setMeeting(data);
    } catch (e) {
      console.log("Error fetching meeting:", e);
    }
  }

  const formatParticipantName = (participant) => {
    if (!participant.first_name && !participant.last_name) return participant.name;
    return `${participant.first_name} ${participant.last_name}`.trim();
  };

  if (!meeting) return <Loader />;

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a1a1a] to-[#272727] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="mb-8">
          <Link to="/meeting_ai" className="inline-flex items-center text-gray-400 hover:text-white mb-4">
            <HiArrowLeft className="mr-2" />
            Retour aux réunions
          </Link>

          {/* Title and External Link */}
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">{meeting.title}</h1>
            <a
              href={meeting.report_url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors ml-4">
              <HiExternalLink className="mr-2" />
              Voir sur Read AI
            </a>
          </div>

          {/* Updated Participants Display */}
          <div className="flex flex-wrap items-center gap-4 mt-4">
            <div className="flex items-center text-gray-400">
              <HiClock className="mr-2" />
              {new Date(meeting.start_time).toLocaleString("fr-FR")}
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <HiUsers className="text-gray-400" />
              {meeting.participants?.map((participant, index) => (
                <div key={index} className="relative group/tooltip">
                  <span className="text-sm text-gray-300">
                    {formatParticipantName(participant)}
                    {index < meeting.participants.length - 1 && ", "}
                  </span>
                  {participant.email && (
                    <div className="absolute bottom-full left-0 mb-2 hidden group-hover/tooltip:block z-10">
                      <div className="bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                        {participant.name}
                        <br />
                        {participant.email}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-700 mb-8">
          <nav className="flex space-x-8">
            {Object.values(TABS).map((tabName) => (
              <button
                key={tabName}
                onClick={() => setTab(tabName)}
                className={`py-4 px-1 relative ${tab === tabName ? "text-red-500 border-b-2 border-red-500" : "text-gray-400 hover:text-gray-200"}`}>
                {tabName}
              </button>
            ))}
          </nav>
        </div>

        {/* Content */}
        <div className="mt-8">
          {tab === TABS.DETAILS && <Details meeting={meeting} />}
          {tab === TABS.TRANSCRIPT && <Transcript meeting={meeting} />}
          {tab === TABS.RAW && <Raw data={meeting} />}
        </div>
      </div>
    </div>
  );
}

function Details({ meeting }) {
  return (
    <div className="space-y-8">
      {/* Summary Section */}
      <section className="bg-[#333333] rounded-xl p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <HiChat className="mr-2" />
          Résumé
        </h2>
        <p className="text-gray-300">{meeting.summary}</p>
      </section>

      {/* Action Items Section */}
      <section className="bg-[#333333] rounded-xl p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <HiClipboardList className="mr-2" />
          Actions à entreprendre
        </h2>
        <ul className="space-y-2">
          {meeting.action_items.map((item, index) => (
            <li key={index} className="flex items-start">
              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-red-500 text-white text-sm mr-3">{index + 1}</span>
              <span className="text-gray-300">{item.text}</span>
            </li>
          ))}
        </ul>
      </section>

      {/* Topics Section */}
      {meeting.topics?.length > 0 && (
        <section className="bg-[#333333] rounded-xl p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <HiClipboardList className="mr-2" />
            Sujets abordés
          </h2>
          <div className="flex flex-wrap gap-2">
            {meeting.topics.map((topic, index) => (
              <span key={index} className="px-3 py-1 bg-gray-700 text-gray-300 rounded-full text-sm">
                {topic.text}
              </span>
            ))}
          </div>
        </section>
      )}

      {/* Key Questions Section */}
      <section className="bg-[#333333] rounded-xl p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <HiQuestionMarkCircle className="mr-2" />
          Questions clés
        </h2>
        <ul className="space-y-2">
          {meeting.key_questions.map((question, index) => (
            <li key={index} className="flex items-start">
              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-600 text-white text-sm mr-3">Q{index + 1}</span>
              <span className="text-gray-300">{question.text}</span>
            </li>
          ))}
        </ul>
      </section>

      {/* Chapters Section */}
      {meeting.chapter_summaries?.length > 0 && (
        <section className="bg-[#333333] rounded-xl p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <HiClipboardList className="mr-2" />
            Chapitres
          </h2>
          <div className="space-y-6">
            {meeting.chapter_summaries.map((chapter, index) => (
              <div key={index} className="border-l-2 border-gray-700 pl-4">
                <h3 className="text-lg font-medium text-white mb-2">{chapter.title}</h3>
                <p className="text-gray-300 text-sm mb-3">{chapter.description}</p>
                {chapter.topics?.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {chapter.topics.map((topic, topicIndex) => (
                      <span key={topicIndex} className="px-2 py-1 bg-gray-700 text-gray-300 rounded-full text-xs">
                        {topic.text}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
}

function Transcript({ meeting }) {
  return (
    <div className="bg-[#333333] rounded-xl p-6">
      <div className="space-y-4">
        {meeting.transcript.speaker_blocks.map((block, index) => (
          <div key={index} className="flex space-x-4">
            <div className="flex-shrink-0 w-32">
              <div className="text-sm text-gray-400">{new Date(block.start_time * 1000).toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}</div>
              <div className="text-sm font-medium text-red-500">{block.speaker.name}</div>
            </div>
            <div className="flex-grow">
              <p className="text-gray-300">{block.words}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Raw({ data }) {
  return (
    <div className="bg-[#333333] rounded-xl p-6 overflow-auto">
      <pre className="text-gray-300 text-sm">{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}
