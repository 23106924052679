import { useState, useEffect } from "react";
import EpicEditModal from "./EpicModal";

export default () => {
  const [epics, setEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);

  useEffect(() => {
    setEpics(data);
  }, []);

  const sections = [];
  for (const item of epics) {
    const module = item.module || "Uncategorized";
    if (!sections[module]) {
      sections[module] = [];
    }
    sections[module].push(item);
  }

  const now = epics.filter((item) => item.priority === "NOW");
  const next = epics.filter((item) => item.priority === "NEXT");
  const later = epics.filter((item) => item.priority === "LATER");

  return (
    <div className="min-h-screen bg-white">
      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
        />
      )}
      <div className="grid grid-cols-3 gap-6 p-4">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold">Now </h2>
            <span className="text-sm text-gray-500">({now.length} items)</span>
            <button className="p-1 hover:bg-gray-100 rounded-full">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold">Next</h2>
            <span className="text-sm text-gray-500">({next.length} items)</span>
            <button className="p-1 hover:bg-gray-100 rounded-full">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold">Later</h2>
            <span className="text-sm text-gray-500">({later.length} items)</span>
            <button className="p-1 hover:bg-gray-100 rounded-full">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div>
        {Object.entries(sections).map(([module, items]) => {
          return (
            <Section
              key={module}
              title={module}
              items={items}
              onEpicClick={(e) => {
                setSelectedEpic(e);
                setIsEpicEditModalOpen(true);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const Section = ({ title, items, onEpicClick }) => {
  const [isOpen, setIsOpen] = useState(true);

  const now = items.filter((item) => item.priority === "NOW");
  const next = items.filter((item) => item.priority === "NEXT");
  const later = items.filter((item) => item.priority === "LATER");

  return (
    <div className="">
      <div className="border ">
        <button className="w-full flex items-center justify-between p-4 hover:bg-gray-50" onClick={() => setIsOpen(!isOpen)}>
          <div className="flex items-center gap-2">
            <span className="font-medium">{title}</span>
            <span className="text-sm text-gray-500">({items.length} items)</span>
          </div>
          <svg className={`w-5 h-5 transition-transform ${isOpen ? "rotate-180" : ""}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div className={`p-4 border-t ${isOpen ? "block" : "hidden"}`}>
          <div className="grid grid-cols-3 gap-6 p-4">
            <div className="space-y-4">
              {now.map((item) => (
                <Card key={item._id} item={item} onEpicClick={onEpicClick} />
              ))}
            </div>
            <div className="space-y-4">
              {next.map((item) => (
                <Card key={item._id} item={item} onEpicClick={onEpicClick} />
              ))}
            </div>
            <div className="space-y-4">
              {later.map((item) => (
                <Card key={item._id} item={item} onEpicClick={onEpicClick} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ item, onEpicClick }) => (
  <div className="w-full p-3 bg-white rounded-lg shadow-sm border space-y-2 cursor-pointer hover:shadow-md hover:border-gray-400 transition-all" onClick={() => onEpicClick(item)}>
    <div className="flex justify-between items-center">
      <span className="text-sm font-medium">{item.title}</span>
    </div>
    {item.tags?.length > 0 && (
      <div className="flex items-center gap-2">
        {item.tags.map((tag, i) => (
          <span key={i} className="text-xs px-2 py-1 rounded-md bg-gray-100 text-gray-700">
            {tag}
          </span>
        ))}
      </div>
    )}
    <div className="flex justify-between items-center">
      <div className="flex space-x-1">
        {item.users?.map((user, idx) => (
          <div key={idx} className="w-6 h-6 rounded-full bg-gray-300 overflow-hidden" title={user.user_name}>
            {user.user_avatar ? (
              <img src={user.user_avatar} alt={user.user_name} className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full flex items-center justify-center text-xs">{user.user_name?.charAt(0)}</div>
            )}
          </div>
        ))}
      </div>
      <div className="text-xs text-gray-500">{item.comments?.length > 0 && `${item.comments.length} comment${item.comments.length > 1 ? "s" : ""}`}</div>
    </div>
  </div>
);

const data = [
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "account-creation"],
    index: 72,
    _id: "672e3c12c4294407b96db4c7",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Welcome email",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674ed22eb1af6907bc327c80",
        field: "title",
        from: "Welcome email",
        to: "As a user, I want to receive a welcome email upon successful account creation",
        why: "The current title 'Welcome email' lacks specificity and actionable detail. By specifying the user perspective and associating it directly with 'account-creation', it becomes clearer that the task involves triggering a welcome email once an account is successfully created. This provides clearer direction for developers and aligns with user onboarding processes. Check integration options with existing email services and consider user feedback mechanisms. Consider offering the perk of integrating Sentry for monitoring email delivery performance.",
      },
    ],
    created_at: "2024-11-08T16:28:02.715Z",
    __v: 6,
    module: "Authentification",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "request", "poc"],
    index: 73,
    _id: "672e3d4ec4294407b96dbd89",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "My request tab page",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e3d5cc4294407b96dbdbc",
        field: "title",
        from: "My request tab page",
        to: "Develop User-Centric Request Management Interface",
        why: "The current title is too vague and lacks user-centric action. Specify that the feature is a request management interface aimed at improving user interactions. Ensure it reflects specific functionalities like viewing, submitting, and tracking requests to make the EPIC actionable and clear.",
      },
    ],
    created_at: "2024-11-08T16:33:18.374Z",
    __v: 7,
    module: "Requests",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "user", "products"],
    index: 74,
    _id: "672e3d9cc4294407b96dbe8b",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "My products tab page",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e3da3c4294407b96dbeaf",
        field: "title",
        from: "My products tab page",
        to: "Enhance Product Tab with Filter and Sorting Options",
        why: "The current title 'My products tab page' is too broad and lacks specifics. To make it actionable and user-centric, focus on specific functionalities like filtering and sorting. This will provide clear value to users by improving their navigation and selection process on the product page. Consider whether this feature is essential for the MVP or if it can be phased in later based on user feedback.",
      },
    ],
    created_at: "2024-11-08T16:34:36.220Z",
    __v: 7,
    module: "Profil",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["subscription", "user", "poc"],
    index: 76,
    _id: "672e3e2ec4294407b96dc4c5",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Pricing - subscription page",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e3e3dc4294407b96dc4e4",
        field: "title",
        from: "Pricing - subscription page",
        to: "Detailed Pricing Options on Subscription Page",
        why: "The title 'Pricing - subscription page' lacks clarity and specificity. It should clearly indicate the focus on detailing pricing options to enhance the subscription page's usability and inform potential subscribers effectively. Confirm if it also includes promotional discounts or special offers that might interest users.",
      },
    ],
    created_at: "2024-11-08T16:37:02.591Z",
    __v: 5,
    module: "Abonnement",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "marketplace"],
    index: 77,
    _id: "672e3e7ac4294407b96dc53b",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Page de payement",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e3e82c4294407b96dc552",
        field: "title",
        from: "Page de payement",
        to: "Implement Comprehensive Payment Processing System",
        why: "The title 'Page de payement' is too broad and lacks specificity. Clarify the specific payment functionalities expected. For example, should the system handle multiple payment methods, provide real-time transaction updates, or secure sensitive data? Consider splitting this into smaller EPICs focused on distinct aspects of payment processing. Confirm if this EPIC is crucial for the MVP or if it can be postponed.",
      },
    ],
    created_at: "2024-11-08T16:38:18.089Z",
    __v: 5,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: ["poc", "marketplace", "user"],
    index: 78,
    _id: "672e3eacc4294407b96dc576",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Page détails d'un produit",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673ca5a46f4f5107ba8a9819",
        field: "title",
        from: "Page détails d'un produit",
        to: "Implement Product Detail View for Marketplace Module",
        why: "The current title is too generic and does not reflect user actions or specific feature details. Refocusing the EPIC title to indicate its role within the 'Marketplace' module will help clarify its purpose and the specific user actions it supports. Ensure the EPIC aligns with user needs by detailing the specific functionalities (e.g., viewing product descriptions, pricing, availability) that are expected in this view.",
      },
    ],
    created_at: "2024-11-08T16:39:08.776Z",
    __v: 11,
    module: "Marketplace",
    persona: "User",
    days_required: 1,
    estimation: 150,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "poc", "marketplace"],
    index: 79,
    _id: "672e418ac4294407b96dd969",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Base produits",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e4199c4294407b96dd997",
        field: "title",
        from: "Base produits",
        to: "Refiner Product Database Features for User Management",
        why: "The title 'Base produits' is too broad and lacks specificity. This Epic should focus on detailing specific user-related functionalities within the product database. Clarify the goals, ensure user-centered language, and specify if it involves 'admin' features or another defined feature area.",
      },
    ],
    created_at: "2024-11-08T16:51:22.845Z",
    __v: 7,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "user", "marketplace"],
    index: 80,
    _id: "672e41ccc4294407b96dda25",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Base services",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e41d7c4294407b96dda7e",
        field: "title",
        from: "",
        to: "",
        why: "The EPIC lacks a clear title and purpose. It needs to specify the goal or feature it targets, such as whether it is related to 'admin', 'account-creation', or specifies another feature. The EPIC should articulate its necessity and the value it provides, ensuring it is user-centric and avoids ambiguity by detailing expected outcomes.",
      },
    ],
    created_at: "2024-11-08T16:52:28.996Z",
    __v: 7,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "user", "marketplace"],
    index: 81,
    _id: "672e420ac4294407b96ddb9a",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Base solutions",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e4212c4294407b96ddbc6",
        field: "title",
        from: "Base solutions",
        to: "Develop Comprehensive Fullstack Implementation Solutions",
        why: "The title 'Base solutions' is too broad and lacks specificity. Provide a detailed EPIC that specifies what the base solutions are and how they should be implemented. Ensure it’s user-centric and actionable. Does it relate to any specific feature like 'admin' or 'account-creation'? Clarify its business contribution explicitly.",
      },
    ],
    created_at: "2024-11-08T16:53:30.704Z",
    __v: 7,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "user", "marketplace"],
    index: 82,
    _id: "672e4234c4294407b96ddc49",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Base trainings",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e423cc4294407b96ddc5f",
        field: "title",
        from: "Base trainings",
        to: "Implement Comprehensive Fullstack Training Modules",
        why: "The title 'Base trainings' lacks specificity and actionability. It should clearly define the scope and objective of the training modules, focusing on comprehensive coverage of Fullstack skills. This will provide a clear directive for the development and align with project goals. The EPIC is relevant and can add significant value to the business by enhancing developer proficiency.",
      },
    ],
    created_at: "2024-11-08T16:54:12.828Z",
    __v: 7,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "user", "marketplace"],
    index: 83,
    _id: "672e4259c4294407b96ddd28",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Base events",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e4261c4294407b96ddd3f",
        field: "title",
        from: "Base events",
        to: "Enhance Fullstack Event Management",
        why: "The current title 'Base events' lacks specificity and does not clearly communicate the scope or objective of the EPIC. 'Enhance Fullstack Event Management' indicates a more detailed focus on improving event functionalities across a fullstack implementation, aligning with the project's broader goals.",
      },
    ],
    created_at: "2024-11-08T16:54:49.597Z",
    __v: 7,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "poc", "marketplace"],
    index: 84,
    _id: "672e4284c4294407b96de062",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Base community",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e428cc4294407b96de0a5",
        field: "title",
        from: "Base community",
        to: "Develop Core Features for Online Community Platform",
        why: "The current title 'Base community' lacks specificity and does not clarify the feature scope or its purpose. Refining it to 'Develop Core Features for Online Community Platform' indicates that the focus should be on essential functionalities of the community platform, aligning with fullstack development scope. It would be beneficial to specify if these core features include user management or any specific interaction modules.",
      },
    ],
    created_at: "2024-11-08T16:55:32.399Z",
    __v: 7,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "parametres", "user", "provider"],
    index: 85,
    _id: "672e42bac4294407b96de14e",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "My account page",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "672e42c9c4294407b96de168",
        field: "title",
        from: "My account page",
        to: "Develop user-focused My Account page with essential features",
        why: "The title 'My account page' lacks clarity and specific user actions. Specify which features will be part of the account page, such as profile editing, privacy settings, or account linkages. Clarify its value to ensure it meets user needs effectively. Confirm if this feature is essential for the MVP. Classify whether this pertains to 'account-creation' or another specific functionality to improve project tracking.",
      },
    ],
    created_at: "2024-11-08T16:56:26.162Z",
    __v: 6,
    module: "Profil",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["provider", "poc", "marketplace"],
    index: 86,
    _id: "6731be8e992fbe07b9643b05",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Page des offres que le user propose en tant que provider",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731be9c992fbe07b9643b1d",
        field: "title",
        from: "Page des offres que le user propose en tant que provider",
        to: "Développement de la fonctionnalité de création et gestion des offres pour les utilisateurs comme fournisseurs",
        why: "Le titre manque de clarté sur l'action spécifique que l'utilisateur peut accomplir. Précisez les fonctionnalités attendues telles que la création et la gestion des offres pour les fournisseurs. Cela permet de garantir que l'EPIC soit centré sur l'utilisateur et suffisamment granulaire pour être actionnable. Vérifiez également si cette fonctionnalité est essentielle au projet actuel ou peut être reportée.",
      },
    ],
    created_at: "2024-11-11T08:21:34.716Z",
    __v: 15,
    persona: "Provider",
    module: "Marketplace",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["provider", "request", "poc"],
    index: 87,
    _id: "6731beb3992fbe07b9643b32",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Page des requests adressées au provider",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731bebe992fbe07b9643b40",
        field: "title",
        from: "Page des requests adressées au provider",
        to: "Implement Provider Request Management Interface",
        why: "The title should specify the purpose and actions allowed on the page. Clarify what 'requests' refer to and the interactions expected. Focus on detailing how users will interact with the provider requests, possibly indicating who manages them (admin, provider).",
      },
    ],
    created_at: "2024-11-11T08:22:11.972Z",
    __v: 5,
    persona: "Provider",
    module: "Marketplace",
  },
  {
    priority: "LATER",
    resource_profile: "DEVELOPER",
    tjm: 350,
    urgent: false,
    tags: ["profil", "provider"],
    index: 88,
    _id: "6731bee7992fbe07b9643b79",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Page profil provider",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731bef3992fbe07b9643bb8",
        field: "title",
        from: "Page profil provider",
        to: "Enhance Provider Profile Page Features for User Engagement",
        why: "The current title 'Page profil provider' lacks clarity on what specific actions or features are to be implemented. To improve this EPIC, it's essential to specify what enhancements will be made to the provider's profile page to drive user engagement and intuitiveness. Detailing these aspects will ensure that the development team can clearly understand the tasks and objectives.",
      },
    ],
    created_at: "2024-11-11T08:23:03.362Z",
    __v: 8,
    persona: "Provider",
    module: "Profil",
    days_required: 2,
    estimation: 350,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["admin", "poc"],
    index: 89,
    _id: "6731c028992fbe07b9643f64",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Ecran admin",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc6aea786733806882712",
        field: "title",
        from: "Ecran admin",
        to: "Develop Detailed Admin Panel Features for User and Data Management",
        why: "The current title 'Ecran admin' is too broad and lacks specificity. It should be broken down into more granular user stories or tasks, detailing specific features or functionalities for the admin panel. Clarifying user interactions and expected outcomes will provide a clear path for development and align with project goals. This feature is associated with 'admin' as indicated by the tags.",
      },
    ],
    created_at: "2024-11-11T08:28:24.981Z",
    __v: 5,
    module: "Admin",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["account-creation", "poc"],
    index: 90,
    _id: "6731c145992fbe07b9644464",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Forgot password",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731c14d992fbe07b9644472",
        field: "title",
        from: "Forgot password",
        to: "Implement user-centric password recovery options",
        why: "The current title 'Forgot password' lacks detail and does not indicate the user-centric nature of the task. It should clearly express the user action and specify methods for password recovery. Categorize under 'account-creation' as it relates to user account management and recovery.",
      },
    ],
    created_at: "2024-11-11T08:33:09.609Z",
    __v: 4,
    module: "Authentification",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["poc", "marketplace", "user"],
    index: 91,
    _id: "6731c4a7992fbe07b96452f2",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Laisser un avis sur un produit",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731c4ae992fbe07b9645305",
        field: "title",
        from: "Laisser un avis sur un produit",
        to: "Faciliter l'évaluation des produits par les utilisateurs",
        why: "Clarify the user action involved in leaving a review. Encourages user interaction by specifying that users can easily leave feedback, which is crucial for improving user engagement and product quality assessment.",
      },
    ],
    created_at: "2024-11-11T08:47:35.293Z",
    __v: 9,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "poc", "marketplace"],
    index: 92,
    _id: "6731c51d992fbe07b96453e6",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Afficher les détails d'un produit acheté",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731c529992fbe07b9645403",
        field: "title",
        from: "Afficher les détails d'un produit acheté",
        to: "Visualisation détaillée des produits pour l'utilisateur",
        why: "Le titre original 'Afficher les détails d'un produit acheté' est trop vague et n'est pas suffisamment axé sur l'utilisateur. La suggestion introduit une perspective utilisateur avec un verbe d'action, rendant l'EPIC plus orienté utilisateur. Il est essentiel de clarifier si cette fonctionnalité s'adresse à un utilisateur final ou à une interface administrateur.",
      },
    ],
    created_at: "2024-11-11T08:49:33.082Z",
    __v: 8,
    module: "Marketplace",
    persona: "User",
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: ["profil", "parametres", "user", "poc"],
    index: 93,
    _id: "6731c7276a3dec07b71d27b1",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Modifier ses informations personnelles",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673ca5936f4f5107ba8a94b9",
        field: "title",
        from: "Modifier ses informations personnelles",
        to: "As a user, I want to update my personal information on my profile",
        why: "The current title is too broad and lacks clarity from a user's perspective. Specify the action of updating personal information, which provides clear direction and makes the EPIC more user-centered and actionable. It pertains specifically to the 'Profil' feature, enhancing user experience by allowing profile updates.",
      },
    ],
    created_at: "2024-11-11T08:58:15.250Z",
    __v: 9,
    module: "Profil",
    persona: "User",
    days_required: 1,
    estimation: 150,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "profil", "subscription", "poc"],
    index: 95,
    _id: "6731c7846a3dec07b71d281e",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Modifier son abonnement à la plateforme",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731c7926a3dec07b71d2854",
        field: "title",
        from: "Modifier son abonnement à la plateforme",
        to: "Gestion des modifications d'abonnement utilisateur",
        why: "Le titre actuel 'Modifier son abonnement à la plateforme' manque de clarté et de granularité. Assurez-vous que l'EPIC décrit les actions spécifiques que les utilisateurs peuvent effectuer pour modifier leur abonnement. Cela pourrait inclure des actions détaillées telles que 'Changer le type d'abonnement', 'Mettre à jour les informations de paiement', et 'Annuler un abonnement'. De plus, précisez si cette fonctionnalité s'adresse aux utilisateurs finaux ou nécessite une intervention administrative. Ce niveau de détail améliorera la compréhension et l'implémentation par le développement.",
      },
    ],
    created_at: "2024-11-11T08:59:48.922Z",
    __v: 7,
    module: "Abonnement",
    persona: "User",
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: ["profil", "poc"],
    index: 96,
    _id: "6731c7d06a3dec07b71d2b8e",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Modifier mes coordonnées bancaires",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673ca5bf6f4f5107ba8a98ad",
        field: "title",
        from: "Modifier mes coordonnées bancaires",
        to: "Update Bank Account Details in User Profile",
        why: "This EPIC should clearly indicate its association with the user profile management feature. It should specify actions users are expected to undertake, ensuring it is actionable and user-centric. Confirm if this feature is essential for the user experience and aligns with project goals.",
      },
    ],
    created_at: "2024-11-11T09:01:04.930Z",
    __v: 6,
    module: "Profil",
    persona: "",
    days_required: 0.5,
    estimation: 75,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["profil", "parametres", "poc"],
    index: 97,
    _id: "6731c8036a3dec07b71d2bf0",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Voir les conditions d'utilisation de la plateforme",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731c80a6a3dec07b71d2c00",
        field: "title",
        from: "Voir les conditions d'utilisation de la plateforme",
        to: "As a user, I want to access the terms and conditions of the platform via a PDF download",
        why: "The current title is vague and lacks clarity. Clearly state the desired action from a user's perspective and propose a cost-effective delivery method using PDF, as it's less costly and aligns with the advice to prefer PDF formats for such content.",
      },
    ],
    created_at: "2024-11-11T09:01:55.095Z",
    __v: 5,
    module: "Profil",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["parametres", "profil", "poc"],
    index: 99,
    _id: "6731c8746a3dec07b71d2c90",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Supprimer son compte",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc3b6a786733806882505",
        field: "title",
        from: "Supprimer son compte",
        to: "As a user, I want to securely delete my profile and account data",
        why: "The current title 'Supprimer son compte' lacks user perspective and specificity. Reframing it as a user-centered statement clarifies the action and emphasizes user security, aligning with project goals. Consider if this feature is necessary for user data management compliance and assess the impact on user experience. Propose 'Sentry' for error monitoring to ensure this critical action is executed smoothly.",
      },
    ],
    created_at: "2024-11-11T09:03:48.085Z",
    __v: 6,
    module: "Profil",
  },
  {
    priority: "LATER",
    resource_profile: "DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["profil", "provider", "poc"],
    index: 100,
    _id: "6731c8aa6a3dec07b71d2ce7",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Modifier ses informations d'entreprise",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "665f3509a6b99c0710821317",
    user_name: "Remy Theolier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/0adb7ab13fa54bfe32c9102c8e8dde2a/Profil%20-%201x1.jpg",
    user_email: "rtheolier@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6731c8b16a3dec07b71d2cff",
        field: "title",
        from: "Modifier ses informations d'entreprise",
        to: "Updating Corporate Information",
        why: "The current title is too vague and broad. Clarify if this is related to 'admin' or 'account-creation'. For example, specify if it involves updating company settings or user roles. This will help in determining if it’s essential for the current project phase.",
      },
    ],
    created_at: "2024-11-11T09:04:42.256Z",
    __v: 5,
    module: "Profil",
    persona: "Provider",
    days_required: 2,
    estimation: 1000,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["profil", "user", "poc"],
    index: 103,
    _id: "67332c93dd1dc607b83f78aa",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Profil Utilisateur: Choix métier, Société",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "67332c9add1dc607b83f78c8",
        field: "title",
        from: "Profil Utilisateur: Choix métier, Société",
        to: "User Profile: Profession Choice and Company Affiliation",
        why: "The original title 'Profil Utilisateur: Choix métier, Société' is unclear and lacks specific user actions. Specify if this EPIC pertains to 'account-creation' or another specific feature. Consider further breaking down into actionable steps such as 'Select Profession', 'Link Company Profile', and ensure it aligns with the user's journey.",
      },
    ],
    created_at: "2024-11-12T10:23:15.144Z",
    __v: 5,
    business_objective: "Ajouter metier et société pour user",
    module: "Profil",
    persona: "User",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "profil"],
    index: 104,
    _id: "67332e7add1dc607b83f7fd1",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Profil User: Ajout d'historique dernières recherches.DUser Freemium = 3 dernières recherchesUser Premium = Infini.&nbsp;",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "REJECTED",
        _id: "67332ecbdd1dc607b83f8061",
        field: "title",
        from: "Profil User: Ajout d'historique dernières recherches.DUser Freemium = 3 dernières recherchesUser Premium = Infini.",
        to: "Implement Search History Feature for User Profiles",
        why: "The title is overly complex and lacks clarity. Simplifying it helps focus on the main objective: adding search history features. Clarification on whether this feature applies to basic or premium users is still needed. This EPIC should be associated with the 'user profile' feature.",
      },
    ],
    created_at: "2024-11-12T10:31:22.493Z",
    __v: 8,
    business_objective: "Créer historique dernières recherches ",
    module: "Profil",
    persona: "User",
    days_required: 1,
    estimation: 500,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["user", "profil", "parametres"],
    index: 105,
    _id: "67333023dd1dc607b83f84f4",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Add CyberConnect Hubspot links",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6733302bdd1dc607b83f8510",
        field: "title",
        from: "Add CyberConnect Hubspot links",
        to: "Integrate CyberConnect Links into Hubspot for Enhanced CRM Functionality",
        why: "The current title lacks specificity about the actions needed and the feature involved. By specifying 'Integrate,' it clarifies the action required. The addition of 'for Enhanced CRM Functionality' highlights the purpose and expected benefit, aligning with a user-centric and goal-oriented approach. This EPIC is related to the 'admin' feature associated with CRM system enhancements.",
      },
    ],
    created_at: "2024-11-12T10:38:27.902Z",
    __v: 5,
    business_objective: "Add Links to Cyberconnect main website",
    persona: "User",
    module: "Profil",
    days_required: 0.5,
    estimation: 250,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["provider", "profil", "poc", "parametres"],
    index: 106,
    _id: "67333199dd1dc607b83f8913",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Provider: Company infos",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc5eaa7867338068826d5",
        field: "title",
        from: "Provider: Company infos",
        to: "Allow Users to Edit Company Information within Profile Module",
        why: "The original title 'Provider: Company infos' lacks clarity and specificity. The new title focuses on the user-centered action of allowing users to modify company information, which is aligned with the module 'Profile'. This EPIC should be scoped to the 'Profil' feature to ensure it's actionable and aligns with business objectives. Consider the necessity of this task for MVP and ensure it adds vital business contribution.",
      },
    ],
    created_at: "2024-11-12T10:44:41.853Z",
    __v: 7,
    business_objective: "Possibility to modify company infos .",
    persona: "",
    module: "Profil",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["provider", "products", "parametres"],
    index: 107,
    _id: "67333299dd1dc607b83f8f72",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Provider: Profile: Add Product",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673332a1dd1dc607b83f8f93",
        field: "title",
        from: "Provider: Profile: Add Product",
        to: "Enable Adding New Products to Provider Profiles for Enhanced Inventory Management",
        why: "The current title lacks clarity and doesn't specify the user action or benefit. The revised title clarifies the specific feature of adding products to provider profiles, indicating its purpose for inventory management. It's important to ensure this EPIC is actionable and aligns with user needs, focusing on enhancing profile capabilities.",
      },
    ],
    created_at: "2024-11-12T10:48:57.157Z",
    __v: 5,
    business_objective: "Possibility to add product from Provider user profile",
    module: "Marketplace",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["admin", "request"],
    index: 108,
    _id: "6733338fdd1dc607b83f9378",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Send Email to admin for any product demo request",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc661a7867338068826e8",
        field: "title",
        from: "Send Email to admin for any product demo request",
        to: "Automate Admin Notification for Product Demo Requests",
        why: "The current title lacks specificity and clarity. Specify the action of automation and its purpose—streamlining admin notifications for demo requests. Ensure it aligns with 'admin' functions, focusing on enhancing admin operations and reducing manual tasks. This new title drives a clear, actionable focus suitable for an experienced developer to grasp and implement effectively.",
      },
    ],
    created_at: "2024-11-12T10:53:03.093Z",
    __v: 5,
    business_objective: "Notify the admin for any product demo requested ",
    persona: "Admin",
    module: "Admin",
    estimation: null,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: ["subscription", "account-creation", "user"],
    index: 109,
    _id: "67333411dd1dc607b83f9727",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Inscription: Choose User or Provider role",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "67333419dd1dc607b83f98ba",
        field: "title",
        from: "Inscription: Choose User or Provider role",
        to: "User Registration: Select User or Provider Role During Signup",
        why: "The current title lacks specificity, making it unclear which part of the process is being addressed. The new title specifies that this decision point occurs during signup, emphasizing its role in user account creation.",
      },
    ],
    created_at: "2024-11-12T10:55:13.425Z",
    __v: 6,
    business_objective: "Select role at SignUp. User:Provider. \n",
    module: "Profil",
    estimation: null,
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 111,
    _id: "67348c034ed81007b90e5bee",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Enrichir la data avec Open AI",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "67447d678d3e5507b9e5b00f",
        field: "title",
        from: "Enrichir la data avec Open AI",
        to: "Integrate OpenAI for Data Enhancement and Analysis",
        why: "The current EPIC title, 'Enrichir la data avec Open AI,' lacks granularity and specificity. The new title specifies the action and expected outcome, ensuring the task is user-centered and aligned with project objectives. Confirm if this EPIC pertains to data analysis functions or another core feature to ensure it is necessary and actionable.",
      },
    ],
    created_at: "2024-11-13T11:22:43.007Z",
    __v: 5,
    days_required: 3,
    estimation: 450,
    assigned: "Carl ",
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: ["admin", "products"],
    index: 112,
    _id: "6735ba2407d4b007ba80138b",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Admin View to see demo requests",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6752cad75bb0e029e2006cd9",
        field: "title",
        from: "Admin View to see demo requests",
        to: "As an admin, I want to view and manage demo requests",
        why: "To ensure the EPIC is user-centered and aligns with administrative functions, the title needs to clearly specify the admin's role in viewing and managing demo requests. This makes it more actionable and guides development towards the actual needs. This feature pertains to the 'admin' module, ensuring clarity and explicit expectations. Consider offering the perk 'Sentry' for error tracking during the implementation phase, providing potential savings and added operational insight.",
      },
    ],
    created_at: "2024-11-14T08:51:48.527Z",
    __v: 9,
    module: "Admin",
    business_objective: "Allow admin to edit any product in backlog",
    estimation: 225,
    days_required: 1.5,
    assigned: "Carl ",
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 350,
    urgent: false,
    tags: ["user", "parametres"],
    index: 113,
    _id: "6735c8c78ecef707bacaa928",
    project_id: "66fea9422ea2c00ede31edb1",
    title:
      "Page profil Offreur : log in/out, possibilité d'ajouter un produit, de modifier le référencement + notification de demande de démo ++ lien site Cyber Connect Product + site blog",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673c95176f4f5107ba8a4975",
        field: "title",
        from: "Page profil Offreur : log in/out, possibilité d'ajouter un produit, de modifier le référencement + notification de demande de démo ++ lien site Cyber Connect Product + site blog",
        to: "User Profile Page Enhancements with Login, Product Management, SEO Updates, and Demo Notifications",
        why: "The current title is too broad and lacks focus. It combines multiple functionalities without specific context. Suggest breaking down into separate, user-centered EPICs such as: 'User Login/Logout Management,' 'Product Addition and Management on Profile,' 'SEO Referencing by User,' 'Notification Setup for Demo Requests,' and 'Integration Links to Cyber Connect Product and Blog.' Ensure each EPIC is actionable and aligns with user expectations. This task does not relate to 'admin' or 'account-creation', but specifically targets the user profile enhancements stage.",
      },
    ],
    created_at: "2024-11-14T09:54:15.898Z",
    __v: 10,
    business_objective: "Create user profile page ",
    estimation: 1750,
    days_required: 5,
    module: "Profil",
    assigned: "Carl ",
  },
  {
    priority: "NEXT",
    resource_profile: "DEVELOPER",
    tjm: 350,
    urgent: false,
    tags: ["profil", "parametres"],
    index: 114,
    _id: "6735d7f44cb79b07b8a7eabb",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Faire page profil Utilisateur ",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673c9e776f4f5107ba8a750d",
        field: "title",
        from: "Faire page profil Utilisateur",
        to: "User Profile Page Development with Fullstack Scope",
        why: "The title 'Faire page profil Utilisateur' is vague and needs more specificity. Clearly state it involves full-stack development for the user profile page. Confirm if any specific user actions or features are needed within this page to enhance user experience. It does not relate to 'admin' or 'account-creation' but rather to 'user profile' feature.",
      },
    ],
    created_at: "2024-11-14T10:59:00.413Z",
    __v: 16,
    module: "Profil",
    days_required: 0.5,
    estimation: 175,
    assigned: "Carl ",
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 115,
    _id: "673c572c6f4f5107ba894c87",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Dissocier les filtres recherche avancée et les filtres premium",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "63148819be2cb006a033de9e",
    user_name: "Louise Poughet",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/user/63148819be2cb006a033de9e.png",
    user_email: "louise@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "673c60506f4f5107ba89716d",
        field: "title",
        from: "Dissocier les filtres recherche avancée et les filtres premium",
        to: "Refactor Advanced and Premium Search Filters for Enhanced User Interface",
        why: "The current title lacks user perspective and granularity. It should specify the intended functionality and user benefit. It’s unclear whether this relates to 'admin', 'account-creation', or another feature. Focus on how this change will enhance the user interface or improve the user experience, ensuring it aligns with broader project goals.",
      },
    ],
    created_at: "2024-11-19T09:15:24.778Z",
    __v: 8,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 118,
    _id: "673c5d596f4f5107ba896437",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Afficher historique de recherche dans profil",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "63148819be2cb006a033de9e",
    user_name: "Louise Poughet",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/user/63148819be2cb006a033de9e.png",
    user_email: "louise@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "673ca5d46f4f5107ba8a98e4",
        field: "title",
        from: "Afficher historique de recherche dans profil",
        to: "As a user, I want to view my search history within my profile section",
        why: "The original title lacks specificity and user perspective. The new title provides clarity by incorporating a user-centered perspective and specifying the exact functionality. This EPIC relates to the 'Profil' module and must ensure it caters primarily to 'offer consumers', enhancing their user experience by allowing easy access to their search history.",
      },
    ],
    created_at: "2024-11-19T09:41:45.071Z",
    __v: 4,
    persona: "offer consumer",
    module: "Profil",
    estimation: 150,
    days_required: 1,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 119,
    _id: "673c5dbd6f4f5107ba8964eb",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "lien vers le site Cyber Connect Produits dans profil",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "63148819be2cb006a033de9e",
    user_name: "Louise Poughet",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/user/63148819be2cb006a033de9e.png",
    user_email: "louise@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "673ca0116f4f5107ba8a8132",
        field: "title",
        from: "lien vers le site Cyber Connect Produits dans profil",
        to: "As a user, I want to access Cyber Connect product links within my profile",
        why: "The title 'lien vers le site Cyber Connect Produits dans profil' lacks specificity and user-centric language. It should highlight the user's ability to easily navigate to Cyber Connect products from their profile, making the task more actionable and aligning with user needs. This feature might be associated with 'Profil' or 'dashboard' enhancements.",
      },
    ],
    created_at: "2024-11-19T09:43:25.127Z",
    __v: 4,
    module: "Profil",
    assigned: "Carl ",
    days_required: 0.5,
    estimation: 75,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 122,
    _id: "673c5f196f4f5107ba896a90",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Enhance Data with Product Page Integration for Full-Stack Development",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6731d60f763daf07b92f1218",
    user_name: "Amélie Montet",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "montetamelie@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673c7b876f4f5107ba89fd67",
        field: "title",
        from: "Enhance Data with Product Page Integration for Full-Stack Development",
        to: "Develop Specific Product Page Data Integration for Full-Stack Apps",
        why: "The current title is too broad and lacks user-centric focus. Specify the particular aspect of 'data enhancement' that is needed. Is it data visualization, data analytics, or something else? Clearly identify which full-stack components are involved and how they interact with the product pages. Identify if this is related to 'admin' functionalities or another specific feature to refine the EPIC into actionable items.",
      },
    ],
    created_at: "2024-11-19T09:49:13.828Z",
    __v: 6,
    persona: "? Demander à Seb ",
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 126,
    _id: "673c5f966f4f5107ba896f35",
    title: "User offerer profile",
    project_id: "66fea9422ea2c00ede31edb1",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "63148819be2cb006a033de9e",
    user_name: "Louise Poughet",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/user/63148819be2cb006a033de9e.png",
    user_email: "louise@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc449a786733806882534",
        field: "title",
        from: "User offerer profile",
        to: "As a user, I want to build a comprehensive profile to offer services",
        why: "This epic should be more granular and user-focused, specifying the actions users can take to create a service provider profile. It should break down into smaller tasks, such as adding personal information, services offered, and contact details. This makes the epic actionable, clear, and better aligned with project goals.",
      },
    ],
    created_at: "2024-11-19T09:51:18.221Z",
    __v: 4,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 127,
    _id: "673c5fa46f4f5107ba896f5b",
    title: "Filters premium",
    project_id: "66fea9422ea2c00ede31edb1",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "63148819be2cb006a033de9e",
    user_name: "Louise Poughet",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/user/63148819be2cb006a033de9e.png",
    user_email: "louise@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc5bca7867338068826c6",
        field: "title",
        from: "Filters premium",
        to: "Advanced User Filtering Options for Premium Accounts",
        why: "The original title 'Filters premium' is too vague and lacks specificity regarding user actions and expected outcomes. By specifying that it involves 'Advanced User Filtering Options for Premium Accounts,' the EPIC becomes more detailed and user-centered. This clarity helps developers understand the precise functionality to implement. It also aligns with the user's business contribution and the fullstack scope, confirming its relevance and necessity in the project. This feature is neither related to 'admin' nor 'account-creation', but specifically to the enhancement of premium user functionalities.",
      },
    ],
    created_at: "2024-11-19T09:51:32.753Z",
    __v: 1,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 129,
    _id: "673c73cb6f4f5107ba89da87",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Define Product data model as Table in backend + export in MongoDB",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "673ca53b6f4f5107ba8a93ef",
        field: "title",
        from: "Define Product data model as Table in backend + export in MongoDB",
        to: "Design and Implement Product Data Model for Backend with MongoDB Export Feature",
        why: "The current title lacks specificity and does not clearly indicate the user perspective or the exact tasks involved. Ensure the title specifies the outcome and key functionalities, such as focusing on backend data structuring and exporting capabilities via MongoDB. Confirm its importance for the MVP and its relevance to either 'admin' or another specific feature.",
      },
    ],
    created_at: "2024-11-19T11:17:31.421Z",
    __v: 6,
    estimation: 150,
    days_required: 1,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 131,
    _id: "673c75296f4f5107ba89e13a",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Create Product creation form view in Offerer Profile",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc5ada7867338068826b7",
        field: "title",
        from: "Create Product creation form view in Offerer Profile",
        to: "Develop Product Creation Form for Offerer Profile Management",
        why: "The current title lacks specificity and does not reflect the user's perspective. By specifying 'Offerer Profile Management', it becomes clearer that this feature involves managing product entries associated with an offerer's profile. This ensures the task is actionable and aligns with project goals. It also clarifies that this feature relates to profile management rather than admin or account-creation.",
      },
    ],
    created_at: "2024-11-19T11:23:21.899Z",
    __v: 4,
    days_required: 1,
    estimation: 150,
  },
  {
    priority: "NOW",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 134,
    _id: "673c7b466f4f5107ba89fa0f",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Create basic Figma file for filters, display all categories, sub categories sub sub categories.",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "67063d02b624905aa3cd5d75",
    user_name: "Carl MARION",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "marion.carl498@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc599a7867338068826a4",
        field: "title",
        from: "Create basic Figma file for filters, display all categories, sub categories sub sub categories.",
        to: "Design User-Interactive Filter and Category Display in Figma",
        why: "The current title is vague and lacks user perspective. Clarify that the feature involves designing a Figma file emphasizing user interaction with filters and category display. This ensures developers understand it's not just about creating a file, but enhancing user experience through interactive design. Specify if it's for admin, account creation, or another feature is also necessary. Consider integrating a design tool perk to enhance development efficiency.",
      },
    ],
    created_at: "2024-11-19T11:49:26.060Z",
    __v: 4,
    days_required: 1.5,
    estimation: 225,
    assigned: "Carl ",
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 135,
    _id: "673c875b6f4f5107ba8a2007",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Automated Email Alert for Demo Requests when Admin Notification",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6731d60f763daf07b92f1218",
    user_name: "Amélie Montet",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "montetamelie@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674591910b30db2c5c38e82f",
        field: "title",
        from: "Automated Email Alert for Demo Requests when Admin Notification",
        to: "Develop Automated Email Alert System for Admin Notifications on Demo Requests",
        why: "The current title is too broad and lacks specificity. Clarifying that this feature is related to 'admin' notifications and explicitly mentioning the focus on 'demo requests' makes it more granular and actionable. Ensure that this EPIC aligns with the project's goals of enhancing admin functionalities and improving user engagement. Consider if this feature is essential for the MVP. Additionally, since the module is 'brevo,' ensure integration benefits are highlighted.",
      },
    ],
    created_at: "2024-11-19T12:40:59.050Z",
    __v: 16,
    assigned: "Carl ",
    days_required: 2.5,
    estimation: 375,
    module: "brevo",
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 137,
    _id: "673c9d496f4f5107ba8a6fc4",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Faire page profil Offreur",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6447976bb35432066706ca7a",
    user_name: "Charlelie Chandelier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/66558efaf05ab59e1abf57d1b5b8eac8/charle%CC%81lie.jpeg",
    user_email: "charlelie.chandelier@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc48fa78673380688255e",
        field: "title",
        from: "Faire page profil Offreur",
        to: "Develop User Profile Page for Offerers",
        why: "The original title 'Faire page profil Offreur' lacks specificity and granularity. A more detailed title clarifies that this EPIC focuses on creating a user profile page specifically for offerers. This enhanced clarity ensures developers understand the precise feature to be built, reflecting a user-centric approach. Verify if this feature pertains to 'account-creation' or another relevant function. Evaluate its necessity for the MVP and any potential complexities. No perks are currently applicable.",
      },
    ],
    created_at: "2024-11-19T14:14:33.540Z",
    __v: 8,
    estimation: 75,
    days_required: 0.5,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 150,
    urgent: false,
    tags: [],
    index: 138,
    _id: "673f0da559ecc507b5b78dbd",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Création De produit pour les offreurs",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6447976bb35432066706ca7a",
    user_name: "Charlelie Chandelier",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/66558efaf05ab59e1abf57d1b5b8eac8/charle%CC%81lie.jpeg",
    user_email: "charlelie.chandelier@selego.co",
    suggestions: [
      {
        status: "PENDING",
        _id: "67447e858d3e5507b9e5bc66",
        field: "title",
        from: "Création De produit pour les offreurs",
        to: "Develop Product Listing and Management for Service Providers",
        why: "The current title is vague and broad. It needs to clarify the specific functionalities being developed for service providers, such as product listing and management. Ensure this task aligns with the project's goals and specifies if it pertains to 'admin', 'account-creation', or directly to service provider features.",
      },
    ],
    created_at: "2024-11-21T10:38:29.524Z",
    __v: 10,
    estimation: 600,
    days_required: 4,
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 139,
    _id: "673f35391494be07b6e4dcee",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Implement Fullstack Solution for Re-importing Initial 500 Scraped Companies",
    project_name: "Cyberconnect",
    status: "NEED_INFO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6731d60f763daf07b92f1218",
    user_name: "Amélie Montet",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "montetamelie@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc4efa786733806882615",
        field: "title",
        from: "Implement Fullstack Solution for Re-importing Initial 500 Scraped Companies",
        to: "Develop Fullstack Import Feature for Bulk Company Data",
        why: "The current title lacks specificity regarding the feature's purpose and its user-centered perspective. Clarify that this feature's aim is to enable a bulk import of company data, which is essential for effective data management and aligns with the project's fullstack scope. Ensure this EPIC reflects user-centric language by highlighting the direct action ('Develop') and specific outcome ('Bulk Company Data'). This will guide developers clearly on the expected functionality without ambiguity. Additionally, associate this task with data management features rather than 'admin' or 'account-creation'.",
      },
    ],
    created_at: "2024-11-21T13:27:21.062Z",
    __v: 9,
    assigned: "Carl ",
    days_required: 5,
    estimation: 2500,
  },
  {
    priority: "LATER",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 140,
    _id: "673f35c51494be07b6e4ddd6",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Implement a Secure Admin Login System",
    project_name: "Cyberconnect",
    status: "NEED_INFO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6731d60f763daf07b92f1218",
    user_name: "Amélie Montet",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "montetamelie@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "674dc458a786733806882547",
        field: "title",
        from: "Implement a Secure Admin Login System",
        to: "Develop Multi-Factor Authentication for Secure Admin Access",
        why: "The current title 'Implement a Secure Admin Login System' lacks specificity regarding the type of security and user perspective. Specifying 'Multi-Factor Authentication' narrows the focus and provides a clear, user-centered purpose. It also confirms the feature's association with 'admin.' Furthermore, the use of multi-factor authentication aligns with best practices for enhanced security, an essential aspect for managing admin access. Consider offering 'Sentry' as a perk for error tracking and performance monitoring, which can be valuable during the authentication system implementation. ",
      },
    ],
    created_at: "2024-11-21T13:29:41.970Z",
    __v: 7,
    assigned: "Carl ",
  },
  {
    priority: "NEXT",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 141,
    _id: "673f36231494be07b6e4e251",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Automate Import Procedure for Initial 500 Companies into System",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "6731d60f763daf07b92f1218",
    user_name: "Amélie Montet",
    user_avatar: "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y",
    user_email: "montetamelie@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "67554f235323cb0e63175a2a",
        field: "title",
        from: "Automate Import Procedure for Initial 500 Companies into System",
        to: "Streamline Company Data Import Workflow for System Integration",
        why: "The current title lacks granularity and specificity. Suggest breaking down the procedure into smaller user-centric actions, such as setting up data validation, establishing import error handling protocols, and optimizing the system for large data loads. Also, confirm if this feature specifically relates to admin functionalities. Validate its relevance and ensure it aligns with the project goals for enhanced clarity and actionability. Leverage Metabase, our BI tool, to monitor and analyze data import effectiveness, potentially available as a perk.",
      },
    ],
    created_at: "2024-11-21T13:31:15.222Z",
    __v: 9,
    assigned: "Carl",
    persona: "",
    days_required: 3,
    estimation: 1500,
  },
  {
    priority: "NOW",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 144,
    _id: "6752caf15bb0e029e2006ce8",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Menu a gauche",
    project_name: "Cyberconnect",
    status: "NEED_INFO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "600d74c384ba261c5890b6db",
    user_name: "Sebastien Le Goff",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/ad5e35c53f09f5a1f61dfc1193855e6f/1689019358260%20%281%29.jpeg.jpeg",
    user_email: "se.legoff@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "675310e58be23407b8fa6e89",
        field: "title",
        from: "Menu a gauche",
        to: "Design and Implement Left Navigation Menu",
        why: "The current title 'Menu a gauche' lacks specificity and user perspective. Translating it to 'Design and Implement Left Navigation Menu' clarifies that it requires designing and implementing a left-side navigation menu, focusing on user interaction and full-stack scope. Ensure it aligns with the user needs and project goals. This feature is specific and not related to 'admin' or 'account-creation', but rather a navigation feature. Consider proposing Sentry for error monitoring as a perk for robust implementation.",
      },
    ],
    created_at: "2024-12-06T09:59:13.630Z",
    __v: 5,
  },
  {
    priority: "NOW",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 145,
    _id: "6752cb1a5bb0e029e2006d1d",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Importer et categoriser les 3 sources de data",
    project_name: "Cyberconnect",
    status: "NEED_INFO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "600d74c384ba261c5890b6db",
    user_name: "Sebastien Le Goff",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/ad5e35c53f09f5a1f61dfc1193855e6f/1689019358260%20%281%29.jpeg.jpeg",
    user_email: "se.legoff@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6755597fb9be4f1ca516b4fd",
        field: "title",
        from: "Importer et categoriser les 3 sources de data",
        to: "Implement Data Import and Categorization for Three Sources",
        why: "The current title lacks specificity and does not clearly define the feature's intended user benefit. Specify the platforms or systems from which data will be imported and the criteria for categorization. This makes the task actionable, clear for development, and aligned with project goals. Ensure it is critical for the MVP and reassess its contribution to the business objectives. This EPIC could utilize MongoDB for enhanced data management, which could be proposed as a perk.",
      },
    ],
    created_at: "2024-12-06T09:59:54.506Z",
    __v: 4,
  },
  {
    priority: "NOW",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 146,
    _id: "6752cb3b5bb0e029e2006d44",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Quand on click sur request, ca envoi un mail a l'admin&nbsp;",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "600d74c384ba261c5890b6db",
    user_name: "Sebastien Le Goff",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/ad5e35c53f09f5a1f61dfc1193855e6f/1689019358260%20%281%29.jpeg.jpeg",
    user_email: "se.legoff@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "67530f548be23407b8fa69dc",
        field: "title",
        from: "Quand on click sur request, ca envoi un mail a l'admin",
        to: "As an admin, receive email notifications upon user request submission",
        why: "The current title lacks specificity and user-centered language. It should clearly indicate that the feature relates to the 'admin' view, highlighting the purpose is to receive mail notifications when a user submits a request. This granularity helps in understanding the specific functionality to develop and ensures the EPIC is actionable and user-centric. Consider integrating a notification system as a perk to enhance feature value .",
      },
    ],
    created_at: "2024-12-06T10:00:27.974Z",
    __v: 3,
  },
  {
    priority: "NOW",
    resource_profile: "EXPERIMENTED DEVELOPER",
    tjm: 500,
    urgent: false,
    tags: [],
    index: 147,
    _id: "6752cb4c5bb0e029e2006d5c",
    project_id: "66fea9422ea2c00ede31edb1",
    title: "Avoir un admin qui permet de modifier les trucs&nbsp;",
    project_name: "Cyberconnect",
    status: "TODO",
    scope: "Fullstack",
    business_contribution: "M",
    charge: "M",
    user_id: "600d74c384ba261c5890b6db",
    user_name: "Sebastien Le Goff",
    user_avatar: "https://bank.cellar-c2.services.clever-cloud.com/file/user/ad5e35c53f09f5a1f61dfc1193855e6f/1689019358260%20%281%29.jpeg.jpeg",
    user_email: "se.legoff@gmail.com",
    suggestions: [
      {
        status: "PENDING",
        _id: "6755596db9be4f1ca516b4df",
        field: "title",
        from: "Avoir un admin qui permet de modifier les trucs",
        to: "Implement Admin Interface for Content Editing",
        why: "The title 'Avoir un admin qui permet de modifier les trucs' is vague and does not specify the type of modifications or content. It should be more explicit to ensure clarity and actionability. By focusing on creating an admin interface specifically designed for content editing, the scope becomes clearer and aids in aligning with project objectives. This feature is associated with 'admin.'",
      },
    ],
    created_at: "2024-12-06T10:00:44.926Z",
    __v: 7,
    assigned: "a",
  },
];
