import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";
import Modal from "../../../components/modal";
import SelectSource from "../component/SelectSource";

export default ({ onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    name: "",
    linkedin: "",
    website: "",
    source_id: "",
    source_name: "",
    personnae: "",
    description: "",
  });

  useEffect(() => {
    const storedSourceName = localStorage.getItem("source_name");
    if (storedSourceName) {
      setValues((prevValues) => ({ ...prevValues, source_name: storedSourceName }));
    }
  }, []);

  useEffect(() => {
    if (values.source_name) {
      localStorage.setItem("source_name", values.source_name);
    }
  }, [values.source_name]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSourceChange = (selectedSource) => {
    setValues((prevValues) => ({
      ...prevValues,
      source_id: selectedSource._id,
      source_name: selectedSource.name,
    }));
  };

  async function onSubmit() {
    try {
      const obj = { ...values };
      const res = await api.post("/s_company", obj);
      if (!res.ok) throw res;
      toast.success("Company created");
      setValues({
        name: "",
        linkedin: "",
        website: "",
        source_id: "",
        source_name: "",
        personnae: "",
        description: "",
      });
      setOpen(false);
      onDone();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={() => setOpen(true)}>
        Create a company
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div>
          <div className="flex flex-col justify-between flex-wrap">
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Name</div>
              <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="name" value={values.name} onChange={handleChange} />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Linkedin</div>
              <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="linkedin" value={values.linkedin} onChange={handleChange} />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Website</div>
              <input className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]" name="website" value={values.website} onChange={handleChange} />
            </div>
            <div className="w-full mt-2">
              <SelectSource value={{ _id: values.source_id, name: values.source_name }} onChange={handleSourceChange} />
            </div>
            <div className="w-full mt-2">
              <select className="input w-full" name="personnae" value={values.personnae} onChange={handleChange}>
                <option value="">Select personae</option>
                <option value="STARTUPS_EARLYSTAGE">STARTUPS_EARLYSTAGE</option>
                <option value="STARTUPS_ADVANCED">STARTUPS_ADVANCED</option>
                <option value="BUSINESS_REFERRERS">BUSINESS_REFERRERS</option>
                <option value="INCUBATORS">INCUBATORS</option>
                <option value="CORPORATES">CORPORATES</option>
                <option value="GOVERNMENT">GOVERNMENT</option>
              </select>
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Description</div>
              <textarea className="w-full text-[14px] font-normal text-[#212325] rounded-[10px]" name="description" rows={4} value={values.description} onChange={handleChange} />
            </div>
          </div>
        </div>
        <br />
        <div className="w-full flex justify-end">
          <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};
