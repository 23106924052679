import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import { IoCheckmarkCircle, IoPencilOutline } from "react-icons/io5";
import Table from "../../../../components/Table";
import EpicEditModal from "./EpicModal";
import EpicCreationModal from "./EpicCreationModal";
import api from "../../../../services/api";
import DebounceInput from "../../../../components/DebounceInput";
import MultiSelect from "../../../../components/MultiSelect";

export default ({ project }) => {
  const [epics, setEpics] = useState([]);
  const [filters, setFilters] = useState({ page: 1, statuses: ["TODO", "IN_PROGRESS", "NEED_INFO"], urgent: [], modules: [], versions: [] });
  const [budgets, setBudgets] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "points", value: 1 });
  const [selectedEpics, setSelectedEpics] = useState([]);
  const [selectedEpic, setSelectedEpic] = useState(null);
  const [isEpicEditModalOpen, setIsEpicEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEpics();
  }, [filters]);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      console.log(filters);
      const { data, ok } = await api.post("/epic/search", { project_id: project._id, ...filters });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      const { data: budgets } = await api.post("/budget/search", { projectId: project._id, status: "active" });
      setBudgets(budgets);
      setEpics(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete selected epics?")) return;

      for (const epicId of selectedEpics) {
        console.log(epicId);
        const { ok } = await api.remove(`/epic/${epicId}`);
        if (!ok) {
          toast.error(`Failed to delete epic ${epicId}`);
          continue;
        }
      }
      toast.success("Epics deleted successfully");
      fetchEpics();
      setSelectedEpics([]);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete epics");
    }
  };

  const handleSave = async (itemId, value) => {
    try {
      const { ok, data } = await api.put(`/epic/${itemId}`, value);
      if (!ok) return toast.error("Failed to update");

      const index = epics.findIndex((item) => item._id === itemId);
      const newEpics = [...epics];
      newEpics[index] = { ...newEpics[index], ...value };
      setEpics(newEpics);
      toast.success(`Updated successfully`);
    } catch (error) {
      console.error(error);
      toast.error("Failed to update");
    }
  };

  const totalDays = epics.reduce((acc, item) => acc + (item.days_required || 0), 0);
  const totalEstimation = epics.reduce((acc, item) => acc + (item.estimation || 0), 0);

  return (
    <div className="space-y-4">
      <div className="bg-white p-4 rounded border border-gray-200">
        <div className="flex items-start gap-4">
          <div className="w-full flex justify-between">
            <div className="w-full grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
              <DebounceInput
                debounce={300}
                className="input w-full"
                placeholder="Search by title..."
                value={filters.search}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value, page: 1 }))}
              />
              {selectedEpics.length > 0 && (
                <>
                  <ExportButton data={selectedEpics} />
                </>
              )}
              <SelectBudgets value={filters.budget_id} onChange={(e) => setFilters((f) => ({ ...f, budget_id: e }))} project={project} />
              <SelectModules value={filters.modules} onChange={(e) => setFilters((f) => ({ ...f, modules: e }))} project={project} />
              <MultiSelect
                id="select-status"
                options={["TODO", "IN_PROGRESS", "DONE", "NEED_INFO"].map((status) => ({ value: status, label: status }))}
                values={filters.statuses?.map((status) => ({ value: status, label: status })) || []}
                onSelectedChange={(e) => setFilters((f) => ({ ...f, statuses: e.map((e) => e.value) }))}
                placeholder="Status"
              />
            </div>
          </div>
          <div>
            <EpicCreationModal
              project={project}
              onCreate={(e) => {
                fetchEpics();
                setSelectedEpic(e);
                setIsEpicEditModalOpen(true);
              }}
            />
          </div>
          {selectedEpics.length > 0 && (
            <button onClick={handleMultipleDelete} className="red-btn">
              Delete Selected Epics
            </button>
          )}
        </div>
      </div>
      {isEpicEditModalOpen && (
        <EpicEditModal
          epic={selectedEpic}
          onClose={(e) => {
            if (!selectedEpic) return;
            const index = epics.findIndex((item) => item._id === selectedEpic._id);
            const newEpics = [...epics];
            newEpics[index] = { ...newEpics[index], ...e };
            setEpics(newEpics);
            setSelectedEpic(null);
            setIsEpicEditModalOpen(false);
          }}
        />
      )}
      <Table
        total={epics.length}
        header={[
          {
            title: (
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedEpics(epics);
                  } else {
                    setSelectedEpics([]);
                  }
                }}
              />
            ),
            position: "center",
          },
          { title: "Title", key: "title" },
          { title: "Budget", key: "budget_name" },
          { title: "Days Required", key: "days_required" },
          { title: "Estimation", key: "estimation" },
          { title: "Status", key: "status" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sort={{ [sortBy.key]: sortBy.value }}
        onSort={(e) => setSortBy(sortBy.key === e ? { key: e, value: sortBy.value * -1 } : { key: e, value: 1 })}>
        <AnimatePresence>
          {epics
            .sort((a, b) => {
              if (sortBy.key === "created_at") return sortBy.value * (new Date(b.created_at) - new Date(a.created_at));
              if (sortBy.key === "points") return sortBy.value * (b.points - a.points);
              return sortBy.value * (a[sortBy.key] || "").localeCompare(b[sortBy.key] || "");
            })
            .map((item, index) => {
              return (
                <motion.tr
                  key={item._id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}>
                  <td
                    className="border px-4 py-2 cursor-pointer"
                    onClick={(e) => {
                      if (selectedEpics.some((e) => e._id === item._id)) {
                        setSelectedEpics(selectedEpics.filter((id) => id !== item._id));
                      } else {
                        setSelectedEpics([...selectedEpics, item]);
                      }
                    }}>
                    <input
                      type="checkbox"
                      className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      checked={selectedEpics.some((e) => e._id === item._id)}
                      onChange={() => {}}
                    />
                  </td>

                  <td
                    onClick={() => {
                      setSelectedEpic(item);
                      setIsEpicEditModalOpen(true);
                    }}
                    className="border px-4 py-2 text-sm hover:bg-gray-200">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">#{item.index}</span>
                        <span className="line-clamp-3">{item.title}</span>
                      </div>
                      <div className="mt-1">
                        <span className="px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">{item.module}</span>
                      </div>
                    </div>
                  </td>
                  <EditableSelectCell2
                    value={{ name: item.budget_name, _id: item.budget_id }}
                    options={budgets.map((budget) => ({ name: budget.name, _id: budget._id }))}
                    onSave={(e) => {
                      console.log(e);
                      if (!e) return handleSave(item._id, { budget_id: "", budget_name: "" });
                      handleSave(item._id, { budget_id: e._id, budget_name: e.name });
                    }}>
                    {item.budget_name}
                  </EditableSelectCell2>
                  <td className="border px-4 py-2 max-w-xs truncate">{item.days_required}</td>
                  <td className="border px-4 py-2 max-w-xs truncate">{item.estimation}</td>
                  <EditableSelectCell
                    itemId={item._id}
                    field="status"
                    value={item.status}
                    options={["TODO", "IN_PROGRESS", "NEED_INFO", "DONE"]}
                    onSave={(e) => handleSave(item._id, { status: e })}>
                    <StatusTag item={item} />
                  </EditableSelectCell>
                </motion.tr>
              );
            })}
        </AnimatePresence>
        <tr className="bg-sky-700 text-white font-bold">
          <td className="border px-4 py-2 max-w-xs truncate text-sm">Total</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalDays}</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm">{totalEstimation}</td>
          <td className="border px-4 py-2 max-w-xs truncate text-sm"></td>
        </tr>
      </Table>
    </div>
  );
};

const EditableSelectCell2 = ({ value, options, onSave, children }) => {
  const [editing, setEditing] = useState(false);
  const cellRef = useRef(null);

  const handleSave = async (e) => {
    setEditing(false);
    const find = options.find((f) => f._id === e.target.value);
    await onSave(find);
  };

  const handleClickOutside = (event) => {
    if (cellRef.current && !cellRef.current.contains(event.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <td ref={cellRef} onClick={() => setEditing(true)} onBlur={() => setEditing(false)} className="border px-4 py-2 max-w-xs truncate text-sm hover:bg-gray-200">
      {editing ? (
        <select className="input p-1" value={value?._id} onChange={handleSave}>
          <option value=""></option>
          {options.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        children
      )}
    </td>
  );
};

const EditableSelectCell = ({ itemId, field, value, options, onSave, children }) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const cellRef = useRef(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleSave = async (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    setEditing(false);
    await onSave(itemId, field, newValue);
  };

  const handleClickOutside = (event) => {
    if (cellRef.current && !cellRef.current.contains(event.target)) {
      setEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <td ref={cellRef} onClick={() => setEditing(true)} onBlur={() => setEditing(false)} className="border px-4 py-2 max-w-xs truncate text-sm hover:bg-gray-200">
      {editing ? (
        <select className="input p-1" value={currentValue} onChange={handleSave}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        children || currentValue
      )}
    </td>
  );
};

const StatusTag = ({ item }) => {
  if (!item?.status) return null;
  const colors = {
    TODO: "bg-blue-600 text-xs text-white border-gray-700",
    IN_PROGRESS: "bg-blue-100 text-xs text-blue-800",
    NEED_INFO: "bg-yellow-100 text-xs text-yellow-800",
    DONE: "bg-green-100 text-xs text-green-800",
  };
  return <div className={`px-2 py-1 rounded w-fit ${colors[item?.status]}`}>{item?.status}</div>;
};

const SelectModules = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);
  const [lastFetch, setLastFetch] = useState(0);

  async function fetchModules() {
    const { data } = await api.post("/epic/aggregate", { project_id: project._id, key: "module" });
    const arr = data.filter((e) => e._id !== null).map((e) => ({ label: e._id, value: e._id, count: e.count }));
    setOptions(arr);
    setLastFetch(Date.now());
  }

  useEffect(() => {
    // Initial fetch when component mounts
    fetchModules();
  }, [project._id]);

  // Refetch when component gains focus if more than 5 seconds have passed
  useEffect(() => {
    function handleFocus() {
      if (Date.now() - lastFetch > 5000) {
        fetchModules();
      }
    }
    window.addEventListener("focus", handleFocus);
    return () => window.removeEventListener("focus", handleFocus);
  }, [lastFetch]);

  return (
    <MultiSelect
      id="select-modules"
      options={options}
      values={value?.map((tag) => ({ value: tag, label: tag }))}
      onSelectedChange={(e) => onChange(e.map((e) => e.value))}
      placeholder="Modules"
    />
  );
};

const SelectBudgets = ({ value, onChange, project }) => {
  const [options, setOptions] = useState([]);

  async function fetchBudgets() {
    const { data } = await api.post("/budget/search", { projectId: project._id, status: "active" });
    setOptions(data);
  }

  useEffect(() => {
    fetchBudgets();
  }, [project._id]);

  return (
    <select className="input w-full" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">Select a budget</option>
      {options.map((option) => (
        <option key={option._id} value={option._id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

const ExportButton = ({ data }) => {
  const cols = [
    "module",
    "title",
    "resource_profile",
    "business_objective",
    "charge",
    "business_contribution",
    "urgent",
    "module",
    "points",
    "estimation",
    "days_required",
    "status",
    "priority",
    "comments",
  ];

  const downloadCSV = () => {
    console.log(data);
    const csvData = [cols.map((column) => column)];
    data.forEach((item) => {
      const rowData = cols.map((column) => {
        const value = item[column];
        return `"${value ? String(value).replace(/"/g, '""') : ""}"`;
      });

      csvData.push(rowData);
    });

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "exported_data.csv";
    link.click();
  };

  return (
    <button onClick={downloadCSV} className="gray-btn">
      Export CSV
    </button>
  );
};
