import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SelectBudget from "../../../../../components/SelectBudget";
import SelectEpic from "../../../../../components/SelectEpic";
import Modal from "../../../../../components/modal";
import api from "../../../../../services/api";
import RichTextEditorSun from "../../../../../components/RichTextEditor";
export default function ({ projectId, onDone }) {
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState({ description: "", multiTicket: false });

  const { search } = useLocation();

  const navigate = useNavigate();

  const handleCreate = async () => {
    const nf = { ...value, project_id: projectId };
    const { data } = await api.post("/notion", nf);
    setValue({ description: "", multiTicket: false });
    const query = new URLSearchParams(search);
    query.set("task_modal_id", data[0]._id);
    navigate({ search: query.toString() });
    onDone();
  };

  return (
    <div>
      <button className="blue-btn mr-2" onClick={() => setOpenModal(true)}>
        New ticket
      </button>
      {openModal && (
        <Modal
          isOpen={true}
          onClose={() => {
            setOpenModal(false);
          }}>
          <div className="flex flex-col items-center justify-center w-[800px] p-6 mx-auto">
            <h3 className="text-xl font-medium mb-6">Create new task</h3>
            <div className="grid grid-cols-3 w-full gap-6">
              <div className="col-span-2">
                <RichTextEditorSun
                  buttonList={[
                    ["font", "fontSize"],
                    ["bold", "underline", "italic"],
                    ["align", "list"],
                    ["link", "image"],
                  ]}
                  values={value.description}
                  options={{ minHeight: "300px" }}
                  onChange={(e) => setValue((prevValue) => ({ ...prevValue, description: e }))}
                />
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <div className="text-sm font-medium mb-2">Category</div>
                  <SelectCategory
                    value={value.category}
                    onChange={(e) => setValue((prevValue) => ({ ...prevValue, category: e.target.value }))}
                  />
                </div>
                <div>
                  <div className="text-sm font-medium mb-2">Budget</div>
                  <SelectBudget
                    value={value.budget}
                    onChange={(e) => setValue((prevValue) => ({ ...prevValue, budget_id: e._id, budget_name: e.name }))}
                    projectId={projectId}
                  />
                </div>
                <div>
                  <div className="text-sm font-medium mb-2">Epic</div>
                  <SelectEpic
                    value={value.epic}
                    onChange={(e) => setValue((prevValue) => ({ ...prevValue, epic_id: e._id, epic_name: e.title, epic_module: e.module }))}
                    projectId={projectId}
                    budgetId={value.budget_id}
                  />
                </div>
              </div>
            </div>
            <button disabled={!value.description} onClick={handleCreate} className="blue-btn w-full py-3 mt-6">
              Create task
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

const SelectCategory = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No category</option>
      <option value="BUG">BUG</option>
      <option value="FEATURE">FEATURE</option>
    </select>
  );
};
