import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoMdCloseCircle } from "react-icons/io";

import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import TabItem from "../../components/TabItem";
import SelectProject from "../../components/selectProject";
import UserSelector from "../../components/selectUser";

import api from "../../services/api";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";
const HISTORY = "HISTORY";
const TEST = "TEST";

export default () => {
  const { id } = useParams();
  const [audit, setAudit] = useState();
  const [tab, setTab] = useState(INFORMATION);

  useEffect(() => {
    fetchAudit();
  }, [id]);

  const fetchAudit = async () => {
    try {
      const { data, ok } = await api.get(`/audit/${id}`);
      if (!ok) return toast.error("Error fetching audit data.");
      setAudit(data);
    } catch (e) {
      toast.error("Error fetching audit data.");
    }
  };

  if (!audit) return <Loader />;

  return (
    <div className="p-5">
      <div className="w-full gap-x-4 gap-y-2 ">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
          <TabItem title="Information" tab="INFORMATION" onClick={() => setTab("INFORMATION")} active={tab === "INFORMATION"} />
          <TabItem title="Selego Test" tab="TEST" onClick={() => setTab("TEST")} active={tab === "TEST"} />
          <TabItem title="Audit History" tab="HISTORY" onClick={() => setTab("HISTORY")} active={tab === "HISTORY"} />
          <TabItem title="Raw Data" tab="RAW_DATA" onClick={() => setTab("RAW_DATA")} active={tab === "RAW_DATA"} />
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50 w-full">
          {tab === INFORMATION && <Information audit={audit} setAudit={setAudit} />}
          {tab === RAW_DATA && <RawData audit={audit} />}
          {tab === HISTORY && <History history={audit?.history} />}
          {tab === TEST && <Test audit={audit} />}
        </div>
      </div>
    </div>
  );
};

const Information = ({ audit, setAudit }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState(audit);
  const [btnLoading, setBtnLoading] = useState(false);

  const onUpdate = async () => {
    setBtnLoading(true);
    const { data, ok, code } = await api.put(`/audit/${audit._id}`, values);
    if (!ok) {
      toast.error(code || "Error updating!");
      setBtnLoading(false);
    } else {
      toast.success("Updated!");
      setBtnLoading(false);
      setAudit(data);
    }
  };

  const onDelete = async () => {
    const { ok } = await api.remove(`/audit/${audit._id}`);
    if (!ok) return toast.error("Error deleting audit!");
    toast.success("Deleted!");
    navigate("/audit");
  };

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-6">
        <div className="w-ful">
          <div className="font-medium mb-2 text-sm">Select Project</div>
          <SelectProject className="w-full" value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id })} />
        </div>

        <div className="w-full">
          <div className="font-medium mb-2 text-sm">Select Auditor</div>
          <UserSelector
            placeholder="Select Auditor"
            className="w-full"
            value={{
              _id: values.author_id,
              name: values.author_name,
              avatar: values.author_avatar,
            }}
            onChange={(e) => {
              setValues({ ...values, author_avatar: e.avatar, author_name: e.name, author_id: e._id });
              console.log(e);
            }}
          />
        </div>

        <div className="grid grid-cols-3 gap-3">
          <div className="w-full mb-3">
            <div className="font-medium mb-2 text-sm">Last Audit Date</div>
            <input
              className="input block"
              placeholder="Select Date"
              type="date"
              value={values?.last_audit_date ? values?.last_audit_date?.slice(0, 10) : ""}
              onChange={(e) => setValues({ ...values, last_audit_date: e.target.value })}
            />
          </div>

          <div className="w-full">
            <div className="text-sm font-medium mb-2">Audit Status</div>
            <select className="input" value={values.status || ""} onChange={(e) => setValues({ ...values, status: e.target.value })}>
              <option value="" disabled>
                Select
              </option>
              {["todo", "audited", "addressed"].map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full">
            <div className="text-sm font-medium mb-2">Effort to Audit</div>
            <select className="input" value={values.effort_to_audit || ""} onChange={(e) => setValues({ ...values, effort_to_audit: e.target.value })}>
              <option value="" disabled>
                Select
              </option>
              {["S", "M", "L", "XL"].map((effort) => (
                <option key={effort} value={effort}>
                  {effort}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3">
          <div className="w-full">
            <div className="text-sm font-medium mb-2">High Issues</div>
            <input type="number" className="input" value={values.high_issues || 0} onChange={(e) => setValues({ ...values, high_issues: e.target.value })} />
          </div>

          <div className="w-full">
            <div className="text-sm font-medium mb-2">Medium Issues</div>
            <input type="number" className="input" value={values.medium_issues || 0} onChange={(e) => setValues({ ...values, medium_issues: e.target.value })} />
          </div>

          <div className="w-full">
            <div className="text-sm font-medium mb-2">Low Issues</div>
            <input type="number" className="input" value={values.low_issues || 0} onChange={(e) => setValues({ ...values, low_issues: e.target.value })} />
          </div>
        </div>

        <div className="w-full">
          <div className="text-sm font-medium mb-2">Audit Document</div>
          <input
            type="text"
            placeholder="Enter document link..."
            className="input"
            value={values.audit_document || ""}
            onChange={(e) => setValues({ ...values, audit_document: e.target.value })}
          />
        </div>

        <div className="w-full">
          <div className="text-sm font-medium mb-2">Tracker</div>
          <input
            type="text"
            placeholder="Enter sheets link..."
            className="input"
            value={values.tracker || ""}
            onChange={(e) => setValues({ ...values, tracker: e.target.value })}
          />
        </div>

        <div>
          <div className="text-sm font-medium mb-2">Team on time of audit</div>
          <UserSelector
            placeholder="Select team member"
            onChange={(user) => {
              if (!user?._id) return;
              const newUsers = values?.team.filter((u) => u._id !== user._id);
              newUsers.push({
                _id: user._id,
                name: user.name,
                avatar: user.avatar,
              });
              setValues({ ...values, team: newUsers });
            }}
          />
        </div>

        <div className="flex flex-row gap-3 p-2 flex-wrap mt-3">
          {(values.team || []).map((user, index) => {
            return (
              <div key={index} className="flex justify-center items-center flex-col">
                <div className="relative">
                  <img src={user.avatar} alt="User Avatar" className="w-12 h-12 rounded-full object-cover" />
                  <button
                    className="absolute -top-1 -right-1"
                    onClick={() => {
                      const newUsers = values?.team.filter((u) => u._id !== user._id);
                      setValues({ ...values, team: newUsers });
                    }}>
                    <IoMdCloseCircle className="text-gray-500 text-lg" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-full mt-8 border-t pt-5 grid grid-cols-3 gap-3">
        <div className="w-full">
          <div className="text-sm font-medium mb-1">Sentry </div>
          <div className="flex gap-3 items-center">
            <label htmlFor="sentry_set_up" className="text-xs text-gray-600">
              Does this project have the Sentry setup ready?
            </label>
            <input
              type="checkbox"
              name="sentry_set_up"
              id="sentry_set_up"
              checked={values?.sentry}
              value={values?.sentry}
              onChange={(e) => setValues({ ...values, sentry: e.target.checked })}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="text-sm font-medium mb-1">Uptime Robot </div>
          <div className="flex gap-3 items-center">
            <label htmlFor="timerobot" className="text-xs text-gray-600">
              Does this project have Uptime Robot setup ready?
            </label>
            <input
              type="checkbox"
              name="timerobot"
              id="timerobot"
              checked={values?.timerobot}
              value={values?.timerobot}
              onChange={(e) => setValues({ ...values, timerobot: e.target.checked })}
            />
          </div>
        </div>

        <div className="w-full">
          <div className="text-sm font-medium mb-1">Selego Stack </div>
          <div className="flex gap-3 items-center">
            <label htmlFor="selego_stack" className="text-xs text-gray-600">
              Is this project built with the Selego stack?
            </label>
            <input
              type="checkbox"
              name="selego_stack"
              id="selego_stack"
              checked={values?.selego_stack}
              value={values?.selego_stack}
              onChange={(e) => setValues({ ...values, selego_stack: e.target.checked })}
            />
          </div>
        </div>
      </div>

      <div className="mt-5 w-1/3">
        <div className="text-sm font-medium mb-2">Hosted On</div>
        <select className="input" value={values?.hosted_on || ""} onChange={(e) => setValues({ ...values, hosted_on: e.target.value })}>
          <option value="" disabled>
            Select
          </option>
          {["scaleway", "clevercloud"].map((hosted_on) => (
            <option key={hosted_on} value={hosted_on}>
              {hosted_on}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full mt-8 border-t pt-5">
        <div className="text-sm font-medium mb-2">Notes</div>
        <textarea
          placeholder="Enter notes..."
          className="input"
          name="notes"
          id="notes"
          onChange={(e) => setValues({ ...values, note: e.target.value })}
          value={values?.note}></textarea>
      </div>

      <div className="flex items-center justify-between space-x-2 mt-10 border-t pt-8">
        <button className="btn bg-red-500 text-white" onClick={onDelete}>
          Delete
        </button>

        <LoadingButton className="btn bg-primary text-white" loading={btnLoading} onClick={() => onUpdate()}>
          Update
        </LoadingButton>
      </div>
    </div>
  );
};

const Test = ({ audit }) => {
  const [values, setValues] = useState(audit?.selego_test || {});
  const [btnLoading, setBtnLoading] = useState(false);

  const onUpdate = async () => {
    setBtnLoading(true);

    const { ok } = await api.put(`/audit/${audit?._id}`, { ...audit, selego_test: { ...values, score: Object.values(values).filter((v) => v === true).length } });
    if (!ok) return toast.error("Error updating test!");
    toast.success("Test updated!");
    setBtnLoading(false);
  };

  return (
    <div>
      <div className="mb-8 p-4 bg-gray-50 rounded-lg">
        <h3 className="font-medium mb-2">About the Selego Test</h3>
        <p className="text-sm text-gray-600 mb-2">This is a quick 3-minute test to rate the quality of a software team. Each "yes" answer is worth 1 point.</p>
        <p className="text-sm text-gray-600">
          The test focuses on key aspects like deployment frequency, code review practices, team knowledge, and business understanding. Answer honestly to get an accurate
          assessment of your team's current state.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.release_multiple_times_per_day} onChange={(e) => setValues({ ...values, release_multiple_times_per_day: e.target.checked })} />
            <span>Do you release multiple times per day?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.prs_reviewed} onChange={(e) => setValues({ ...values, prs_reviewed: e.target.checked })} />
            <span>Are all PRs reviewed by someone else?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.know_roadmap} onChange={(e) => setValues({ ...values, know_roadmap: e.target.checked })} />
            <span>Do all developers working on the project know the roadmap?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.know_cash} onChange={(e) => setValues({ ...values, know_cash: e.target.checked })} />
            <span>Do you know how long the company has (in cash) before bankruptcy?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.know_users} onChange={(e) => setValues({ ...values, know_users: e.target.checked })} />
            <span>Do you know who the users are and their usage patterns?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.know_tech_spent} onChange={(e) => setValues({ ...values, know_tech_spent: e.target.checked })} />
            <span>Do you know what the current monthly cash spent on tech/product/growth is?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.code_simple} onChange={(e) => setValues({ ...values, code_simple: e.target.checked })} />
            <span>Is the code simple enough for junior developers to start coding in less than 0.5 days?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.challenge_spec} onChange={(e) => setValues({ ...values, challenge_spec: e.target.checked })} />
            <span>Did you challenge/change specs in the last 2 weeks to simplify development?</span>
          </label>
        </div>

        <div>
          <label className="flex items-center gap-2">
            <input type="checkbox" checked={values?.tshirt_pricing} onChange={(e) => setValues({ ...values, tshirt_pricing: e.target.checked })} />
            <span>Did you do T-shirt sizing for current features (business value vs dev cost)?</span>
          </label>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="font-medium"> Total Score: {Object.values(values).filter((v) => v === true).length}/9</div>
          <LoadingButton className="btn bg-primary text-white" loading={btnLoading} onClick={onUpdate}>
            Update Test
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

const RawData = ({ audit }) => {
  return (
    <div className="text-sm bg-slate-800 p-5 rounded-md text-white">
      <pre className="break-all whitespace-pre-wrap">{JSON.stringify(audit, null, 2)}</pre>
    </div>
  );
};

const History = ({ history }) => (
  <div>
    <h3 className="font-semibold mb-4">Audit History</h3>
    <div className="flow-root mb-5">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden border shadow-md rounded-lg bg-white">
            <table className="min-w-[900px] w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden">
              <thead className="">
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Last Audit Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">
                    High Issues
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Medium Issues
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Low Issues
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Team
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Effort
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Audit Doc
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Tracker
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {history?.map((history, i) => (
                  <tr key={i}>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="max-w-xl"> {history?.data?.last_audit_date ? new Date(history?.data?.last_audit_date).toLocaleDateString() : "-"}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{Status[history?.data?.status] || "-"}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      <span
                        className={`border p-1 px-3 rounded-md ${
                          history?.data?.high_issues > 5
                            ? "text-red-600 font-semibold bg-red-100 border-red-200 "
                            : "text-yellow-700 font-semibold bg-yellow-100 border-yellow-300 "
                        }`}>
                        {history?.data?.high_issues || "-"}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history?.data?.medium_issues || "-"}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{history?.data?.low_issues || "-"}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {!history?.data?.team?.length ? (
                        "-"
                      ) : (
                        <div className="flex gap-1">
                          {history?.data?.team?.map((person) => (
                            <img src={person.avatar} alt={person.name} className="w-7 h-7 rounded-full object-cover" />
                          ))}
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{Effort[history?.data?.effort_to_audit] || "-"}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {history?.data.audit_document ? (
                        <a
                          href={history?.data.audit_document}
                          target="_blank"
                          onClick={(e) => e.stopPropagation()}
                          className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 hover:bg-blue-100 border-blue-200 border">
                          Audit
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm">
                      {history?.data.tracker ? (
                        <a
                          href={history?.data.tracker}
                          target="_blank"
                          onClick={(e) => e.stopPropagation()}
                          className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 hover:bg-green-100 border-green-200 border">
                          Tracker
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Effort = {
  S: <div className="border border-green-200 bg-green-50 text-green-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">S</div>,
  M: <div className="border border-yellow-300 bg-yellow-50 text-yellow-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">M</div>,
  L: <div className="border border-purple-300 bg-purple-50 text-purple-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">L</div>,
  XL: <div className="border border-red-200 bg-red-50 text-red-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">XL</div>,
};

const Status = {
  done: <div className="border border-green-200 bg-green-50 text-green-800 rounded p-1 px-3 text-xs flex flex-row items-center space-x-2 w-fit  font-semibold">Done</div>,
  todo: <div className="border border-yellow-300 bg-yellow-50 text-yellow-600 rounded p-1 text-xs flex flex-row items-center px-2 space-x-2 w-fit  font-semibold">Todo</div>,
};
