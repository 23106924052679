import React, { useState } from "react";
import Modal from "../../../../components/modal";

import Campaigns from "./campaigns";
import Company from "./company";
import Users from "./users";
import Messages from "./messages";

import AIRecommendations from "./recommendation";

export default ({ company, setCompany }) => {
  const [selectedUser, setSelectedUser] = useState(null);

  if (!company) return <div />;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        setCompany(null);
        setSelectedUser(null);
      }}>
      <div className="w-full h-screen m-4bg-[#FBFBF9] overflow-y-auto p-4">
        <div className="grid grid-cols-4 gap-4">
          <Company company={company} setCompany={setCompany} onClick={() => setSelectedUser(null)} />
          <div className="col-span-3 grid grid-cols-2 gap-4">
            <Users company={company} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
            <div className="flex flex-col gap-4">
              {/* <AIRecommendations company={company} user={selectedUser} /> */}
              <Messages company={company} user={selectedUser} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// Il m'a envoye un mail avec ca Bonsoir Sébastien, J’ai découvert ton profile sur cofondateur au chômage, et si je ne dis pas de bêtises on est tous les deux sur Barcelone 😊 J’ai un profile marketing/business avec en plus un master en computer sciences ça aide pour comprendre comment fonctionne les choses haha mais pas assez pour coder parce que ce n’est ni ma vocation ni ma passion. J’ai un projet de SAAS qui nécessite des connaissances assez poussées en dev/IA ! Si ça t’intéresse d’échanger fais-moi signe et je t’explique ce que j’ai en tête via un google meet 😊 Bonne soirée,
