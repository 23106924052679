import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import MultiSelect from "../../../components/MultiSelect";
import Loader from "../../../components/loader";

export default ({ values = [], onChange }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await api.get("/project?status=active");
      let projects = res.data
        .sort(function (a, b) {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
          return 0;
        })
        .map((j) => ({ value: j._id, label: j.name }));
      projects.push({ value: "", label: "" });
      setProjects(projects);
    })();
  }, []);

  if (!projects?.length) return <Loader size="small" />;

  return <MultiSelect id="select-projects" options={projects} values={values} onSelectedChange={onChange} placeholder="Select a project" />;
};
