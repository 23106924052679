import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import Pagination from "../../../components/Paginator";
import MultiSelect from "../../../components/MultiSelect";
import Table from "../../../components/Table";
import GithubIcon from "../../../assets/github.svg";
import DebounceInput from "../../../components/DebounceInput";
import Modal from "../../../components/modal";

import api from "../../../services/api";
import toast from "react-hot-toast";

const APPLICANT_STATUS_COLOR = {
  NEW: "#8B5CF6",
  REJECT: "#EF4444",
  SHORTLIST: "#3B82F6",
  CONTACTED: "#10B981",
  INTERVIEW: "#F59E0B",
  TECHNICAL_TEST: "#2563EB",
  FINAL_LIST: "#F59E0B",
  HIRE: "#10B981",
};

function statusColor(status) {
  switch (status) {
    case "NEW":
      return "bg-purple-600";
    case "REJECT":
      return "bg-red-600";
    case "SHORTLIST":
      return "bg-blue-400";
    case "CONTACTED":
      return "bg-green-400";
    case "FINAL_LIST":
      return "bg-yellow-700";
    case "INTERVIEW":
      return "bg-yellow-400";
    case "TECHNICAL_TEST":
      return "bg-blue-700";
    case "HIRE":
      return "bg-green-700";
    default:
      return "bg-gray-300";
  }
}

export default ({ job }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState({ created_at: -1 });
  const [filter, setFilter] = useState(() => ({
    search: searchParams.get("search") || "",
    page: parseInt(searchParams.get("page")) || 1,
    per_page: parseInt(searchParams.get("per_page")) || 50,
    applicant_status: searchParams.get("applicant_status")?.split(",") || ["NEW", "SHORTLIST", "CONTACTED", "FINAL_LIST", "INTERVIEW", "HIRE", "TECHNICAL_TEST"],
  }));
  const [users, setUsers] = useState();
  const [allUsers, setAllUsers] = useState();
  const [total, setTotal] = useState(0);
  const [isScoring, setIsScoring] = useState(false);
  const [scoringProgress, setScoringProgress] = useState(0);
  const [selectedApplicants, setSelectedApplicants] = useState([]);

  useEffect(() => {
    load();
  }, [filter, sort]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (filter.search) params.set("search", filter.search);
    if (filter.page !== 1) params.set("page", filter.page);
    if (filter.per_page !== 50) params.set("per_page", filter.per_page);
    if (filter.applicant_status.length) params.set("applicant_status", filter.applicant_status.join(","));
    setSearchParams(params);
  }, [filter, setSearchParams]);

  async function load() {
    const { data } = await api.post(`/user/search`, { ...filter, role: "applicant", job_ids: [job._id], sort });
    setUsers(data.users);
    setTotal(data.total);

    const response = await api.post(`/user/search`, { applicant_status: filter.applicant_status, role: "applicant", job_ids: [job._id], per_page: 10_000 });
    setAllUsers(response.data.users);
  }

  const handleSort = (key) => {
    if (key === "socials") return;

    setSort((prevSort) => {
      const newSortOrder = prevSort[key] === 1 ? -1 : 1;
      return { [key]: newSortOrder };
    });
  };

  const scoreApplicants = async () => {
    try {
      setIsScoring(true);
      const batchSize = 1;
      const { data } = await api.post(`/user/search`, { search: filter.search, applicant_status: filter.applicant_status, role: "applicant", job_ids: [job._id] });
      const applicants = data.users.filter((user) => !user.ai_score);
      console.log("✌️  applicants", applicants.length);

      for (let i = 0; i < applicants.length; i += batchSize) {
        const batch = applicants.slice(i, i + batchSize);
        const { ok } = await api.post(`/job/${job._id}/michelle`, {
          applicant_ids: batch.map((user) => user._id),
        });
        if (!ok) throw new Error("Error scoring applicants");

        // Update progress
        setScoringProgress(Math.round(((i + batchSize) / applicants.length) * 100));

        // Reload after each batch to show new scores
        await load();
      }

      toast.success("All applicants scored successfully");
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setIsScoring(false);
      setScoringProgress(0);
    }
  };

  const CreateApplicant = ({}) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState({});

    async function onCreate() {
      try {
        if (!values.name) return toast.error("Missing name !");
        if (!values.linkedin) return toast.error("Missing linkedin !");
        if (!values.email) return toast.error("Missing email !");
        values.role = "applicant";
        values.job_id = job._id;
        values.job_title = job.title;
        values.applicant_status = "NEW";
        const { data, ok } = await api.post("/user", values);
        if (!ok) return toast.error("Wrong request");
        toast.success("Created!");
        setOpen(false);
        load();
      } catch (error) {
        console.log(values, "values");
        toast.error("Error: " + error.code);
      }
    }

    return (
      <div>
        <button className="btn btn-primary !text-sm" onClick={() => setOpen(true)}>
          Create New Applicant
        </button>
        <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
          <div className="w-full mb-6">
            <div className="text-base font-medium mb-2">{job?.title}</div>
          </div>
          <div className="w-full mb-6">
            <div className="text-sm font-medium mb-2">Name</div>
            <input className="projectsInput" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
          </div>
          <div className="w-full mb-6">
            <div className="text-sm font-medium mb-2">LinkedIn</div>
            <input className="projectsInput" value={values.linkedin} onChange={(e) => setValues({ ...values, linkedin: e.target.value })} />
          </div>
          <div className="w-full mb-6">
            <div className="text-sm font-medium mb-2">Email</div>
            <input className="projectsInput" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
          </div>
          <button className="blue-btn mt-4" onClick={onCreate}>
            Create
          </button>
        </Modal>
      </div>
    );
  };

  if (!users || !allUsers) return <div className="p-3 text-sm">Loading...</div>;

  const countries = allUsers.reduce((acc, user) => {
    if (user.country && !acc.find((c) => c.value === user.country)) {
      acc.push({ value: user.country, label: user.country });
    }
    return acc;
  }, []);

  return (
    <div className="p-1">
      <div className="flex gap-2 items-center mb-3 my-2 justify-between">
        <div className="flex gap-2">
          <DebounceInput debounce={300} value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value })} className="input" placeholder="Search" />

          <MultiSelect
            id="select-priority"
            values={filter.applicant_status.map((p) => ({ value: p, label: p }))}
            options={["NEW", "REJECT", "SHORTLIST", "CONTACTED", "FINAL_LIST", "INTERVIEW", "HIRE", "TECHNICAL_TEST"].map((p) => ({ value: p, label: p }))}
            onSelectedChange={(e) => setFilter((f) => ({ ...f, applicant_status: e.map((e) => e.value) }))}
            placeholder="Status"
          />
          <MultiSelect
            id="select-country"
            values={filter.country?.map((c) => ({ value: c, label: c })) || []}
            options={countries}
            onSelectedChange={(e) => setFilter((f) => ({ ...f, country: e.map((e) => e.value) }))}
            placeholder="Country"
          />
        </div>

        <div className="flex gap-2 items-center">
          <div className="font-semibold">{total} applicants</div>
          <button className="btn gray-btn" onClick={scoreApplicants} disabled={isScoring}>
            {isScoring ? `Scoring... ${scoringProgress}%` : "Score Applicants"}
          </button>
          <Export users={users} />
          <CreateApplicant />
        </div>
        {selectedApplicants.length > 0 && (
          <div className="flex items-center gap-2">
            <span className="text-gray-500 text-sm">Selected {selectedApplicants.length} applicants</span>
            <BatchEditApplicantStatus selectedApplicants={selectedApplicants} onUpdate={load} />
          </div>
        )}
      </div>

      <Table
        header={[
          {
            title: (
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedApplicants(users);
                  } else {
                    setSelectedApplicants([]);
                  }
                }}
              />
            ),
          },
          { title: "Status", key: "applicant_status" },
          { title: "Name", key: "name" },
          { title: "Country", key: "country" },
          { title: "Channel", key: "channel_name" },
          { title: "AI Score", key: "ai_score" },
          { title: "Socials", key: "socials" },
          { title: "Created at", key: "created_at" },
        ]}
        sort={sort}
        onSort={handleSort}
        total={total}>
        {users.map((user) => (
          <tr key={user._id} className="hover:bg-gray-200">
            <td>
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                checked={selectedApplicants.some((a) => a._id === user._id)}
                onChange={() => {
                  const isSelected = selectedApplicants.some((a) => a._id === user._id);
                  if (isSelected) {
                    setSelectedApplicants(selectedApplicants.filter((a) => a._id !== user._id));
                  } else {
                    setSelectedApplicants([...selectedApplicants, user]);
                  }
                }}
              />
            </td>
            <select
              className={`max-w-20 text-[12px] text-black border-2 font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm`}
              style={{ borderColor: APPLICANT_STATUS_COLOR[user.applicant_status] }}
              value={user.applicant_status}
              name="status"
              onChange={async (e) => {
                const { ok, data } = await api.put(`/user/${user._id}`, { applicant_status: e.target.value });
                if (!ok) return toast.error("Failed to update status");
                toast.success(
                  <div>
                    <p className="font-semibold text-xs">{user.job_title}</p>
                    <p className="my-1">{user.name}</p>
                    <div className={`text-white px-2 rounded-lg ${statusColor(data.applicant_status)}`}>{data.applicant_status}</div>
                  </div>,
                );
                load();
              }}>
              <option key="NEW" value="NEW">
                NEW
              </option>
              <option key="REJECT" value="REJECT">
                REJECT
              </option>
              <option key="SHORTLIST" value="SHORTLIST">
                SHORTLIST
              </option>
              <option key="CONTACTED" value="CONTACTED">
                CONTACTED
              </option>
              <option key="INTERVIEW" value="INTERVIEW">
                INTERVIEW
              </option>
              <option key="FINAL_LIST" value="FINAL_LIST">
                FINAL_LIST
              </option>
              <option key="HIRE" value="HIRE">
                HIRE
              </option>
              <option key="TECHNICAL_TEST" value="TECHNICAL_TEST">
                TECHNICAL_TEST
              </option>
            </select>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
              <Link to={`/job/${job._id}?user_modal_id=${user._id}&user_modal_tab=application`}>{user.name}</Link>
            </td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.country}</td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.channel_name}</td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user.ai_score ? `${user.ai_score}/100` : "-"}</td>
            <td className="px-3 py-2 max-w-xs truncate">
              <div className="flex space-x-2">
                {user.linkedin && (
                  <a href={user.linkedin} target="_blank" rel="noopener noreferrer">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" alt="LinkedIn Logo" className="w-6 h-6" />
                  </a>
                )}
                {user.email && (
                  <a href={`mailto:${user.email}`}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png" alt="Email Logo" className="w-6 h-6" />
                  </a>
                )}
                {user.resume && (
                  <a href={user.resume} target="_blank" rel="noopener noreferrer">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/PDF_icon.svg" alt="Resume Logo" className="w-6 h-6" />
                  </a>
                )}
                {user.github && (
                  <a href={user.github} target="_blank" rel="noreferrer">
                    <img alt="github" src={GithubIcon} className="w-6 h-6" />
                  </a>
                )}
              </div>
            </td>
            <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">{user?.created_at && user.created_at.slice(0, 10)}</td>
          </tr>
        ))}
      </Table>
      <div className="my-4">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.per_page)} />
      </div>
    </div>
  );
};

const Export = ({ users }) => {
  const onClick = async () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Index;Name;Email;Github;Linkedin;Resume;Status;TJM;\n`;

    for (let i = 0; i < users.length; i++) {
      const e = users[i];
      const str = `${i};${e.name};${e.email};${e.github};${e.linkedin};${e.resume};${e.applicant_status};${e.tjm};\n`;
      csvContent += str;
    }

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="btn btn-primary" onClick={onClick}>
      Export
    </button>
  );
};

const BatchEditApplicantStatus = ({ selectedApplicants, onUpdate }) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = async (value) => {
    try {
      for (const applicant of selectedApplicants) {
        const { ok } = await api.put(`/user/${applicant._id}`, { applicant_status: value });
        if (!ok) {
          toast.error(`Failed to update status for applicant ${applicant._id}`);
          return;
        }
      }
      toast.success("Successfully updated status for selected applicants");
      setInputValue("");
      onUpdate();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update applicants");
    }
  };

  return (
    <div className="relative">
      <select
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          handleChange(e.target.value);
        }}
        className="input w-32">
        <option key="NEW" value="NEW">
          NEW
        </option>
        <option key="REJECT" value="REJECT">
          REJECT
        </option>
        <option key="SHORTLIST" value="SHORTLIST">
          SHORTLIST
        </option>
        <option key="CONTACTED" value="CONTACTED">
          CONTACTED
        </option>
        <option key="INTERVIEW" value="INTERVIEW">
          INTERVIEW
        </option>
        <option key="FINAL_LIST" value="FINAL_LIST">
          FINAL_LIST
        </option>
        <option key="HIRE" value="HIRE">
          HIRE
        </option>
        <option key="TECHNICAL_TEST" value="TECHNICAL_TEST">
          TECHNICAL_TEST
        </option>
      </select>
    </div>
  );
};
