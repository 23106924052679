import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdModeEdit, MdCached } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Modal from "../../../../components/modal";
import Switch from "../../../../components/Switch";
import api from "../../../../services/api";

import Voucher from "./voucher";

import { YEARS } from "../../../../constants";
import Loader from "../../../../components/loader";
import Tooltip from "react-tooltip";

const BudgetAdmin = ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    inactive: searchParams.get("inactive") === "true",
    year: searchParams.get("year") || "",
    month: searchParams.get("month") || "",
  });
  const [data, setData] = useState([]);

  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { projectId: project._id };
        if (!filters.inactive) query.status = "active";
        const res = await api.post(`/budget/search`, query);
        if (!res.ok) return toast.error("Failed to fetch credits");
        setData(res.data);
        // set search params
        const newSearchParams = new URLSearchParams();
        if (filters.inactive) newSearchParams.set("inactive", "true");
        if (filters.year) newSearchParams.set("year", filters.year);
        if (filters.month) newSearchParams.set("month", filters.month);
        setSearchParams(newSearchParams);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [project, filters]);

  useEffect(() => {
    if (!data.length) return;
    const fetchData = async () => {
      try {
        const queryA = { projectId: project._id };
        queryA.budgetIds = data.map((e) => e._id);

        const { data: a } = await api.post(`/activity/search`, queryA);
        setActivities(a);

        const queryB = { projectId: project._id };
        queryB.notCategories = ["INCOME", "DISBURSEMENT"];
        queryB.per_page = 20000;
        queryB.budgetIds = data.map((e) => e._id);

        const { data: b } = await api.post(`/bank/search`, queryB);
        setBanks(b.banks.map((bank) => ({ ...bank, amount: bank.amount })));

        const queryI = { projectId: project._id, per_page: 20000 };
        queryI.budgetIds = data.map((e) => e._id);

        const { data: i } = await api.post(`/invoice/search`, queryI);
        setInvoices(i);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [data]);

  if (!["admin", "user"].includes(user.role)) return <div>Forbidden</div>;

  const handleBudgetChange = (budget) => {
    const index = data.findIndex((e) => e._id === budget._id);
    const newData = [...data];
    newData[index] = budget;
    setData(newData);
  };

  const handleBudgetDelete = (id) => {
    const newData = data.filter((e) => e._id !== id);
    setData(newData);
  };

  const totalAmount = data.filter((e) => e?.amount).reduce((acc, budget) => acc + budget.amount, 0);
  const totalRealUserCost = activities.reduce((acc, activity) => acc + activity.cost, 0);
  const totalBankCost = banks.filter((bank) => bank.category !== "INCOME" && bank.category !== "DISBURSEMENT").reduce((acc, bank) => acc - bank.amount, 0);
  const totalAmountUsed = data.filter((e) => e?.amountUsed).reduce((acc, budget) => acc + budget.amountUsed, 0);
  const totalAmountRemaining = totalAmount - totalAmountUsed;
  const totalAmountInvoiced = invoices.reduce((acc, invoice) => acc + invoice.netTotal, 0);
  const rows = [];

  for (let i = 0; i < data.length; i++) {
    const budget = data[i];
    const findActivities = activities.filter((activity) => activity.budgetId === budget._id);
    const findBanks = banks.filter((bank) => bank.budgetId === budget._id);
    const findInvoices = invoices.filter((invoice) => invoice.budgetId === budget._id);
    rows.push({ budget: budget, invoices: findInvoices, banks: findBanks, activities: findActivities });
  }

  const computeProfit = (item) => {
    const invoiced = item.invoices.reduce((acc, cur) => acc + cur.netTotal, 0);
    const userCost = item.activities.reduce((acc, cur) => acc + cur.cost, 0);
    const bankCost = item.banks.filter((bank) => bank.category !== "INCOME" && bank.category !== "DISBURSEMENT").reduce((acc, bank) => acc - bank.amount, 0);
    const realCost = userCost + bankCost;

    if (item.budget.status === "inactive") return invoiced - realCost;
    // if (item.invoices.length > 0) return invoiced - realCost;
    if (item.budget.amountUsed > item.budget.amount) return item.budget.amount - realCost;
    return item.budget.amountUsed - realCost;
  };

  const totalProfit = rows.reduce((acc, cur) => acc + computeProfit(cur), 0);

  return (
    <div className="space-y-6 py-8">
      <div className="flex items-center gap-4 justify-between px-4">
        <h2 className="text-xl font-semibold">{data.length} budgets found</h2>
        <div className="flex items-center gap-4">
          <label className="text-sm font-semibold">Show Inactive</label>
          <Switch checked={filters.inactive} onChange={() => setFilters({ ...filters, inactive: !filters.inactive })} />
          <CreateModal project={project} onDone={(v) => setData([...data, v])} />
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        <div className="bg-white border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Total amount</h2>
          <p className="text-xl font-bold">{(totalAmount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</p>
        </div>
        <div className="bg-white border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Total amount used</h2>
          <p className={`text-xl font-bold ${totalAmountUsed > totalAmount ? "text-red-700" : "text-green-700"}`}>
            {(totalAmountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
          </p>
        </div>
        <div className="bg-white border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Total amount remaining</h2>
          <p className={`text-xl font-bold ${totalAmountRemaining < 0 ? "text-red-700" : "text-green-700"}`}>
            {(totalAmountRemaining || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
          </p>
        </div>
        <div className="bg-white border border-gray-200 rounded-md p-4 flex-1">
          <h2 className="text-lg font-semibold">Total amount invoiced</h2>
          <p className={`text-xl font-bold ${totalAmountInvoiced < 0 ? "text-red-700" : "text-green-700"}`}>
            {(totalAmountInvoiced || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
          </p>
        </div>
      </div>

      <div className="w-full p-6 bg-white border border-gray-300 rounded">
        <table className="table-fixed">
          <thead className="border-b border-gray-300">
            <tr>
              <th colSpan={2} className="text-left font-semibold text-sm px-3">
                Budget
              </th>
              <th className="text-left font-semibold text-sm p-2">Amount</th>
              <th className="text-left font-semibold text-sm px-3">Amount used</th>
              <th className="text-left font-semibold text-sm px-3">Amount used (%)</th>
              <th className="text-left font-semibold text-sm px-3">Real cost</th>
              <th colSpan={3} className="text-left font-semibold text-sm px-3">
                Invoices
              </th>
              <th className="text-left font-semibold text-sm pr-10">Profitability</th>
              <th className="text-left font-semibold text-sm pr-10">Profitability (%)</th>
              <th className="text-left font-semibold text-sm pr-10"></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <BudgetRow key={index} index={index} item={row} onChange={handleBudgetChange} onDelete={() => handleBudgetDelete(row.budget._id)} profit={computeProfit(row)} />
            ))}
          </tbody>
          <tfoot className="border-t border-gray-300">
            <tr>
              <td colSpan={2} className="p-2 text-sm font-semibold">
                Total
              </td>
              <td className="text-left p-2 text-sm font-semibold">{(totalAmount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
              <td className="text-left p-2 text-sm font-semibold">{(totalAmountUsed || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
              <td className="text-left p-2 text-sm font-semibold">{totalAmount && totalAmountUsed ? ((totalAmountUsed / totalAmount) * 100).toFixed(2) : 0}%</td>
              <td className="text-left p-2 text-sm font-semibold" data-tip data-for="real_cost">
                {(totalBankCost + totalRealUserCost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
                <Tooltip id="real_cost" border borderColor="#e6e6e6" effect="solid" className="max-w-xs text-sm keepOpacity" backgroundColor="#fff" textColor="#000">
                  <div>User cost : {(totalRealUserCost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</div>
                  <div>Bank cost : {(totalBankCost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</div>
                </Tooltip>
              </td>

              <td colSpan={3} className="text-left p-2 text-sm font-semibold">
                {(totalAmountInvoiced || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
              </td>
              <td className="text-left p-2 text-sm font-semibold">{(totalProfit || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
              <td className="text-left p-2 text-sm font-semibold">
                {totalProfit && totalAmount ? ((totalProfit / (totalAmountUsed > totalAmount ? totalAmount : totalAmountUsed)) * 100).toFixed(2) : 0}%
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {/* <Broker project={project} /> */}
      <Voucher project={project} />
    </div>
  );
};

// const Broker = ({ project, data, rows }) => {
//   return (
//     <div className="w-full p-6 border border-gray-300 rounded">
//       <h2 className="text-xl font-semibold">Broker</h2>
//     </div>
//   );
// };

const BudgetRow = ({ item, index, onChange, onDelete, profit }) => {
  const navigate = useNavigate();
  const invoiced = item.invoices.reduce((acc, cur) => acc + cur.netTotal, 0);

  const userCost = item.activities.reduce((acc, cur) => acc + cur.cost, 0);
  const bankCost = item.banks.filter((bank) => bank.category !== "INCOME" && bank.category !== "DISBURSEMENT").reduce((acc, bank) => acc - bank.amount, 0);
  const realCost = userCost + bankCost;

  return (
    <tr className={index !== 0 ? "border-t border-gray-200" : ""}>
      <td colSpan={2} className="text-left p-2 text-sm">
        <div>
          <p className="font-semibold">
            {item.budget.name} {item.budget.status === "inactive" && <span className="border border-gray-500 rounded px-1 text-gray-500">Inactive</span>}{" "}
          </p>
          <p className="text-sm text-gray-600">
            {item.budget.startAt ? new Date(item.budget.startAt).toLocaleDateString() : ""} - {item.budget.endAt ? new Date(item.budget.endAt).toLocaleDateString() : ""}
          </p>
        </div>
      </td>
      <td className="text-left p-2 text-sm">{(item.budget.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td className="text-left p-2 text-sm">{(item.budget.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td className="text-left p-2 text-sm">{item.budget.amount && item.budget.amountUsed ? ((item.budget.amountUsed / item.budget.amount) * 100).toFixed(2) : 0}%</td>
      <td className="text-left p-2 text-sm" data-tip data-for={`real_cost_row_${index}`}>
        {(realCost || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
        <Tooltip id={`real_cost_row_${index}`} border borderColor="#e6e6e6" effect="solid" className="max-w-xs text-sm keepOpacity" backgroundColor="#fff" textColor="#000">
          <div className="flex flex-col gap-2">User cost : {(userCost || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div>Bank cost : {(bankCost || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
        </Tooltip>
      </td>
      <td colSpan={3} className="text-left p-2 text-sm">
        <div className="flex-1 flex flex-col gap-2">
          <div className="font-semibold mb-2">{(invoiced || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          {item.invoices.map((e, i) => {
            console.log("e", e);
            return (
              <div key={i} className="flex items-center gap-2">
                <Link to={`/invoice/${e._id}`} target="_blank" className="text-xs truncate underline">
                  <Tooltip border borderColor="#e6e6e6" id={`invoice-${e._id}`} effect="solid" className="max-w-xs text-sm keepOpacity" backgroundColor="#fff" textColor="#000">
                    {e.total.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </Tooltip>
                  <div data-tip data-for={`invoice-${e._id}`}>
                    {e.name} {(e.netTotal || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </div>
                </Link>

                {e.sent === "yes" ? (
                  <span className="border border-green-500 rounded text-green-500 px-1 text-xs truncate">Sent</span>
                ) : (
                  <span className="border border-gray-500 rounded text-gray-500 px-1 text-xs truncate">Not sent</span>
                )}
                {e.received === "yes" ? (
                  <span className="border border-green-500 rounded text-green-500 px-1 text-xs truncate">Received</span>
                ) : e.sent === "yes" ? (
                  <span className="border border-red-500 rounded text-red-500 px-1 text-xs truncate">Not received</span>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </td>
      <td className="text-left p-2 text-sm">{(profit || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td className="text-left p-2 text-sm">
        {profit && item.budget.amount ? ((profit / (item.budget.amountUsed > item.budget.amount ? item.budget.amount : item.budget.amountUsed)) * 100).toFixed(2) : 0}%
      </td>
      <td className="text-left p-2 text-sm">
        <div className="flex-1 flex items-center gap-2">
          <EditModal data={item.budget} onChange={onChange} onDelete={onDelete} />
          <button className="empty-btn" onClick={() => navigate(`/project/${item.budget.projectId}/breakdown?budgetId=${item.budget._id}`)}>
            <MdCached />
          </button>
        </div>
      </td>
    </tr>
  );
};

const CreateModal = ({ project, onDone }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    projectId: project._id,
    projectName: project.name,
    type: project.type,
    name: "",
    year: new Date().getFullYear(),
  });
  const user = useSelector((state) => state.Auth.user);
  const handleSubmit = async () => {
    if (!values.name) return toast.error("Missing title !");
    if (!values.startAt) return toast.error("Missing start date !");
    if (!values.endAt) return toast.error("Missing end date !");

    try {
      const res = await api.post("/budget", values);
      if (!res.ok) return toast.error("Failed to create budget");

      toast.success("Budget created");
      onDone(res.data);
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to create budget");
    }
  };

  return (
    <div>
      <button className="blue-btn" onClick={() => setOpen(true)}>
        Create budget
      </button>
      <Modal isOpen={open} className="w-2/3" onClose={() => setOpen(false)}>
        <div className="px-16 py-12 space-y-8">
          <h2 className="text-xl font-semibold mb-4">Create new budget</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-name">
                Name
              </label>
              <input id="budget-name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} placeholder="Budget name" className="input" />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-name">
                Year
              </label>
              <select id="budget-year" value={values.year} onChange={(e) => setValues({ ...values, year: e.target.value })} className="select">
                <option value="">Select year</option>
                {YEARS.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-startAt">
                Start at
              </label>
              <input className="input" type="date" value={values.startAt ? values.startAt.split("T")[0] : ""} onChange={(e) => setValues({ ...values, startAt: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-endAt">
                End at
              </label>
              <input className="input" type="date" value={values.endAt ? values.endAt.split("T")[0] : ""} onChange={(e) => setValues({ ...values, endAt: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-type">
                Type
              </label>
              <select className="input" value={values.type} onChange={(e) => setValues({ ...values, type: e.target.value })}>
                <option value="startup-project">startup-project</option>
                <option value="prospection">prospection</option>
                <option value="admin">admin</option>
                <option value="startup-invest">startup-invest</option>
              </select>
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button className="gray-btn mr-2" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className="blue-btn" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const EditModal = ({ data, onChange, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(data);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues(data);
  }, [data]);

  if (!values) return null;

  const handleSubmit = async () => {
    if (!values.name) return toast.error("Missing name !");
    setLoading(true);
    try {
      const res = await api.put(`/budget/${data._id}`, values);
      if (res.ok) {
        toast.success("Created!");
        onChange(res.data);
        setOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update");
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure you want to delete this budget ?");
    if (!confirm) return;

    try {
      const res = await api.remove(`/budget/${data._id}`);
      if (!res.ok) return toast.error(res.code);
      toast.success("Deleted!");
      onDelete();
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete");
    }
  };

  return (
    <>
      <button className="empty-btn" onClick={() => setOpen(true)}>
        <MdModeEdit />
      </button>
      <Modal isOpen={open} className="w-2/3" onClose={() => setOpen(false)}>
        <div className="px-16 py-12 space-y-8">
          <h2 className="text-xl font-semibold mb-4">Edit budget</h2>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-name">
                Id
              </label>
              <input className="input" value={values._id} disabled />
            </div>
            <div />
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-name">
                Name
              </label>
              <input className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-status">
                Status
              </label>
              <select className="input" value={values.status} onChange={(e) => setValues({ ...values, status: e.target.value })}>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-description">
                Description
              </label>
              <input className="input" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-type">
                Type
              </label>
              <select className="input" value={values.type} onChange={(e) => setValues({ ...values, type: e.target.value })}>
                <option value="startup-project">startup-project</option>
                <option value="prospection">prospection</option>
                <option value="admin">admin</option>
                <option value="startup-invest">startup-invest</option>
              </select>
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-amount">
                Amount
              </label>
              <input className="input" type="number" value={values.amount} onChange={(e) => setValues({ ...values, amount: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-startAt">
                Start at
              </label>
              <input className="input" type="date" value={values.startAt ? values.startAt.split("T")[0] : ""} onChange={(e) => setValues({ ...values, startAt: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="budget-endAt">
                End at
              </label>
              <input className="input" type="date" value={values.endAt ? values.endAt.split("T")[0] : ""} onChange={(e) => setValues({ ...values, endAt: e.target.value })} />
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between gap-2">
            <button className="red-btn w-40" onClick={handleDelete} disabled={loading}>
              {loading ? <Loader size="small" color="white" /> : "Delete"}
            </button>
            <div className="flex items-center gap-2">
              <button className="gray-btn w-40" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="blue-btn w-40" onClick={handleSubmit} disabled={loading}>
                {loading ? <Loader size="small" color="white" /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BudgetAdmin;
