import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { MissionIcon } from "../../../assets/Icons";
import { classNames, copyToClipboard } from "../../../utils";
import SelectUser from "../../../components/selectUser";
import { AiOutlineLink } from "react-icons/ai";
import api from "../../../services/api";
import CommentForm from '../components/CommentForm';
import CommentList from '../components/CommentList';

const MissionCard = () => {
  const { id } = useParams();
  const [mission, setMission] = useState();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("about");
  const [refreshComments, setRefreshComments] = useState(false);
  const [leadAISuggestion, setLeadAISuggestion] = useState('');
  const [isLoadingAIGenerate, setIsLoadingAIGenerate] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/mission_ao/${id}`);
        setMission(data);
      } catch (e) {
        setLoading(false);
        toast.error(e.message);
      }
      setLoading(false);
    })();
  }, [id]);

  const getLeadAISuggestion = async() => {
    setIsLoadingAIGenerate(true)
    const {ok, data} = await api.get(`/mission_ao/${id}/bertrand`);
    if(ok){
      setLeadAISuggestion(data)
    }
    setIsLoadingAIGenerate(false);
  }

  if (loading) return <div>chargement</div>;

  //get domain from url
  const domain = mission.link ? mission.link.split("/")[2] : "";

  const isLead = mission.status === 'lead'
  const rightColWidth = isLead ? 'w-full md:w-3/5' : 'w-full'

  return (
    <div>
      <div className="flex flex-col md:flex-row flex-row-reverse w-full h-full bg-white">
        <div className={`flex flex-col ${rightColWidth} h-full order-2 md:order-1`}>
          <div className="flex flex-row items-center  p-3 !pr-14">
            <div className="w-full">
              <div className="flex gap-3">
                <div className="h-8 w-8">
                  <MissionIcon />
                </div>
                <div className="flex items-start gap-1">
                  <div>
                    <div className="text-lg font-bold mb-2">{ mission.name || mission.offer_name }</div>
                    <div className="flex items-center space-x-4 ">
                      {/* {mission.buyer && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Buyer: </span>
                          <span className="font-medium text-dull-black">{mission.buyer}</span>
                        </div>
                      )}
                      {mission.city && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">City: </span>
                          <span className="font-medium text-dull-black">{mission.city}</span>
                        </div>
                      )} */}
                      {/* {mission.reference && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Reference: </span>
                          <span className="font-medium text-dull-black">{mission.reference}</span>
                        </div>
                      )}
                      {mission.url && (
                        <div className="flex items-center text-sm space-x-1">
                          <span className="text-dull-grey">Link to the offer: </span>
                          <a className="font-medium" href={mission.url} target="_blank">
                            View offer
                          </a>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full ml-5 my-1">
            <div>
              {(mission.sectors || []).map((sector) => (
                <span key={sector} className="bg-blue-100 text-blue-600 px-1 mr-2 text-xs">
                  {sector}
                </span>
              ))}
            </div>
          </div>
          <div className="flex items-center text-sm space-x-1 ml-5 my-1">
            <span className="text-dull-grey">Estimated closing date: </span>
            <span className="font-medium text-dull-black">{mission.closure_date ? new Date(mission.closure_date).toLocaleString() : <></>}</span>
            <div className="ml-4">
              <a
               target="_blank"
               href={mission.url}
               className="text-primary hover:text-blue-500 flex flex-row items-center cursor-pointer">
                 <AiOutlineLink/>
                 <span> open link</span>
              </a>
            </div>
          </div>
          {mission.localisation?.length>4 && 
          <div className="p-4 flex flex-row w-full gap-3">
            <h4 className="font-medium text-primary-700"> Localization: </h4>
            <p>
              {mission.localisation}
            </p>
          </div>}
          {mission.suggestions && <div className="flex flex-col w-3/4 p-4 bg-white-300">
           <div className="p-4 bg-lighter-grey rounded-md shadow-md border border-gray-200 mb-2">
              <h3 className="text-lg font-semibold text-blue-900 mb-2">Advice:</h3>
              <p className="text-gray-700 leading-relaxed">{mission.suggestions.advice}</p>
           </div>
           <div className="p-4 bg-gray-100 rounded-md shadow-md">
            <h3 className="text-lg font-semibold text-gray-800 mb-3">Steps:</h3>
            <ul className="list-decimal pl-5 space-y-2">
            {mission.suggestions.next_steps.map((step, index) => (
            <li key={index} className="bg-white p-3 rounded-md shadow-sm border border-gray-200">
             <span className="text-gray-700">{step}</span>
           </li>
            ))}
           </ul>
            </div>
          </div>}
          <nav className="flex flex-row w-full bg-gray-50 border border-gray-100">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} />
            {user.role === "admin" && <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />}
          </nav>
          <div className={`p-4 ${isLead ? 'w-full': 'w-2/3'}`}>
            <CommentList setRefreshComments={setRefreshComments} refreshComments={refreshComments} id={id}/>
          </div>
          <div className={`p-4 ${isLead ? 'w-full': 'w-2/3'}`}>
            <CommentForm setRefreshComments={setRefreshComments} id={id}/>
          </div>
          <div className={`p-4 w-full`}>
            {tab === "about" && <About mission={mission} />}
            {tab === "raw" && user.role === "admin" && <Raw mission={mission} />}
          </div>
        </div>
        {isLead && (
        <div
         className="bg-white w-full md:w-2/5 min-w-[300px] flex flex-col h-auto md:h-[100vh] justify-center items-start border-t md:border-t-0 md:border-l border-gray-300 order-1 md:order-2"
         style={{ resize: "horizontal", overflow: "auto", minWidth: "300px", maxWidth: "50%" }}
         >
       <div className="w-full h-1/2 flex flex-col rounded-lg shadow-md justify-center items-center p-2">
      <button
        onClick={getLeadAISuggestion}
        className="w-full py-2 px-4 bg-primary-500 text-white font-semibold rounded hover:bg-primary-700 transition duration-300"
      >
        {isLoadingAIGenerate ? 'Generating ...' : 'Generate AI Response (BERTRAND)'}
       </button>
       <div className="w-full flex flex-col mt-4">
         <div className="flex justify-between items-center">
          <h4 className="text-lg font-medium text-primary-700">Message:</h4>
           <button
            className="text-sm text-gray-500 bg-gray-200 px-2 py-1 rounded hover:bg-gray-300 transition duration-200"
             onClick={() => {
              navigator.clipboard.writeText(leadAISuggestion.message || "Nothing generated!");
            }}>
             Copy
          </button>
          </div>
            <p
             className="w-full h-60 p-3 bg-gray-100 text-dull-black border border-gray-300 rounded focus:outline-none overflow-y-scroll"
             placeholder="Nothing generated!">
             {leadAISuggestion.message || "Nothing generated!"}
           </p>
          </div>
        <div className="w-full flex flex-col mt-4">
        <div className="flex justify-between items-center">
         <h4 className="text-lg font-medium text-primary-700">Strategy:</h4>
          <button
              className="text-sm text-gray-500 bg-gray-200 px-2 py-1 rounded hover:bg-gray-300 transition duration-200"
              onClick={() => {
               navigator.clipboard.writeText(leadAISuggestion.strategie || "Nothing generated!");
              }}>
            Copy
          </button>
           </div>
            <p
             className="w-full h-60 p-3 bg-gray-100 text-dull-black border border-gray-300 rounded focus:outline-none overflow-y-scroll"
             placeholder="Nothing generated!">
             {leadAISuggestion.strategie || "Nothing generated!"}
           </p>
         </div>
       </div>
      </div>
    )}
      </div>
    </div>
  );
};

const About = ({ mission }) => {
  return (
    <div className="w-3/4">
      <div>
        <div className="mb-10 p-2 leading-7 bg-lighter-grey whitespace-pre-line">{mission.description ? mission.description : "Pas de description pour le moment..."}</div>
      </div>
    </div>
  );
};

const Raw = ({ mission }) => (
  <div>
    <pre className="text-xs bg-gray-800 text-gray-100 rounded p-2">{JSON.stringify(mission, null, 2)}</pre>
  </div>
);

const TabItem = ({ tab, title, setTab, active }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames("px-4 py-2 cursor-pointer text-sm text-gray-600 hover:text-black", active ? "text-black border-b-2 border-blue-400" : "")}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default MissionCard;
