import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { HiMagnifyingGlass } from "react-icons/hi2";

import Loader from "../../components/loader";
import Modal from "../../components/modal";
import LoadingButton from "../../components/loadingButton";
import SelectProject from "../../components/selectProject";
import Pagination from "../../components/Paginator";
import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const [uptimeRobots, setUptimeRobots] = useState();
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
    per_page: 10,
  });
  const [total, setTotal] = useState();

  useEffect(() => {
    get();
  }, [filter]);

  const get = async () => {
    try {
      const { data, ok, code, total } = await api.post("/uptime_robot/search", {
        search: filter.search,
        page: filter.page || 1,
        per_page: filter.per_page || 10,
      });
      if (!ok) return toast.error("Error!" || code);
      setUptimeRobots(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
      return toast.error("Error fetching uptime robots!");
    }
  };

  if (!uptimeRobots) return <Loader />;

  return (
    <div className="mx-auto p-4">
      <h2 className="text-xl mb-3 font-semibold text-gray-900">Uptime robot</h2>

      <div className="flex items-center justify-between mb-5">
        <div className="relative w-96">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            value={filter.search}
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            className="block w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Search project..."
          />
        </div>
        <Create />
      </div>

      <div className="flow-root mb-5">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border shadow-md rounded-lg bg-white">
              <table className="min-w-[900px] w-full divide-y divide-primary-black-50 rounded-lg overflow-hidden">
                <thead className="">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Project
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Project URLs
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {uptimeRobots?.map((uptime_robot, i) => (
                    <tr key={i} className="hover:bg-gray-50 transition-colors cursor-pointer" onClick={() => navigate(`/uptime-robot/${uptime_robot._id}`)}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">{uptime_robot?.project_name}</div>
                      </td>

                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex flex-wrap gap-2">
                          {uptime_robot?.project_urls.map((url, i) => (
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-700 bg-blue-50 border px-2 py-1 hover:bg-blue-100 rounded truncate block max-w-xs text-sm font-medium"
                              onClick={(event) => event.stopPropagation()}>
                              {url}
                            </a>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.per_page)} />
    </div>
  );
};

const Create = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const onCreate = async () => {
    setBtnLoading(true);
    try {
      const { ok, code, data } = await api.post("/uptime_robot", values);
      if (!ok) return toast.error(code || "Error creating uptime robot.");

      toast.success("Uptime robot created successfully!");
      setOpen(false);
      setValues({});
      navigate(`/uptime-robot/${data._id}`);
    } catch (error) {
      toast.error(error?.code || "Error creating uptime robot!");
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div>
      <button className="bg-primary-50 border border-primary-700 text-primary-700 px-5 py-1.5 rounded-md text-sm" onClick={() => setOpen(true)}>
        Add Uptime Robot
      </button>

      <Modal isOpen={open} className="!w-[90vw] md:!w-[700px] p-6" onClose={() => setOpen(false)}>
        <h1 className="text-lg font-semibold mb-5 mt-3">Add Uptime Robot</h1>

        <div className="w-full mb-3">
          <div className="font-medium mb-2 text-sm">Select Project</div>
          <SelectProject className="w-full" value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id })} />
        </div>

        <div className="flex justify-end items-center">
          <LoadingButton
            className="bg-primary-50 text-primary-900 border px-4 py-1.5 rounded border-primary-600 mt-4  disabled:cursor-not-allowed"
            loading={btnLoading}
            disabled={!values.project_id}
            onClick={() => onCreate()}>
            Create
          </LoadingButton>
        </div>
      </Modal>
    </div>
  );
};
