import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";
import StudFlixAiPng from "../../assets/studflix_ai.png";
import { HiSearch, HiClock, HiUsers, HiExternalLink } from "react-icons/hi";

export default function MeetingsAi() {
  const [meetings, setMeetings] = useState([]);
  const [filters, setFilters] = useState({ search: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMeetings();
  }, []);

  async function fetchMeetings(e) {
    try {
      e?.preventDefault();
      setLoading(true);
      const response = await api.post("/meeting_ai/search", filters);
      setLoading(false);
      if (!response.ok) return toast.error("Erreur lors de la récupération des réunions");
      const sortedMeetings = (response.data || []).sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
      setMeetings(sortedMeetings);
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de la récupération des réunions :", error);
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1a1a1a] to-[#272727]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header with Logo */}
        <div className="flex justify-center mb-12">
          <img src={StudFlixAiPng} alt="StudFlix AI" className="h-16 object-contain" />
        </div>

        {/* Search Form */}
        <form onSubmit={fetchMeetings} className="mb-12">
          <div className="max-w-xl mx-auto">
            <div className="relative">
              <HiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
              <input
                className="w-full bg-[#333333] text-white placeholder-gray-400 rounded-lg pl-10 pr-4 py-3 focus:ring-2 focus:ring-red-500 focus:outline-none transition-all"
                value={filters.search}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                placeholder="Rechercher une réunion..."
                disabled={loading}
              />
            </div>
          </div>
        </form>

        {/* Meetings Grid */}
        {loading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : meetings.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {meetings.map((meeting) => (
              <Tile key={meeting._id} meeting={meeting} />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-400 py-12">
            <p className="text-lg">Aucune réunion trouvée</p>
          </div>
        )}
      </div>
    </div>
  );
}

const Tile = ({ meeting }) => {
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const formatParticipantName = (participant) => {
    if (!participant.first_name && !participant.last_name) return participant.name;
    return `${participant.first_name} ${participant.last_name?.[0] || ""}`.trim();
  };

  return (
    <Link to={`/meeting_ai/${meeting._id}`}>
      <div className="group bg-[#333333] rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300">
        <div className="p-6">
          {/* Date Badge */}
          <div className="flex items-center text-gray-400 text-sm mb-4">
            <HiClock className="mr-2" />
            {formatDate(meeting.start_time)}
          </div>

          {/* Title */}
          <h3 className="text-xl font-bold text-white mb-3 group-hover:text-red-500 transition-colors">{meeting.title}</h3>

          {/* Participants */}
          <div className="flex flex-wrap gap-2 mb-4">
            {meeting.participants?.slice(0, 3).map((participant, index) => (
              <div key={index} className="relative group/tooltip">
                <span className="text-sm text-gray-300">
                  {formatParticipantName(participant)}
                  {index < meeting.participants.length - 1 && ", "}
                </span>
                {participant.email && (
                  <div className="absolute bottom-full left-0 mb-2 hidden group-hover/tooltip:block z-10">
                    <div className="bg-gray-900 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                      {participant.name}
                      <br />
                      {participant.email}
                    </div>
                  </div>
                )}
              </div>
            ))}
            {meeting.participants?.length > 3 && <span className="text-sm text-gray-400">+{meeting.participants.length - 3}</span>}
          </div>

          {/* Summary */}
          <p className="text-gray-300 text-sm mb-6 line-clamp-3">{meeting.summary}</p>

          {/* Actions */}
          <a
            href={meeting.report_url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors">
            <HiExternalLink className="mr-2" />
            Rapport Read AI
          </a>
        </div>
      </div>
    </Link>
  );
};
