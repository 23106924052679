import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import api from "../../../services/api";
import Modal from "../../../components/modal";
import Pagination from "../../../components/Paginator";
import DebounceInput from "../../../components/DebounceInput";

export default () => {
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState({ created_at: -1 });
  const [filter, setFilter] = useState({ search: "", page: 1, pageSize: 30, status: ["active", "inactive"] });
  const [jobs, setJobs] = useState();
  const [dashboardStats, setDashboardStats] = useState({
    activeJobs: [],
    applicants: [],
    recentApplicants: [],
    applicantStats: {},
  });

  useEffect(() => {
    load();
    loadDashboardStats();
  }, [filter, sort]);

  async function load() {
    try {
      const { ok, data, total } = await api.post("/job/search", { ...filter, sort });
      if (!ok) return console.error("Error fetching jobs");
      setJobs(data);
      setTotal(total);
    } catch (error) {
      console.error(error);
    }
  }

  async function loadDashboardStats() {
    try {
      // Get active jobs
      const responseJob = await api.post("/job/search", { status: ["active", "inactive"], pageSize: 10_000 });

      // Get recent applicants
      const responseUser = await api.post("/user/search", {
        role: "applicant",
        sort: "-created_at",
        per_page: 10_000,
        job_ids: responseJob.data.map((job) => job._id),
      });

      setDashboardStats({
        jobs: responseJob.data,
        applicants: responseUser.data.users,
      });
    } catch (error) {
      console.error(error);
      toast.error("Error loading dashboard stats");
    }
  }

  if (!jobs) return <div className="p-3 text-sm">Loading...</div>;

  return (
    <div className="p-3">
      {/* Add Dashboard Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <DashboardCard title="Active Jobs" value={dashboardStats.jobs?.filter((j) => j.status === "active").length} icon="📋" />
        <DashboardCard title="Inactive Jobs" value={dashboardStats.jobs?.filter((j) => j.status === "inactive").length} icon="📋" />
        <DashboardCard title="Applicants" value={dashboardStats.applicants?.length} icon="📝" />
        <DashboardCard title="Pending Reviews" value={dashboardStats.applicants?.filter((a) => a.applicant_status === "NEW").length} icon="⏳" />
      </div>

      <section className="mb-6">
        <div className="flex flex-1 items-center gap-4 justify-between mb-3">
          <nav className="bg-white rounded-md flex item-center gap-1 border border-neutral-300 p-1">
            <div
              onClick={() => setFilter({ ...filter, status: ["active", "inactive"] })}
              className={`${filter.status !== "archived" ? "blue-btn" : "transparent-btn"} relative rounded px-4 flex items-center gap-2 cursor-pointer`}>
              <div className="flex items-center gap-2 truncate text-xs">Active & Inactive</div>
            </div>
            <div
              onClick={() => setFilter({ ...filter, status: "archived" })}
              className={`${filter.status === "archived" ? "blue-btn" : "transparent-btn"} relative rounded px-4 flex items-center gap-2 cursor-pointer`}>
              <div className="flex items-center gap-2 truncate text-xs">Archived Jobs</div>
            </div>
          </nav>
          <DebounceInput debounce={300} value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value })} className="input" placeholder="Search" />
          <div className="flex gap-2 items-center">
            <Create />
            <SyncWebflow />
          </div>
        </div>
      </section>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {(jobs || []).map((job) => (
          <JobCard key={job._id} job={job} />
        ))}
      </div>

      <div className="flex w-full mt-5">
        <Pagination page={filter.page} setPage={(page) => setFilter({ ...filter, page })} last={Math.ceil(total / filter.pageSize)} />
      </div>
    </div>
  );
};

const JobCard = ({ job }) => {
  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [jobposts, setJobposts] = useState([]);

  async function loadStats() {
    try {
      setLoading(true);
      const responseUser = await api.post("/user/search", {
        job_ids: [job._id],
        role: "applicant",
        sort: { created_at: -1 },
        per_page: 10_000,
      });
      setApplicants(responseUser.data?.users || []);

      const responseJobPost = await api.post("/jobpost/search", { job_id: job._id });
      setJobposts(responseJobPost.data || []);
    } catch (error) {
      console.error("Error loading job stats:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadStats();
  }, []);

  if (loading) return <JobCardSkeleton />;

  return (
    <div className={`bg-white rounded-lg shadow hover:shadow-md transition-shadow ${job.status === "inactive" ? "opacity-50" : ""}`}>
      <div className="p-4">
        <div className="flex justify-between items-start mb-4">
          <div>
            <Link to={`/job/${job._id}`} className="text-lg font-semibold text-blue-600 hover:text-blue-800">
              {job.title}
            </Link>
            <div className="text-sm text-gray-600 mt-1 flex items-center gap-2">
              <span>#{job.index}</span>
              <span>•</span>
              <span>{job.location}</span>
              <span>•</span>
              <span>{job.contract_type}</span>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-xs text-gray-500">Posted</span>
            <span className="text-xs text-gray-500 text-right">{moment(job.created_at).fromNow()}</span>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
          <div className="relative">
            <Link
              to={`/job/${job._id}?applicant_status=NEW`}
              className={`block p-2 rounded-lg transition-all ${
                applicants.filter((a) => a.applicant_status === "NEW").length > 0 ? "bg-blue-50 hover:bg-blue-100 shadow-sm hover:shadow" : "hover:bg-gray-50"
              }`}>
              <div
                className={`text-sm font-medium ${applicants.filter((a) => a.applicant_status === "NEW").length > 0 ? "text-blue-600 flex items-center gap-1" : "text-gray-600"}`}>
                New
                {applicants.filter((a) => a.applicant_status === "NEW").length > 0 && (
                  <span className="flex h-2 w-2">
                    <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-blue-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
                  </span>
                )}
              </div>
              <div className="flex flex-col">
                <div className={`text-xl font-semibold ${applicants.filter((a) => a.applicant_status === "NEW").length > 0 ? "text-blue-600" : ""}`}>
                  {applicants.filter((a) => a.applicant_status === "NEW").length ?? "..."}
                </div>
                <div className={`text-xs ${applicants.filter((a) => moment(a.created_at).isAfter(moment().subtract(48, "hours"))).length > 0 ? "text-blue-500" : "text-gray-500"}`}>
                  +{applicants.filter((a) => moment(a.created_at).isAfter(moment().subtract(48, "hours"))).length ?? 0} last 48h
                </div>
              </div>
            </Link>
          </div>
          <div className="relative">
            <Link to={`/job/${job._id}`} className="hover:opacity-75">
              <div className="text-sm font-medium text-gray-600">Applications</div>
              <div className="flex flex-col">
                <div className="text-xl font-semibold">{applicants.length ?? "..."}</div>
              </div>
            </Link>
          </div>

          <div>
            <div className="text-sm font-medium text-gray-600">Views</div>
            <div className="text-xl font-semibold">{jobposts.reduce((total, item) => total + item.views, 0) ?? "..."}</div>
          </div>

          <div>
            <div className="text-sm font-medium text-gray-600">Conversion</div>
            <div className="text-xl font-semibold">
              {jobposts.length > 0 && applicants.length > 0 ? `${((applicants.length * 100) / jobposts.reduce((total, item) => total + item.views, 0)).toFixed(1)}%` : "..."}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center text-sm text-gray-500 pt-3 border-t">
          <div className="flex items-center gap-2">
            <img src={job.user_avatar} alt={job.user_name} className="w-6 h-6 rounded-full" />
            <span>{job.user_name}</span>
          </div>
          <div className="flex gap-2">
            <StatusBadge status={job.status} />
          </div>
        </div>
      </div>
    </div>
  );
};

const StatusBadge = ({ status }) => {
  const statusColors = {
    active: "bg-green-100 text-green-800",
    inactive: "bg-gray-100 text-gray-800",
    draft: "bg-yellow-100 text-yellow-800",
  };

  return <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusColors[status] || statusColors.draft}`}>{status}</span>;
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ project_id: "62fb3ecdf4d045068e31a78d" });
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);

  async function onCreate() {
    if (!values.title) return toast.error("Missing title !");
    const res = await api.post("/job", values);

    let obj = {};
    obj.job_id = res.data._id;
    obj.job_title = res.data.title;

    obj.user_id = user._id;
    obj.user_name = user.name;
    obj.user_avatar = user.avatar;

    obj.channel_id = "650c0ae98e232b2a9879d6c0";
    obj.channel_name = "Accounting";

    const jobPost = await api.post("/jobpost", obj);
    toast.success("Created!");

    navigate(`/job/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary !text-sm" onClick={() => setOpen(true)}>
        Create a job
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Title (*)</div>
          <input className="projectsInput block" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};

const SyncWebflow = () => {
  const [loading, setLoading] = useState(false);

  async function sync() {
    setLoading(true);
    try {
      const { ok } = await api.post("/job/sync-webflow");
      if (!ok) return toast.error("Error during sync !", { icon: "❌" });
      toast.success("Job post synced !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
    setLoading(false);
  }
  return (
    <button
      disabled={loading}
      className="bg-blue-800 mb-1 disabled:opacity-50 disabled:cursor-not-allowed text-xs whitespace-nowrap font-medium text-white py-3 px-5 rounded-md"
      onClick={sync}>
      {loading ? "Syncing..." : "Sync webflow"}
    </button>
  );
};

const DashboardCard = ({ title, value, icon }) => (
  <div className="bg-white p-4 rounded-lg shadow">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-gray-500 text-sm">{title}</p>
        <p className="text-2xl font-semibold">{value}</p>
      </div>
      <span className="text-2xl">{icon}</span>
    </div>
  </div>
);

const JobCardSkeleton = () => (
  <div className="bg-white rounded-lg shadow animate-pulse">
    <div className="p-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <div className="h-6 w-48 bg-gray-200 rounded"></div>
          <div className="h-4 w-32 bg-gray-200 rounded mt-2"></div>
        </div>
        <div className="flex flex-col items-end">
          <div className="h-3 w-16 bg-gray-200 rounded"></div>
          <div className="h-4 w-20 bg-gray-200 rounded mt-1"></div>
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        <div className="relative">
          <div className="h-4 w-16 bg-gray-200 rounded mb-1"></div>
          <div className="h-6 w-12 bg-gray-200 rounded"></div>
        </div>
        <div className="relative">
          <div className="h-4 w-16 bg-gray-200 rounded mb-1"></div>
          <div className="h-6 w-12 bg-gray-200 rounded"></div>
        </div>
        <div className="relative">
          <div className="h-4 w-16 bg-gray-200 rounded mb-1"></div>
          <div className="h-6 w-12 bg-gray-200 rounded"></div>
        </div>
        <div className="relative">
          <div className="h-4 w-16 bg-gray-200 rounded mb-1"></div>
          <div className="h-6 w-12 bg-gray-200 rounded"></div>
        </div>
      </div>

      <div className="flex justify-between items-center pt-3 border-t">
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
          <div className="h-4 w-24 bg-gray-200 rounded"></div>
        </div>
        <div className="h-5 w-16 bg-gray-200 rounded-full"></div>
      </div>
    </div>
  </div>
);
