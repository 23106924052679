import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronUp, HiMagnifyingGlass, HiPlus } from "react-icons/hi2";
import { classNames } from "../utils";

export default ({ id, options, value, onChange, placeholder = "Select an option", onCreateNew }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const refInput = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isOpen) refInput.current.focus();
  }, [isOpen]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  function handleOptionClick(option) {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  }

  function handleToggleClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const idRegex = new RegExp(id);
      if (ref.current && !ref.current.contains(event.target) && !idRegex.test(event.target.id)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) setSearch("");
  }, [isOpen]);

  function handleCreateNew() {
    if (onCreateNew) {
      const newOption = { value: search.toLowerCase().replace(/\s+/g, "-"), label: search };
      onCreateNew(newOption);
      handleOptionClick(newOption);
      setSearch("");
    }
  }

  return (
    <div className="relative">
      <button
        id={id}
        type="button"
        onClick={handleToggleClick}
        className="min-w-[90px] w-full inline-flex justify-between items-center gap-4 px-2 pr-1 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 z-50">
        <span id={`${id}-text`} className="flex-1 text-left">
          {selectedOption?.label || placeholder}
        </span>
        {isOpen ? <HiChevronUp /> : <HiChevronDown />}
      </button>
      {isOpen && (
        <div ref={ref} className="absolute z-20 mt-1 max-h-96 w-fit bg-white shadow-lg rounded-md overflow-y-scroll">
          <ul className="border border-gray-200 divide-y divide-gray-200 list-none w-fit">
            <li className={classNames(`flex items-center gap-1 text-sm cursor-pointer w-56`)}>
              <div className="relative w-full py-2">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  ref={refInput}
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                  placeholder={onCreateNew ? `Search or create new` : `Search`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </li>
            {options
              .filter((o) => {
                if (!search) return true;
                return o.label.toLowerCase().includes(search.toLowerCase());
              })
              .map((option) => {
                const isSelected = selectedOption?.value === option.value;
                return (
                  <li
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    className={classNames(`flex items-center gap-1 px-2 py-1 cursor-pointer w-56`, isSelected ? "bg-blue-50 text-blue-700" : "hover:bg-gray-100")}>
                    <span className={classNames("flex-1", isSelected ? "font-semibold" : "font-normal")}>{option.label}</span>
                  </li>
                );
              })}
            {onCreateNew && search && !options.find((o) => o.label.toLowerCase() === search.toLowerCase()) && (
              <li onClick={handleCreateNew} className={classNames(`flex items-center gap-1 hover:bg-gray-100 text-sm px-2 py-1 cursor-pointer w-56`)}>
                <HiPlus className="text-gray-500" />
                <span className="flex-1 font-semibold">Create "{search}"</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
