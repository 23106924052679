import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronUp, HiMagnifyingGlass, HiPlus } from "react-icons/hi2";
import { classNames } from "../utils";
import api from "../services/api";

export default ({ id, value, projectId, budgetId, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const refInput = useRef(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isOpen) refInput.current.focus();
    if (!isOpen) setSearch("");
    if (projectId) fetch();
  }, [, projectId, isOpen, budgetId]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  async function fetch() {
    if (!isOpen) return;
    setLoading(true);
    const query = { limit: 20, status: "active" };
    if (projectId) query.project_id = projectId;
    if (budgetId) query.budget_id = budgetId;
    const res = await api.post("/epic/search", query);
    setOptions(res.data);
    setLoading(false);
  }

  function handleOptionClick(option) {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const idRegex = new RegExp(`epic-text`);
      if (ref.current && !ref.current.contains(event.target) && !idRegex.test(event.target.id)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="relative">
      <button
        id={id}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="min-w-[90px] w-full inline-flex justify-between items-center gap-4 px-2 pr-1 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 z-50">
        <span id={`epic-text`} className="flex-1 text-left">
          {value?.name || "Select an Epic"}
        </span>
        {isOpen ? <HiChevronUp /> : <HiChevronDown />}
      </button>
      {isOpen && (
        <div ref={ref} className="absolute z-20 mt-1 max-h-96 w-[400px] bg-white shadow-lg rounded-md overflow-y-scroll">
          <ul className="border border-gray-200 divide-y divide-gray-200 list-none w-full">
            <li className={classNames(`flex items-center gap-1 text-sm cursor-pointer w-full`)}>
              <div className="relative w-full py-2">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  ref={refInput}
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                  placeholder={`Search`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </li>
            {options
              .filter((o) => {
                if (!search) return true;
                return o.title.toLowerCase().includes(search.toLowerCase());
              })
              .map((option) => {
                const isSelected = selectedOption?._id === option._id;
                return (
                  <li
                    key={option._id}
                    onClick={() => handleOptionClick(option)}
                    className={classNames(
                      `flex items-center gap-2 px-3 py-2 cursor-pointer w-full`,
                      isSelected ? "bg-indigo-50 text-indigo-700" : "hover:bg-gray-50 text-gray-700"
                    )}>
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-gray-500">#{option.index}</span>
                        <span className={classNames("text-sm", isSelected ? "font-semibold" : "font-normal")}>
                          {option.title}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            {/* {onCreateNew && search && !options.find((o) => o.label.toLowerCase() === search.toLowerCase()) && (
              <li onClick={handleCreateNew} className={classNames(`flex items-center gap-1 hover:bg-gray-100 text-sm px-2 py-1 cursor-pointer w-56`)}>
                <HiPlus className="text-gray-500" />
                <span className="flex-1 font-semibold">Create "{search}"</span>
              </li>
            )} */}
          </ul>
        </div>
      )}
    </div>
  );
};

//add
// export default ({ id, options, value, onChange, placeholder = "Select an option", onCreateNew }) => {

// ({ value, onChange, projectId, status = "active" }) => {
//   const [options, setOptions] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(value);

//   useEffect(() => {
//     if (projectId) get();
//   }, [projectId]);

//   useEffect(() => {
//     setSelectedOption(value);
//   }, [value]);

//   async function get() {
//     const query = { limit: 20, status };
//     if (projectId) query.projectId = projectId;
//     const res = await api.post("/epic/search", query);
//     setOptions(res.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
//   }

//   async function onSelect(e) {
//     const selected = options.find((o) => o._id === e.target.value);
//     setSelectedOption(selected);
//     onChange(selected);
//   }

//   return <SingleSelect disabled={!projectId} value={selectedOption?._id || ""} onChange={onSelect} options={options} />;
// };

// const SingleSelect =
