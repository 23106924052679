import React, { useState } from "react";

export default function StatusTabs({setStatus}) {
  const [activeTab, setActiveTab] = useState("no_status");

  const onStatusChange = (status) => {
    setActiveTab(status)
    setStatus(status ==='results' ? ['lost','win'] : status)
  }

  const tabs = [
    { id: "no_status", label: "No Status", description: "All" },
    { id: "to_respond", label: "To Respond", description: "Waiting for the respond" },
    { id: "pending", label: "Pending", description: "Waiting for the answer" },
    { id: "results", label: "Results", description: "Lost or Win" },
    { id: "lead", label: "Lead", description: "Lead associate" },
  ];

  return (
    <div className="w-full mx-auto shadow-box rounded-large bg-light-white p-2 mb-1">
      <div className="flex space-x-4 w-full">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => onStatusChange(tab.id)}
            className={`flex-1 text-center py-2 rounded-md font-poppins text-normal ${
              activeTab === tab.id
                ? "bg-primary-100 text-primary-700"
                : "bg-lighter-grey text-dull-black"
            }`}
          >
            <div className="flex flex-col items-center">
             <label className="text-normal font-semibold">{tab.label}</label>
             <span className="text-min text-light-grey">{tab.description}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
