import React, { useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import debounce from "lodash/debounce";

import api from "../../../../services/api";

export default ({ company, setCompany }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      fetchCampaigns(searchTerm);
    }, 300),
    [],
  );

  useEffect(() => {
    if (showResults) {
      debouncedSearch(search);
    }
  }, [search, showResults]);

  async function fetchCampaigns(searchTerm) {
    try {
      setLoading(true);
      const res = await api.post("/s_campaign/search", {
        limit: 10,
        search: searchTerm,
      });
      if (!res.ok) throw res;
      setCampaigns(res.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Error fetching campaigns");
      setLoading(false);
    }
  }

  async function addToCampaign(campaign) {
    try {
      setActionLoading(true);
      const res = await api.put(`/s_company/${company._id}`, { campaigns: [...company.campaigns, { name: campaign.name, id: campaign._id }] });

      // update contacts too
      const { data: contacts } = await api.post(`/s_contact/search`, { company_id: company._id });
      for (const contact of contacts) {
        if (contact.campaigns.find((c) => c.id === campaign._id)) continue;
        await api.put(`/s_contact/${contact._id}`, { campaigns: [...contact.campaigns, { name: campaign.name, id: campaign._id }] });
      }

      if (!res.ok) throw res;
      setCompany(res.data);
      toast.success(`Added to campaign ${campaign.name}`);
      setSearch("");
      setShowResults(false);
    } catch (e) {
      console.error(e);
      toast.error("Error adding to campaign");
    } finally {
      setActionLoading(false);
    }
  }

  async function removeFromCampaign(campaignId) {
    try {
      setActionLoading(true);
      const res = await api.put(`/s_company/${company._id}`, { campaigns: company.campaigns.filter((c) => c.id !== campaignId) });
      if (!res.ok) throw res;
      // update contacts too
      const { data: contacts } = await api.post(`/s_contact/search`, { company_id: company._id });
      for (const contact of contacts) {
        if (!contact.campaigns.find((c) => c.id === campaignId)) continue;
        await api.put(`/s_contact/${contact._id}`, { campaigns: contact.campaigns.filter((c) => c.id !== campaignId) });
      }
      setCompany(res.data);
      toast.success("Removed from campaign");
    } catch (e) {
      console.error(e);
      toast.error("Error removing from campaign");
    } finally {
      setActionLoading(false);
    }
  }

  const filteredCampaigns = campaigns.filter((c) => !company.campaigns?.find((cc) => cc.id === c._id));

  return (
    <div className="">
       <h3 className="text-sm mt-4">Campaigns</h3>
      <div className="space-y-2 mb-4">
        {company.campaigns?.map((campaign) => (
          <div key={campaign.id} className="flex items-center justify-between bg-gray-50 p-2 rounded">
            <span>{campaign.name}</span>
            <button onClick={() => removeFromCampaign(campaign.id)} className="text-red-500 hover:text-red-700" disabled={actionLoading}>
              {actionLoading ? "Loading..." : "Remove"}
            </button>
          </div>
        ))}
      </div>

      <div className="relative">
        <input
          type="text"
          className="w-full p-2 border rounded mb-2"
          placeholder="Search campaigns..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => {
            setShowResults(true);
            fetchCampaigns("");
          }}
          disabled={actionLoading}
        />

        {showResults && (
          <div className="absolute z-10 w-full bg-white border rounded shadow-lg">
            {loading ? (
              <div className="p-2">Loading campaigns...</div>
            ) : filteredCampaigns.length > 0 ? (
              filteredCampaigns.map((campaign) => (
                <div
                  key={campaign._id}
                  className={`flex items-center justify-between p-2 hover:bg-gray-50 ${actionLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                  onClick={() => !actionLoading && addToCampaign(campaign)}>
                  <span>{campaign.name}</span>
                  <span className="text-blue-500 text-sm">{actionLoading ? "Loading..." : "Add"}</span>
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">No matching campaigns found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
