import { classNames } from "../utils";

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:text-gray-700",
      active ? "text-primary border-b-2 border-primary" : "",
    )}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default TabItem;
