import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ project }) => {
  const [epics, setEpics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const user = useSelector((state) => state.Auth.user);
  const [resourceAllocations, setResourceAllocations] = useState({});

  useEffect(() => {
    fetchEpics();
  }, []);

  const fetchEpics = async () => {
    setLoading(true);
    try {
      const { data, ok } = await api.post("/epic/search", { project_id: project._id });
      if (!ok) return toast.error("Failed to fetch roadmaps");
      setEpics(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch roadmaps");
    } finally {
      setLoading(false);
    }
  };

  const aggregateByPriority = () => {
    const resourceWorkload = {};

    epics.forEach((epic) => {
      const priority = epic.priority || "UNSET";
      const assigned = epic.assigned || "Unassigned";
      const allocation = resourceAllocations[assigned] || 100;
      const daysRequired = (epic.days_required || 0) * (100 / allocation) * 1.5;

      if (!resourceWorkload[priority]) {
        resourceWorkload[priority] = {};
      }

      if (!resourceWorkload[priority][assigned]) {
        resourceWorkload[priority][assigned] = 0;
      }

      resourceWorkload[priority][assigned] += daysRequired;
    });

    return resourceWorkload;
  };

  const renderWorkloadTable = () => {
    const workload = aggregateByPriority();
    const priorities = ["NOW", "NEXT", "LATER", "UNSET"];
    const allResources = [...new Set(epics.map((e) => e.assigned || "Unassigned"))];
    const totalDays = Object.values(workload).reduce((acc, days) => acc + Object.values(days).reduce((acc, day) => acc + day, 0), 0);

    // Calculate maximum weeks for scaling (divided by 2 for doubled resources and including 20% buffer)
    const maxWeeks = Math.max(...allResources.map((resource) => priorities.reduce((acc, priority) => acc + (workload[priority]?.[resource] || 0), 0) / 5));

    return (
      <div className="mt-4 space-y-8">
        {/* Timeline Summary */}
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <div className="grid grid-cols-3 gap-4">
            <div className="text-center">
              <div className="text-sm text-gray-600">Start Date</div>
              <input
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                className="border rounded-md px-3 py-2 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="flex flex-col gap-4 text-center border-x border-gray-200">
              <div>
                <div className="text-sm text-gray-600">Duration</div>
                <div className="font-semibold text-blue-600">
                  {maxWeeks.toFixed(1)} weeks
                  {/* <div className="text-xs text-gray-500">({Math.ceil(maxWeeks * 5)} working days)</div> */}
                </div>
              </div>
              <div>
                <div className="text-center">
                  <div className="text-sm text-gray-600">Total Days/Person</div>
                  <div className="font-semibold text-blue-600">{totalDays} days</div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <div className="text-sm text-gray-600">Estimated Completion</div>
              <div className="font-semibold">
                {new Date(startDate.getTime() + maxWeeks * 7 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </div>
              <div className="mt-2 space-y-1">
                {["NOW", "NEXT", "LATER"].map((priority, index) => {
                  const previousPriorities = ["NOW", "NEXT", "LATER"].slice(0, index);
                  const accumulatedWeeks = previousPriorities.reduce((acc, prevPriority) => {
                    return acc + Math.max(...allResources.map((resource) => (workload[prevPriority]?.[resource] || 0) / 5));
                  }, 0);

                  const priorityWeeks = Math.max(...allResources.map((resource) => (workload[priority]?.[resource] || 0) / 5));
                  const completionDate = new Date(startDate.getTime() + (accumulatedWeeks + priorityWeeks) * 7 * 24 * 60 * 60 * 1000);

                  return (
                    <div key={priority} className="flex items-center gap-2 text-xs font-medium">
                      <div
                        className={`
                        px-2 py-0.5 rounded-full
                        ${priority === "NOW" ? "bg-red-100 text-red-700" : ""}
                        ${priority === "NEXT" ? "bg-yellow-100 text-yellow-700" : ""}
                        ${priority === "LATER" ? "bg-blue-100 text-blue-700" : ""}
                      `}>
                        {priority}
                      </div>
                      <span className="text-gray-400">→</span>
                      <div className="font-semibold">
                        {completionDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        })}
                      </div>
                      <span className="text-xs text-gray-500">({Math.ceil(priorityWeeks)} weeks)</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Bar Graph Section */}
        <div>
          <h3 className="text-lg font-bold mb-4">Provisional resource allocation</h3>
          <div className="space-y-4">
            {allResources.map((resource) => (
              <div key={resource} className="relative">
                <div className="flex items-center mb-2">
                  <div className="w-32 flex flex-col items-center gap-2">
                    <span className="font-medium truncate">{resource}</span>
                    <div className="flex items-center gap-1">
                      <input
                        type="range"
                        min="1"
                        max="100"
                        className="w-16"
                        value={resourceAllocations[resource] || 100}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          setResourceAllocations((prev) => ({
                            ...prev,
                            [resource]: value,
                          }));
                        }}
                      />
                      <span className="text-xs w-8">{resourceAllocations[resource] || 100}%</span>
                    </div>
                  </div>
                  <div className="flex-1 flex gap-1">
                    {priorities.map((priority) => {
                      const days = workload[priority]?.[resource] || 0;
                      const weeks = days / 5;
                      const widthPercentage = (weeks / maxWeeks) * 100;

                      return weeks > 0 ? (
                        <div
                          key={priority}
                          className={`h-6 rounded transition-all duration-300
                            ${priority === "NOW" ? "bg-red-200" : ""}
                            ${priority === "NEXT" ? "bg-yellow-200" : ""}
                            ${priority === "LATER" ? "bg-blue-200" : ""}
                            ${priority === "UNSET" ? "bg-gray-200" : ""}
                          `}
                          style={{ width: `${widthPercentage}%` }}>
                          <div className="px-2 text-xs font-medium truncate">
                            {weeks.toFixed(1)}w ({days.toFixed(1)}d)
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Legend */}
          <div className="flex gap-4 mt-4 justify-end">
            {priorities.map((priority) => (
              <div key={priority} className="flex items-center gap-2">
                <div
                  className={`w-3 h-3 rounded
                  ${priority === "NOW" ? "bg-red-200" : ""}
                  ${priority === "NEXT" ? "bg-yellow-200" : ""}
                  ${priority === "LATER" ? "bg-blue-200" : ""}
                  ${priority === "UNSET" ? "bg-gray-200" : ""}
                `}></div>
                <span className="text-sm text-gray-600">{priority}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Existing Table */}
        <div>
          <h3 className="text-lg font-bold mb-4">Detailed Workload</h3>
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-50">
                <th className="border p-3 text-left">Resource Profile</th>
                {priorities.map((priority) => (
                  <th key={priority} className="border p-3 text-center font-semibold">
                    <div
                      className={`
                      ${priority === "NOW" ? "bg-red-100 text-red-800" : ""}
                      ${priority === "NEXT" ? "bg-yellow-100 text-yellow-800" : ""}
                      ${priority === "LATER" ? "bg-blue-100 text-blue-800" : ""}
                      ${priority === "UNSET" ? "bg-gray-100 text-gray-800" : ""}
                      rounded-md px-2 py-1
                    `}>
                      {priority}
                    </div>
                  </th>
                ))}
                <th className="border p-3 bg-gray-100">Total</th>
              </tr>
            </thead>
            <tbody>
              {allResources.map((resource) => (
                <tr key={resource} className="hover:bg-gray-50 transition-colors">
                  <td className="border p-3 font-medium">{resource}</td>
                  {priorities.map((priority) => {
                    const days = workload[priority]?.[resource] || 0;
                    const weeks = (days / 5).toFixed(1);
                    return (
                      <td
                        key={`${resource}-${priority}`}
                        className={`
                          border p-3 text-center
                          ${Number(weeks) > 0 ? "font-medium" : "text-gray-400"}
                        `}>
                        {weeks > 0 ? (
                          <div className="flex flex-col items-center justify-center">
                            <div className="flex items-center gap-1">
                              <span>{weeks}</span>
                              <span className="text-xs text-gray-500">wks</span>
                            </div>
                            <div className="text-xs text-gray-500">({days.toFixed(1)} days)</div>
                          </div>
                        ) : (
                          "—"
                        )}
                      </td>
                    );
                  })}
                  <td className="border p-3 text-center font-bold bg-gray-50">
                    {(
                      priorities.reduce((acc, priority) => {
                        return acc + (workload[priority]?.[resource] || 0);
                      }, 0) / 5
                    ).toFixed(1)}
                    <span className="text-xs text-gray-500 ml-1">wks</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (loading) return <div>Loading...</div>;

  return <div className="bg-white p-4 rounded-lg shadow">{renderWorkloadTable()}</div>;
};
