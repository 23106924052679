import React, { useState } from "react";
import { MdUpload } from "react-icons/md";
import { toast } from "react-hot-toast";
import api from "../../../services/api";
import Modal from "../../../components/modal";
import { readFileAsync } from "../../../utils";

export default function ModalCreationDoc({ onSubmit }) {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFileUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const rawBody = await readFileAsync(file);
    const { data } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder: "documents" });
    setValues({ ...values, url: data, fileName: file.name });
    setLoading(false);
  };

  const handleSave = () => {
    if (!values.name || !values.url) {
      toast.error("Please provide a name and upload a file.");
      return;
    }
    onSubmit(values);
    setValues({});
    setIsOpen(false);
  };

  return (
    <div>
      <button className="btn blue-btn mb-4" onClick={() => setIsOpen(true)}>
        Add new document
      </button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setValues({});
          setIsOpen(false);
        }}
        className="max-w-sm w-full">
        <div className="bg-white p-4 rounded-md">
          <h2 className="text-lg font-bold mb-2">Add New Document</h2>
          <input
            type="text"
            className="form-input w-full p-2 border rounded-md mb-2"
            placeholder="Document name"
            value={values.name || ""}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
          <label className="btn btn-primary p-2 h-auto cursor-pointer flex items-center mb-2" style={{ opacity: loading ? 0.5 : 1 }}>
            <input name="file-upload" type="file" disabled={loading} onChange={handleFileUpload} />
            <MdUpload className="text-lg mr-2" />
            Upload File
          </label>
          {values.fileName && <p className="text-sm text-gray-600 mb-2">Uploaded File: {values.fileName}</p>}
          <div className="flex justify-end">
            <button className="btn btn-primary" onClick={handleSave} disabled={loading}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
