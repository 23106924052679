import React,{useState} from 'react';
import api from "../../../services/api";
import { useSelector } from "react-redux";

const CommentForm = ({id, setRefreshComments}) => {
    const [comment, setComment] = useState('');
    const user = useSelector((state) => state.Auth.user);

    const onCommentSubmit = async (e) => {
    e.preventDefault();
    if(!comment) return;

   let newComment = {
    text: comment,
    user_name: user.name,
    user_id: user._id,
    user_avatar: user.avatar,
   }

    try {
      const {ok} = await api.post(`/mission_ao/comment/${id}`,newComment)

      if (ok) {
        console.log("Comment submitted successfully!");
        setComment("");
        setRefreshComments(true);
      } else {
        console.error("Failed to submit comment");
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

    return (
    <form
      id="commentForm"
      className="p-2 w-full flex flex-col justify-start items-start"
      onSubmit={onCommentSubmit}
    >
      <textarea
        className="p-2 w-[80%] rounded-md"
        placeholder="comment..."
        name="comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <input
        className="bg-blue-500 hover:bg-blue-300 cursor-pointer p-2 w-1/6 m-2 text-white rounded text-xm font-bold"
        type="submit"
        title="Add comment"
      />
    </form>
    )
}


export default CommentForm;