import React from "react";
import Available from "./Available";
import Birthday from "./Birthday";
import DaysOff from "./DaysOff";
import Contracts from "./Contracts";

const People = () => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex flex-col gap-4 col-span-2 md:col-span-1">
        <Available />
        <Contracts />
        <Birthday />
      </div>
      <div className="col-span-2 md:col-span-1">
        <DaysOff />
      </div>
    </div>
  );
};

export default People;
