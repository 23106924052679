import React, { useEffect, useState } from "react";

import api from "../../../services/api";
import Loader from "../../../components/loader";
import Tooltip from "react-tooltip";

const sourceToOrganisationMap = {
  BNP: "645131208989391af9bc9e33",
  BUNQ: "62961be126ff569dbb11d3e6",
};
const DATES = [];
for (let i = -10; i < 1; i++) {
  DATES.push(new Date(new Date().getFullYear(), new Date().getMonth() + i, 1, 0, 0, 0));
}

export default ({}) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState();

  useEffect(() => {
    get();
  }, [source]);

  async function get() {
    setLoading(true);

    // const query = { status: "active", per_page: 1000 }
    // const a = await api.post(`/project/search`, query);
    // // const arr = a.data.projects.filter((e) => e.estimation_monthly_budget > 0);
    // let temp = a.data.projects;
    // temp = temp.sort((a, b) => (a.estimation_monthly_budget || 0) - (b.estimation_monthly_budget || 0)).reverse()
    // setProjects(temp)

    // //remove 1 days
    const dateFrom = new Date(DATES[0]);
    dateFrom.setDate(dateFrom.getDate() - 1);

    const query2 = { 
      type: "invoice", 
      sent: "yes",
      ...source && { 
        organisation_id: sourceToOrganisationMap[source],
       },
    };
    const res1 = await api.post(`/invoice/search`, query2);
    const invoices = res1.data;

    const query5 = { startDate: dateFrom, per_page: 20000, sub_category: "INVOICE", source };
    const res5 = await api.post(`/bank/search`, query5);
    const incoming = res5.data.banks;

    const query3 = { startDate: dateFrom, per_page: 20000, source };
    const res2 = await api.post(`/bank/search`, query3);
    const banks = res2.data.banks;

    const res3 = await api.post(`/bank/cash`, { source });
    // console.log("res3", res3.data)

    const obj = {};
    for (let i = 0; i < DATES.length; i++) {
      const date = DATES[i];

      const filteredIncoming = incoming.filter((f) => areSameMonth(f.date, date));
      const invoicesIn = parseInt(filteredIncoming.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const VATIn = parseInt(filteredIncoming.reduce((acc, f) => acc + (parseInt(f.tax) || 0), 0));

      const invoicesInHT = invoicesIn - VATIn;

      const filteredBank = banks.filter((f) => areSameMonth(f.date, date));
      const cost = parseInt(filteredBank.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0));

      const filteredBankSalary = filteredBank.filter((f) => f.category === "VARIABLE_COST");
      const costSalaries = filteredBankSalary.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      const filteredBankFixedCosts = filteredBank.filter((f) => f.category == "GENERAL_COST");
      const fixedCosts = filteredBankFixedCosts.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      const filteredBankVATOut = filteredBank.filter((f) => f.sub_category === "VAT_DISBURSEMENT");
      const VATOut = filteredBankVATOut.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      const totalOutOperating = costSalaries + fixedCosts + VATOut;

      const filteredBankImpots = filteredBank.filter((f) => f.sub_category === "CORPORATION_TAX");
      const impots = filteredBankImpots.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      //const filteredBankTaxes = (filteredBank.filter((f => category != "SALARY" || category != "TAX")))
      //const fixedCosts = parseInt(filteredBankFixedCosts.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0))

      const invests = banks
        .filter((e) => e.sub_category === "INVEST")
        .filter((f) => areSameMonth(f.month, date))
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
      const transfers = banks
        .filter((e) => e.sub_category === "TRANSFER")
        .filter((f) => areSameMonth(f.month, date))
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      const balance = invoicesIn + totalOutOperating + impots + invests + transfers;

      const uncategorisedCosts = cost - costSalaries - VATOut - invests - transfers - fixedCosts - incoming;

      const r = res3.data.find((f) => areSameMonth(f.month, date));
      const inBank = r?.total;

      const invest = banks
        .filter((e) => e.sub_category === "INVEST")
        .filter((f) => areSameMonth(f.month, date))
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);
      const dividendes = banks
        .filter((e) => e.sub_category === "DIVIDEND")
        .filter((f) => areSameMonth(f.month, date))
        .reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0);

      const invoicesSentAndNotReceived = invoices.filter((f) => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1); // 1er du mois suivant
        if (
          f.sent === "yes" &&
          new Date(f.sentAt).getTime() < nextMonth.getTime() &&
          (f.received === "no" || (f.received === "yes" && new Date(f.receivedAt).getTime() >= nextMonth.getTime()))
        )
          return true;

        return false;
      });

      let banksReceivedAfter = []

      const banks3 = invoicesSentAndNotReceived.filter((f) => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1); // 1er du mois suivant
        for (let i = 0; i < f.banks.length; i++) {
          const bank = f.banks[i];
          if (new Date(bank.date).getTime() >= nextMonth.getTime() && !banksReceivedAfter.some((e) => e.id === bank.id)) {
            bank.projectName = f.projectName;
            banksReceivedAfter.push(bank)
          }
        }
      });

      const cashOut = invoicesSentAndNotReceived.reduce((acc, f) => acc + (parseInt(f.totalRemaining) || 0), 0) + banksReceivedAfter.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0)

      const invoicesSentAndNotReceivedLate = invoices.filter((f) => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1); // 1er du mois suivant
        if (
          f.sent === "yes" &&
          new Date(f.sentAt).getTime() < nextMonth.getTime() &&
          ((f.received === "no" || 
          (f.received === "yes" && new Date(f.receivedAt).getTime() >= nextMonth.getTime()))
          && new Date(f.dueAt).getTime() < nextMonth.getTime())
        )
          return true;

        return false;
      });

      const invoicesSentAndNotReceivedLatePerProject = Object.values(
        invoicesSentAndNotReceivedLate.reduce((acc, curr) => {
          const { projectName, totalRemaining } = curr;
          if (!acc[projectName]) {
            acc[projectName] = { projectName, totalRemaining: 0 };
          }
          acc[projectName].totalRemaining += totalRemaining;
          return acc;
        }, {})).sort((a, b) => b.totalRemaining - a.totalRemaining);

      const banksReceivedAfterPerProject = Object.values(
          banksReceivedAfter.reduce((acc, curr) => {
            const { projectName, amount } = curr;
            if (!acc[projectName]) {
              acc[projectName] = { projectName, amount: 0 };
            }
            acc[projectName].amount += amount;
            return acc;
          }, {})).sort((a, b) => b.amount - a.amount);
        
          const invoicesPerProjectMap = invoicesSentAndNotReceivedLatePerProject.reduce((acc, curr) => {
            acc[curr.projectName] = curr;
            return acc;
          }, {});
          
          const banksPerProjectMap = banksReceivedAfterPerProject.reduce((acc, curr) => {
            acc[curr.projectName] = curr;
            return acc;
          }, {});
          
          const combinedLateCashOutPerProject = Object.values(
            [...new Set([...Object.keys(invoicesPerProjectMap), ...Object.keys(banksPerProjectMap)])].reduce((acc, projectName) => {
              acc[projectName] = {
                projectName,
                totalRemaining: invoicesPerProjectMap[projectName]?.totalRemaining || 0,
                amount: banksPerProjectMap[projectName]?.amount || 0,
                sum: (invoicesPerProjectMap[projectName]?.totalRemaining || 0) + (banksPerProjectMap[projectName]?.amount || 0),
              };
              return acc;
            }, {})
          ).sort((a, b) => b.sum - a.sum);
          
      const lateCashOut = invoicesSentAndNotReceivedLate.reduce((acc, f) => acc + (parseInt(f.totalRemaining) || 0), 0) + banksReceivedAfter.reduce((acc, f) => acc + (parseInt(f.amount) || 0), 0)

      const virtualMoney = inBank + cashOut;

      obj[date] = {
        invoicesInHT,
        VATIn,
        invoicesIn,
        costSalaries,
        fixedCosts,
        VATOut,
        totalOutOperating,
        impots,
        invests,
        transfers,
        uncategorisedCosts,
        balance,
        inBank,
        cashOut,
        lateCashOut,
        combinedLateCashOutPerProject,
        invest,
        dividendes,
        virtualMoney,
      };
    }

    setStats(obj);
    setLoading(false);
  }

  if (loading) return <Loader />;

  return (
    <div className="p-4">
      <div className="flex items-center justify-end mb-2">
        <div className="max-w-xs w-full flex items-center space-x-2">
          <label class="whitespace-nowrap text-sm font-medium text-gray-700">Organisation: </label>
          <select class="input" value={source} onChange={(evt) => setSource(evt.target.value)}>
            <option value="">ALL</option>
            <option value="BNP">LE COLLECTIF</option>
            <option value="BUNQ">SELEGO</option>
          </select>
        </div>
      </div>
      <div className="flex justify-between h-12">
        <table>
          <thead className="bg-gray-200">
            <tr>
              <th></th>
              {DATES.map((e) => {
                const options = { month: "short", year: "numeric" };
                const str = e.toLocaleDateString("en-US", options) || "";
                return (
                  <th key={e} className="w-[100px]">
                    {str}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-sm text-center font-bold uppercase p-2">
                <div>Invoices in</div>
                <div className="text-xs text-gray-500 text-center">Sum of received invoices (HT)</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.invoicesInHT || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>VAT in</div>
                <div className="text-xs text-gray-500">tax from received invoices this month</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.VATIn || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Operating total in</div>
                <div className="text-xs text-gray-500">Invoices In + VAT In</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.invoicesIn || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Bank loans</div>
                <div className="text-xs text-gray-500">Bank loans</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.loan || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Subventions</div>
                <div className="text-xs text-gray-500">Subventions</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.loan || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Non-operating total in</div>
                <div className="text-xs text-gray-500">Subventions + loans</div>
              </td>
              {DATES.map((e, i) => {
                const value = (stats[e]?.loan || 0) + (stats[e]?.subventions || 0);
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="bg-sky-200 border border-gray-200 text-center font-bold bg-sky-200">
                <div>TOTAL IN</div>
                <div className="text-xs text-gray-500"></div>
              </td>
              {DATES.map((e, i) => {
                const value = (stats[e]?.loan || 0) + (stats[e]?.subventions || 0) + (stats[e]?.invoicesIn || 0);
                return (
                  <td className="bg-sky-200 border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Variable costs (salaries)</div>
                <div className="text-xs text-gray-500">Sum of all banks with category VARIABLE_COST</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.costSalaries || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Fixed costs</div>
                <div className="text-xs text-gray-500">Sum of all banks with category GENERAL_COST</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.fixedCosts || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>VAT out</div>
                <div className="text-xs text-gray-500">Banks with sub category VAT_DISBURSEMENT</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.VATOut || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Operating total out</div>
                <div className="text-xs text-gray-500">General costs + fixed costs + VAT</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.totalOutOperating || 0;
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Invests</div>
                <div className="text-xs text-gray-500">Sum of all banks INVEST</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.invests || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Transferts</div>
                <div className="text-xs text-gray-500">A catégoriser / ligne qui va disparaître</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.transfers || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Impôts</div>
                <div className="text-xs text-gray-500">Impôts</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.impots || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>Non-operating total out</div>
                <div className="text-xs text-gray-500">Subventions + loans</div>
              </td>
              {DATES.map((e, i) => {
                const value = (stats[e]?.impots || 0) + (stats[e]?.invests || 0);
                return (
                  <td className="border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Uncategorised costs</div>
                <div className="text-xs text-gray-500">(To control - should be 0)</div>
              </td>
              {DATES.map((e, i) => {
                const value = stats[e]?.uncategorisedCosts || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="bg-sky-200 border border-gray-200 text-center font-bold">
                <div>TOTAL OUT</div>
                <div className="text-xs text-gray-500"></div>
              </td>
              {DATES.map((e, i) => {
                const value = (stats[e]?.totalOutOperating || 0) + (stats[e]?.impots || 0) + (stats[e]?.invests || 0);
                return (
                  <td className="bg-sky-200 border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="bg-sky-200 border border-gray-200 text-center font-bold">
                <div>Balance</div>
                <div className="text-xs text-gray-500">Cash in - cash out</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.balance || 0;
                return (
                  <td className="bg-sky-200 border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center font-bold">
                <div>-- CASH ACTIVITY --</div>
                <div className="text-xs text-gray-500"></div>
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>In bank</div>
                <div className="text-xs text-gray-500">On our bank account</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.inBank || 0;
                return (
                  <td className=" border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Cash out</div>
                <div className="text-xs text-gray-500">Cash on client side</div>
              </td>

              {DATES.map((e) => {
                const value = stats[e]?.cashOut || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Late Cash out</div>
                <div className="text-xs text-gray-500">Late Cash (not paid yet due date passed)</div>
              </td>
              {
                DATES.map((e) => {
                  const value = stats[e]?.lateCashOut || 0;
                  const lateCashOut = stats[e]?.combinedLateCashOutPerProject || [];

                  return (
                    <td
                      className="border border-gray-200 text-center"
                      data-tip
                      data-for={`lateCashOut-${e.getTime()}`}
                      key={e.getTime()}
                    >
                      {addSpaceBetweenNumbers(value)}€
                      
                      {lateCashOut.length > 0 && (
                        <Tooltip
                          id={`lateCashOut-${e.getTime()}`}
                          border
                          borderColor="#e6e6e6"
                          effect="solid"
                          className="max-w-xs text-sm keepOpacity"
                          backgroundColor="#fff"
                          textColor="#000"
                        >
                          {lateCashOut.map((b, index) => (
                            <div key={index}>
                              {b.projectName} - {(b.sum || 0).toLocaleString("fr", {
                                style: "currency",
                                currency: "EUR"
                              })}
                            </div>
                          ))}
                        </Tooltip>
                      )}
                    </td>
                  );
                })
              }
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Paid Taxes</div>
                <div className="text-xs text-gray-500">Corporation taxes (banks with subcategory CORPORTATION_TAX)</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.impots || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Invests</div>
                <div className="text-xs text-gray-500">Sum of banks with sub category INVEST</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.invest || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="border border-gray-200 text-center">
                <div>Dividendes</div>
                <div className="text-xs text-gray-500 w-[200px]">dividendes (sub category = DIVIDEND)</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.dividendes || 0;
                return (
                  <td className="border border-gray-200 text-center" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td className="bg-sky-200 border border-gray-200 text-center font-bold">
                <div>Purchasing Power</div>
                <div className="text-xs text-gray-500">Cashout + bank - Amount the company has (we should also remove the creditors)</div>
              </td>
              {DATES.map((e) => {
                const value = stats[e]?.virtualMoney || 0;
                return (
                  <td className="bg-sky-200 border border-gray-200 text-center font-bold" key={e.getTime()}>
                    {addSpaceBetweenNumbers(value)}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

function addSpaceBetweenNumbers(str) {
  return str
    .toFixed()
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function areSameMonth(a, b) {
  let aDate = new Date(a);
  let bDate = new Date(b);
  return aDate.getMonth() === bDate.getMonth() && aDate.getFullYear() === bDate.getFullYear();
}
