import React, { useEffect, useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { ImSpinner2 } from "react-icons/im";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import DebounceInput from "react-debounce-input";
import { HiDotsHorizontal, HiOutlineShare } from "react-icons/hi";

import Modal from "../../../../../../components/modal";
import Loader from "../../../../../../components/loader";

import Info from "./info";
import Status from "./status";
import Raw from "./raw";
import Logs from "./logs";

import { copyToClipboard, classNames } from "../../../../../../utils";

import api from "../../../../../../services/api";

export default ({ onDelete, onUpdate, project }) => {
  const [tab, setTab] = useState("info");
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  useEffect(() => {
    const task_modal_id = query.get("task_modal_id");
    if (!values || values._id !== task_modal_id) fetch();
  }, [query]);

  const fetch = async () => {
    try {
      setValues(null);
      const task_modal_id = query.get("task_modal_id");
      if (!task_modal_id) return;
      setLoading(true);
      const res = await api.get(`/notion/${task_modal_id}`);
      if (!res.ok) return;
      setValues(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (!values) return <></>;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        query.delete("task_modal_id");
        navigate({ pathname: location.pathname, search: query.toString() });
        setValues(null);
      }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full h-[90vh] bg-gray-100 overflow-y-scroll">
          <div className="bg-white flex flex-col w-full overflow-hidden">
            <div className="flex justify-between items-center gap-2 p-4 mr-4">
              <div className="text-gray-600 text-lg">{`# ${values.index}`}</div>
              <input
                className="input text-base font-semibold flex-1"
                placeholder="Title..."
                type="text"
                id="title"
                value={values.name || ""}
                onChange={async (e) => {
                  setValues({ ...values, name: e.target.value });
                }}
                onBlur={async (e) => {
                  await api.put(`/notion/${values._id}`, values);
                  toast.success("Title updated!");
                  onUpdate(values);
                }}
              />
              <button
                className="transparent-btn"
                onClick={() => {
                  const url = new URL(window.location.href);
                  url.searchParams.set("t", values.name);
                  copyToClipboard(url.href);
                  toast.success("Copied to clipboard!");
                }}>
                <HiOutlineShare className="mr-1" />
                <span className="text-xs">Share</span>
              </button>
              <Menu
                notion={values}
                setNotion={(v) => {
                  setValues(v);
                  onDelete(values);
                }}
              />
            </div>
            <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
              <TabItem title="Info" tab="info" setTab={setTab} active={tab === "info"} Icon={IoInformationCircleOutline} />
              <TabItem title="Logs" tab="logs" setTab={setTab} active={tab === "logs"} />
              <TabItem title="Status" tab="status" setTab={setTab} active={tab === "status"} />
              <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
            </nav>
          </div>
          <div className="my-2" />
          {tab === "info" && <Info notion={values} setNotion={(e) => setValues(e)} project={project} onUpdate={onUpdate} />}
          {tab === "raw" && <Raw notion={values} />}
          {tab === "status" && <Status notion={values} setNotion={setValues} />}
          {tab === "logs" && <Logs notion={values} />}
        </div>
      )}
    </Modal>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

const Menu = ({ notion, setNotion }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    if (!confirm("Are you sure you want to delete this ticket?")) return;
    try {
      const { ok } = await api.remove(`/notion/${notion._id}`);
      if (!ok) return toast.error("Something went wrong!");
      toast.success("Notion deleted!");

      query.delete("task_modal_id");
      navigate({ pathname: location.pathname, search: query.toString() });
      setNotion(null);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  };

  const handleConvertToEpic = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(`/epic`, { title: notion.name, project_id: notion.project_id, project_name: notion.project_name });
      if (!data) return toast.error("Something went wrong!");
      toast.success("Ticket converted as a new epic!");
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <div className="relative">
      <button className="transparent-btn" onClick={() => setOpen((p) => !p)}>
        <HiDotsHorizontal />
      </button>
      {open && (
        <div className="text-sm fixed right-2 top-[3rem] bg-white border border-gray-200 rounded-md shadow-md min-w-40 z-10">
          <button className="flex items-center gap-2 px-4 py-2 w-full hover:bg-gray-100" onClick={handleConvertToEpic} disabled={loading}>
            <span>Move to roadmap</span>
            {loading && <ImSpinner2 className="animate-spin" />}
          </button>
          <button className="flex items-center gap-2 px-4 py-2 w-full hover:bg-gray-100" onClick={handleDelete}>
            <span>Delete</span>
          </button>
        </div>
      )}
    </div>
  );
};
