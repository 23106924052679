import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "../../services/api";
import { toast } from "react-hot-toast";
import Combobox from "../../components/Combobox";
import Loader from "../../components/loader";
import { HiPencil } from "react-icons/hi";

import StudFlixPng from "../../assets/studflix.png";

export default function Meetings() {
  const [meetings, setMeetings] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState({
    project: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMeetings();
  }, []);

  async function fetchMeetings(e) {
    try {
      e?.preventDefault();
      setLoading(true);
      const response = await api.post("/meeting/search", filters);
      setLoading(false);
      if (!response.ok) return toast.error("Error fetching meetings");
      setMeetings(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching meetings:", error);
    }
  }

  useEffect(() => {
    (async () => {
      const res = await api.post("/project/search", { status: "active", per_page: 1000 });
      if (res.ok) setProjects(res.data.projects);
    })();
  }, []);

  return (
    <>
      <form className="w-full grid grid-cols-4 items-center gap-3 p-2" onSubmit={fetchMeetings}>
        <div className="w-full">
          <label className="text-xs text-gray-500">Recherche</label>
          <input
            className="w-full flex items-center justify-between border border-gray-300 rounded hover:border-sky-700 overflow-hidden"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            placeholder="Search title, tags, ..."
            disabled={loading}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Project</label>
          <Combobox
            options={projects}
            value={projects.find((p) => p._id === filters.project_id)}
            onChange={(e) => setFilters({ ...filters, project_id: e?._id })}
            placeholder="Project"
            getValue={(o) => o._id}
            getLabel={(o) => o.name}
            match={(o, s) => o.name.toLowerCase().includes(s.toLowerCase())}
            width="w-full md:w-80"
            disabled={loading}
          />
        </div>
        <button type="submit" className="mt-5 h-9 w-full flex items-center justify-center bg-sky-600 text-white rounded hover:bg-sky-500">
          Search
        </button>
      </form>
      <div className="relative p-6 bg-[#272727] text-white min-h-screen">
        <div className="my-8 w-full flex justify-center">
          <img src={StudFlixPng} />
        </div>
        {loading ? <Loader /> : null}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {meetings.map((e) => (
            <Tile meeting={e} callback={fetchMeetings} />
          ))}
        </div>
      </div>
    </>
  );
}

const Tile = ({ meeting, callback }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(meeting.name);

  const handleSave = async () => {
    try {
      const response = await api.put(`/meeting/${meeting._id}`, {
        name: editedName,
      });
      if (response.ok) {
        toast.success("Meeting updated successfully");
        setIsEditing(false);
        callback();
      } else {
        toast.error("Error updating meeting");
      }
    } catch (error) {
      console.error("Error updating meeting:", error);
      toast.error("Error updating meeting");
    }
  };

  const getProjectBadge = () => {
    if (isEditing) return null;

    if (meeting.project_name) {
      return <span className="absolute top-1 right-1 bg-[#DC1B27] rounded-full px-4 text-white text-sm">{meeting.project_name}</span>;
    }
    return <span className="absolute top-1 right-1 bg-gray-600 rounded-full px-4 text-white text-sm">No Project</span>;
  };

  return (
    <div className="group relative p-4 shadow-lg rounded-lg mb-4 bg-[#272727] hover:scale-105 transition-transform duration-200">
      <Link to={`/meetings/${meeting._id}`} className="block relative">
        <div className="relative">
          <img
            src={`https://drive.google.com/thumbnail?id=${meeting.drive_id}` || StudFlixPng}
            className="rounded-lg w-full h-48 object-cover object-center brightness-90 group-hover:brightness-100 transition-all"
            alt="content"
          />
          {getProjectBadge()}
        </div>
      </Link>
      <div className="mt-4">
        <div className="flex items-center justify-between">
          {isEditing ? (
            <div className="w-full space-y-2">
              <input
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                className="w-full bg-[#404040] text-white rounded px-2 py-1"
                onClick={(e) => e.preventDefault()}
              />
              <div className="flex gap-2">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSave();
                  }}
                  className="bg-[#DC1B27] text-white px-3 py-1 rounded hover:bg-red-700">
                  Save
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditing(false);
                  }}
                  className="bg-gray-600 text-white px-3 py-1 rounded hover:bg-gray-700">
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <>
              <h3 className="text-lg font-semibold text-white group-hover:text-red-500">{meeting.name}</h3>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(true);
                }}
                className="opacity-0 group-hover:opacity-100 transition-opacity">
                <HiPencil className="text-white text-xl hover:text-red-500" />
              </button>
            </>
          )}
        </div>
        <p className="text-sm text-neutral-300">{meeting.description}</p>
      </div>
    </div>
  );
};
