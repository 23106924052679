import React, {useState, useEffect} from 'react';
import api from "../../../services/api";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";

const CommentList = ({ id, refreshComments, setRefreshComments }) => {
  const [comments, setComments] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editText, setEditText] = useState("");
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const fetchComments = async () => {
      const { ok, data } = await api.get(`/mission_ao/comments/${id}`);
      if (ok) {
        setComments(data);
        setRefreshComments(false)
      } else {
        console.log("Failed to get comments");
      }
    };
    fetchComments();
  }, [id, refreshComments]);

  const handleDelete = async (commentId) => {
    if (!window.confirm("Are you sure you want to delete this Comment ?")) return;
    try {
      const { ok } = await api.remove(`/mission_ao/comments/${id}/${commentId}`);
      if (ok) {
        setComments(comments.filter((comment) => comment._id !== commentId));
      } else {
        console.log("Failed to delete comment");
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleEdit = (comment) => {
    setEditingCommentId(comment._id);
    setEditText(comment.text);
  };

  const handleEditSubmit = async (e, commentId) => {
    e.preventDefault();
    try {
      const { ok } = await api.put(`/mission_ao/comments/${id}/${commentId}`, { text: editText });
      if (ok) {
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment._id === commentId ? { ...comment, text: editText } : comment
          )
        );
        setEditingCommentId(null);
        setEditText("");
      } else {
        console.log("Failed to update comment");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  return (
    <div className="w-full bg-lighter-grey p-4 rounded-md">
      <h2 className="text-primary-700 font-bold text-twenty mb-4">Comments</h2>
      {comments.length === 0 ? (
        <p className="text-light-grey">No comments available.</p>
      ) : (
        <div className="space-y-4">
          {comments.map((comment,index) => (
            <div
              key={index}
              className="flex items-start bg-light-white p-3 rounded-md shadow-sm"
            >
              <img
                src={comment.user_avatar}
                alt={`${comment.user_name} avatar`}
                className="w-12 h-12 rounded-full border-2 border-primary-500 mr-4"
              />
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  <h3 className="text-dull-black font-semibold text-med">
                    {comment.user_name}
                  </h3>
                  <span className="text-light-grey text-min">
                    {new Date(comment.created_at).toLocaleString()}
                  </span>
                </div>
                {editingCommentId === comment._id ? (
                  <form
                    onSubmit={(e) => handleEditSubmit(e, comment._id)}
                    className="mt-2"
                  >
                    <textarea
                      value={editText}
                      onChange={(e) => setEditText(e.target.value)}
                      className="w-full p-2 rounded border border-gray-bord"
                    />
                    <button
                      type="submit"
                      className="mt-2 bg-primary-500 text-white px-4 py-2 rounded hover:bg-primary-700"
                    >
                      Save
                    </button>
                  </form>
                ) : (
                  <p className="text-dull-grey mt-1">{comment.text}</p>
                )}
              </div>
              {(comment.user_id === user._id) && 
              (<div className="flex items-center space-x-2 ml-2">
                <button
                  className="text-primary-600 hover:text-primary-800"
                  onClick={() => handleEdit(comment)}
                >
                  <AiOutlineEdit className="cursor-pointer" />
                </button>
                <button
                  className="text-shade-red hover:text-red-700"
                  onClick={() => handleDelete(comment._id)}
                >
                  <AiOutlineDelete className="cursor-pointer"/>
                </button>
              </div>)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CommentList;
