import React, { useEffect, useState } from "react";

import "react-funnel-pipeline/dist/index.css";

import CompanyModal from "../component/companyModal";

import api from "../../../services/api";

const DEFAULT_MONTH_BACK = 3;

export default ({}) => {
  const [data, setData] = useState(null);
  const [month, setMonth] = useState(new Date(new Date().setMonth(new Date().getMonth() - DEFAULT_MONTH_BACK)));
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    fetch();
  }, [month]);

  async function fetch() {
    try {
      setData(null);
      const filter = {};
      filter.limit = 10000;
      filter.month = month;
      filter.pipeline = true;
      const { data, ok } = await api.post("/s_company/search", filter);
      const arr = [];

      console.log("data", data);

      const prospections = data.filter((e) => e.status_prospection_date);
      arr.push({
        name: "PROSPECTION",
        companies: prospections,
        value: prospections.length,
        median: median(prospections.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: prospections.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      const qualified = data.filter((e) => e.status_qualified_date);
      arr.push({
        name: "QUALIFIED",
        companies: qualified,
        value: qualified.length,
        median: median(qualified.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: qualified.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      const discovery = data.filter((e) => e.status_discovery_date);
      arr.push({
        name: "DISCOVERY",
        companies: discovery,
        value: discovery.length,
        median: median(discovery.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: discovery.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      const proposal = data.filter((e) => e.status_proposal_date);
      arr.push({
        name: "PROPOSAL",
        companies: proposal,
        value: proposal.length,
        median: median(proposal.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: proposal.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      const negotiation = data.filter((e) => e.status_negotiation_date);
      arr.push({
        name: "NEGOTIATION",
        companies: negotiation,
        value: negotiation.length,
        median: median(negotiation.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: negotiation.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      const closing = data.filter((e) => e.status_closing_date);
      arr.push({
        name: "CLOSING",
        companies: closing,
        value: closing.length,
        median: median(closing.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: closing.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      const won = data.filter((e) => e.status_won_date);
      arr.push({
        name: "WON",
        companies: won,
        value: won.length,
        median: median(won.filter((e) => e.deal_amount).map((e) => e.deal_amount)),
        total: won.reduce((a, b) => a + (b.deal_amount ? parseInt(b.deal_amount) : 0), 0),
      });

      setData(arr);
    } catch (e) {
      console.log("error", e);
    }
  }

  console.log("data", data);

  return (
    <div className="">
      {/* <FolkModal onClose={() => setSelectedCompany()} contact={selectedCompany} /> */}
      <div className="grid grid-cols-4 items-center space-x-4 mb-5">
        <MonthFilter value={month} onChange={setMonth} />
      </div>
      {data === null ? (
        <div>Loading...</div>
      ) : (
        <div className="overflow-x-auto">
          <div className="flex">
            <div className="w-1/2">
              <table>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Leads</th>
                    <th>Drop</th>
                    <th>Median</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((e, i) => {
                    let percent = 0;
                    if (i > 0) percent = Math.round((e.value / data[i - 1].value) * 100);
                    return (
                      <tr>
                        <td>
                          <details>
                            <summary className="cursor-pointer">{`${i + 1}.${e.name}`}</summary>
                            <div className="bg-gray-100 p-3 text-xs space-y-1 flex flex-col">
                              {e.companies &&
                                e.companies.map((c) => (
                                  <div className="cursor-pointer hover:underline font-medium" onClick={() => setSelectedCompany(c)}>
                                    {c.name} ({c.deal_amount || "-"}€)
                                  </div>
                                ))}
                            </div>
                          </details>
                        </td>
                        <td>{e.value}</td>
                        <td>{percent ? 100 - percent + "%" : ""}</td>
                        <td>{numberWithSpaces(e.median)}€</td>
                        <td>{numberWithSpaces(e.total)}€</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function median(numbers) {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);
  if (sorted.length % 2 === 0) return (sorted[middle - 1] + sorted[middle]) / 2;
  return sorted[middle];
}

const Data = ({ user, dateFrom }) => {
  const [data, setData] = useState(null);
  //dsadsa

  useEffect(() => {
    fetch();
  }, [user]);

  async function fetch() {
    const filter = {};
    if (user) filter.userId = user._id;
    if (dateFrom) filter.dateFrom = dateFrom;
    filter.projectId = "64cb6ea5298d8606b9dc8325";
    const { data } = await api.post(`/activity/search`, filter);
    console.log("xxxx", data);
    setData(data);
  }

  if (data === null) return <div>Loading...</div>;

  const days = (data.reduce((acc, a) => (a.total ? acc + a.total : 0), 0) / 8).toFixed(0);
  const costs = data.reduce((acc, a) => (a.cost ? acc + a.cost : 0), 0).toFixed(0);

  return (
    <div>
      <div>
        <label>Nombre de jours</label>
        <div>{days}</div>
      </div>
      <div>
        <label>Couts</label>
        <div>{numberWithSpaces(costs)}€</div>
      </div>
      <div>
        <label>Goal</label>
        <div>{numberWithSpaces(costs * 20)}€</div>
      </div>
    </div>
  );
};

function numberWithSpaces(x) {
  if (!x) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

// create a react select component which will be used to select a month from now

const MonthFilter = ({ onChange }) => {
  const [value, setValue] = useState(DEFAULT_MONTH_BACK);

  const handleChange = (e) => {
    const date = new Date();
    date.setMonth(date.getMonth() - e.target.value); // number of months back
    onChange(e.target.value ? date : "");
    setValue(e.target.value);
  };

  return (
    <select className="input" value={value} onChange={handleChange}>
      <option value="">All</option>
      <option value={1}>Last 1 month</option>
      <option value={3}>Last 3 months</option>
      <option value={6}>Last 6 months</option>
    </select>
  );
};
