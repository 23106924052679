import React, { useEffect, useState } from "react";
import { FaLinkedin, FaEnvelope, FaTimes, FaCheck } from "react-icons/fa";
import toast from "react-hot-toast";

import api from "../../../services/api";

import Table from "../../../components/Table";
import Paginator from "../../../components/Paginator";
import CompanyModal from "../component/companyModal";

export default () => {
  const [contacts, setContacts] = useState([]);
  const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = {};
    newFilters.skip = (filters.page - 1) * 100;
    newFilters.business_introducer = true;

    if (filters.search) newFilters.search = filters.search;

    const { data, total, ok } = await api.post("/s_contact/search", newFilters);
    console.log(data, total, ok);
    if (!ok) return toast.error("Error fetching contacts");
    setContacts(data);
    setTotal(total);
    setLoading(false);
  }

  console.log(selectedCompany);

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
      </div>
      <div>{total} items</div>
      <CompanyModal company={selectedCompany} setCompany={setSelectedCompany} />
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },
          { title: "Job title", key: "title" },
          { title: "Companies", key: "companies" },
          { title: "Status ", key: "status" },
          { title: "Updated at", key: "updated_at" },
          { title: "Created at ", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {contacts.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={async (e) => {
                try {
                  e.stopPropagation();
                  if (!item.companies.length) return toast.error("No company found");
                  console.log(item);
                  const res = await api.get(`/s_company/${item.companies[0]?.company_id}`);
                  if (!res.ok) return toast.error("Error fetching company");
                  setSelectedCompany(res.data);
                } catch (error) {
                  toast.error("Error fetching company");
                }
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex items-center gap-2">
                  <img
                    src={item.picture || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
                    className="h-8 w-8 rounded-full object-cover"
                    alt={`${item.first_name} ${item.last_name}`}
                  />
                  <span>
                    {item.first_name} {item.last_name}
                  </span>
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.job_title}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {item.companies.map((e) => {
                  return (
                    <span key={e._id} className="bg-gray-200 text-xs px-2 py-1 rounded-md mr-1">
                      {e.name}
                    </span>
                  );
                })}
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-1">
                    <span className="text-base flex gap-1">
                      {item.linkedin && <FaLinkedin className="text-gray-600 text-base" />}
                      {item.status_linkedin_visit_profile ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.status_linkedin_invite_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.status_linkedin_invite_accepted ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.status_linkedin_message_sent ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-500" />}
                      {item.email && <FaEnvelope className="text-gray-600 text-base" />}
                    </span>
                  </div>
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.updated_at}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.created_at}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");
  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);
  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};
