import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "../../services/api";
import { Link } from "react-router-dom";

const getDueDate = (ticket) => {
  // retrun <span green if due date is in the future, red if in the past, grey if today
  const today = new Date();
  const dayLeft = Math.ceil((new Date(ticket.end_at) - today) / (1000 * 60 * 60 * 24));
  if (dayLeft > 0) return <span className="text-xs text-green-500">{dayLeft} days left</span>;
  if (dayLeft < 0) return <span className="text-xs text-red-500">{dayLeft} days late</span>;
  return <span className="text-xs text-red-500">today</span>;
};

const Tickets = () => {
  const user = useSelector((state) => state.Auth.user);
  const [data, setData] = useState({
    inProgress: [],
    awaitingFeedback: [],
    todo: [],
  });
  const [total, setTotal] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = {
          leader_id: user._id,
          limit: 10000,
          status: ["TODO", "INPROGRESS", "AWAITING_FEEDBACK"],
          type: "ticket",
        };

        const { ok, data, total } = await api.post("/ticket/search", query);
        if (!ok) throw new Error("Error fetching tickets");
        setData({
          inProgress: data.filter((t) => t.status === "INPROGRESS"),
          awaitingFeedback: data.filter((t) => t.status === "AWAITING_FEEDBACK"),
          todo: data.filter((t) => t.status === "TODO"),
        });
        setTotal(total);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Link to="/message">
      <div className="border border-gray-200 rounded-md overflow-hidden h-full bg-white">
        <div className="w-full p-3 flex items-center justify-between">
          <h1 className="flex items-center text-base font-semibold text-gray-900 gap-2">{total} active tickets</h1>
        </div>
        <div className="w-full flex items-start gap-2 px-3 pb-3">
          <div className="space-y-2 w-1/3">
            <div className="text-base font-semibold">{data.inProgress.length} in progress</div>
            {data.inProgress
              .sort((a, b) => new Date(a.end_at) - new Date(b.end_at))
              .slice(0, 3)
              .map((t) => (
                <div className="w-full gap-1 border border-gray-200 rounded-md p-2">
                  <p className="text-sm text-gray-500 truncate">{t.title}</p>
                  {getDueDate(t)}
                </div>
              ))}
            {data.inProgress.length > 3 && <div className="text-sm text-gray-500">and {data.inProgress.length - 3} more...</div>}
          </div>

          <div className="space-y-2 w-1/3">
            <div className="text-base font-semibold">{data.todo.length} todo</div>
            {data.todo
              .sort((a, b) => new Date(a.end_at) - new Date(b.end_at))
              .slice(0, 3)
              .map((t) => (
                <div className="w-full gap-1 border border-gray-200 rounded-md p-2">
                  <p className="text-sm text-gray-500 truncate">{t.title}</p>
                  {getDueDate(t)}
                </div>
              ))}
            {data.todo.length > 3 && <div className="text-sm text-gray-500">and {data.todo.length - 3} more...</div>}
          </div>
          <div className="space-y-2 w-1/3">
            <div className="text-base font-semibold">{data.awaitingFeedback.length} awaiting feedback</div>
            {data.awaitingFeedback
              .sort((a, b) => new Date(a.end_at) - new Date(b.end_at))
              .slice(0, 3)
              .map((t) => (
                <div className="w-full gap-1 border border-gray-200 rounded-md p-2">
                  <p className="text-sm text-gray-500 truncate">{t.title}</p>
                  {getDueDate(t)}
                </div>
              ))}
            {data.awaitingFeedback.length > 3 && <div className="text-sm text-gray-500">and {data.awaitingFeedback.length - 3} more...</div>}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Tickets;
