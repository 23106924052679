import React, { useState } from "react";
import toast from "react-hot-toast";

import { TicketPeople } from "./people";
import SelectUsers from "../../../../components/selectUser";
import Modal from "../../../../components/modal";
import api from "../../../../services/api";

export const TicketInfo = ({ ticket, setTicket }) => {
  const agent = ticket.people.find((e) => e.agent);
  const comment = ticket.messages[0];

  async function onEditEndAt(value) {
    try {
      const { data } = await api.put(`/ticket/${ticket._id}`, { end_at: value });
      toast.success(`Deadline changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="w-[350px] p-2">
      <div className="mb-2">
        <h3>Lead</h3>
        <SelectLeader ticket={ticket} setTicket={setTicket} />
      </div>
      <div className={"mb-2"}>
        <TicketPeople
          ticket={ticket}
          onAdd={(e) => {
            toast.success(`todo`);
            toast.success(`Added ${e.user_name} to this conversation`);
          }}
        />
      </div>
      <div className={"mb-2"}>
        <h3>Files</h3>
        <div>
          {(ticket.messages || []).map((e, index) => (
            <div key={index} className="flex items-center space-x-4 mb-2">
              <div>
                {e.files.map((file, index) => (
                  <li key={index}>
                    <a href={file.url} target="_blank" rel="noopener noreferrer" className={"text-xs"}>
                      {file.name}
                    </a>
                  </li>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={"mb-2"}>
        <h3>Deadline</h3>
        <div>
          <div className="flex items-center space-x-4 ml-auto">
            <input
              type="date"
              defaultValue={ticket.end_at ? new Date(ticket.end_at).toISOString().split("T")[0] : null}
              onChange={(e) => {
                onEditEndAt(e.target.value);
              }}
              className="mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      {agent && (
        <div className="border-t mt-5 text-sm">
          <div className="my-5 border-blue-800 text-blue-700 bg-blue-50 p-2 rounded-md font-semibold flex items-center gap-4">
            <span>AI Agent - {agent?.user_name}</span>
            <FeedbackModal agent={agent} comment={comment} />
          </div>
        </div>
      )}
    </div>
  );
};

const SelectLeader = ({ ticket, setTicket }) => {
  async function handleChange(user) {
    try {
      const obj = {};
      obj.leader_id = user._id;
      obj.leader_name = user.name;
      obj.leader_email = user.email;
      obj.leader_avatar = user.avatar;
      const { data } = await api.put(`/ticket/${ticket._id}`, obj);
      toast.success(`Leader changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <SelectUsers value={{ _id: ticket.leader_id, name: ticket.leader_name, avatar: ticket.leader_avatar }} userName={ticket.leader_name} onChange={handleChange} />
    </div>
  );
};

const FeedbackModal = ({ agent, comment }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState();

  const handleSave = async () => {
    try {
      setLoading(true);
      const text = "INPUT :" + comment?.text + "\n\n" + "FEEDBACK :" + feedback?.description;

      const { data, ok, code } = await api.post(`/agent_feature`, {
        title: feedback?.title,
        description: text,
        tags: ["feedbacks"],
        agent_id: agent?.user_id,
        agent_name: agent?.user_name,
      });

      if (!ok) return toast.error(code);
      setFeedback({});
      toast.success("Feedback saved");
      setOpen((prev) => !prev);
    } catch (e) {
      console.error("Error saving feedback:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className=" bg-blue-50 rounded text-blue-800 w-fit underline hover:bg-blue-100 px-3 py-1" onClick={() => setOpen(!isOpen)}>
        Give Feedbackk
      </button>

      <Modal isOpen={isOpen} onClose={() => setOpen((prev) => !prev)} className="w-[90%] lg:w-[700px]">
        <div className="p-4 ">
          <h1 className="text-lg font-semibold">Feedback</h1>
          <input
            className="w-full p-3 border border-gray-200 rounded focus:!outline-0 focus:ring-0 mt-2 !text-sm"
            type="text"
            placeholder="Enter feedback title"
            onChange={(e) => setFeedback({ ...feedback, title: e.target.value })}
            value={feedback?.title}
          />
          <textarea
            placeholder="Enter feedback description"
            className="w-full h-32 p-3 border border-gray-200 rounded focus:!outline-0 focus:ring-0 mt-2 !text-sm"
            value={feedback?.description}
            onChange={(e) => setFeedback({ ...feedback, description: e.target.value })}
          />
          <button className="bg-blue-50 rounded text-blue-800 w-fit hover:bg-blue-100 px-3 py-2 text-sm mt-4 mb-6 float-end" disabled={!feedback?.description} onClick={handleSave}>
            {loading ? "Saving..." : "Save feedback"}
          </button>
        </div>
      </Modal>
    </>
  );
};
