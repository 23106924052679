import { useState, useEffect } from "react";

import api from "../../../services/api";
export default ({ value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  async function loadOptions() {
    if (options.length > 0) return;

    setLoading(true);
    try {
      const { data } = await api.post("/s_source/search");
      setOptions(data);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <div className="flex flex-col gap-2 mb-2 w-full">
      <select
        value={value?.name || ""}
        onChange={(e) => {
          const option = options.find((o) => o.name === e.target.value);
          onChange({ _id: option?._id, name: option?.name });
        }}
        className="input"
        disabled={loading}>
        <option value="">{loading ? "Loading..." : "Select a source"}</option>
        {options.map((option) => (
          <option key={option._id} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
