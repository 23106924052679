import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";

import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from "recharts";

export default function BudgetNormal({ project }) {
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [filters, setFilters] = useState({ status: "active" });
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = { projectId: project._id };
        if (filters.status) query.status = filters.status;
        const res = await api.post(`/budget/search`, query);
        if (!res.ok) throw new Error("Failed to fetch budgets");
        setBudgets(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [project, filters]);

  return (
    <div className="space-y-6">
      <div className="flex gap-3">
        <div className="w-1/3 rounded-md bg-white border flex flex-col gap-4 p-2">
          <div className="space-y-4">
            <div className="divide-y">
              {loading ? (
                <div className="text-center py-8 text-gray-500">Loading...</div>
              ) : budgets.length === 0 ? (
                <div className="text-center py-8 text-gray-500">No budgets found</div>
              ) : (
                budgets.map((item, index) => <BudgetRow key={item._id} item={item} isSelected={item._id === selectedBudget?._id} setSelectedBudget={setSelectedBudget} />)
              )}
            </div>
            <div className="flex items-center gap-2">
              <select value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })} className="input text-sm">
                <option value="active">Active only</option>
                <option value="inactive">Inactive only</option>
                <option value="">All</option>
              </select>
            </div>
          </div>
        </div>
        <div className="w-2/3 rounded-md bg-white border flex flex-col gap-4 p-2">
          <Budget selectedBudget={selectedBudget} budgets={budgets} />
        </div>
      </div>
    </div>
  );
}

const BudgetRow = ({ item, setSelectedBudget, isSelected }) => {
  const daysRemaining = Math.ceil((new Date(item.endAt) - new Date()) / (1000 * 60 * 60 * 24));
  const percentageUsed = (item.amountUsed / item.amount) * 100;

  return (
    <div
      className={`${
        isSelected ? "bg-blue-50 border-l-4 border-l-blue-500" : item.status === "inactive" ? "bg-gray-50" : "bg-white"
      } cursor-pointer hover:bg-blue-50/50 transition-all duration-200`}
      onClick={() => setSelectedBudget(isSelected ? null : item)}>
      <div className="p-4 space-y-3">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="font-medium text-gray-900">{item.name}</h4>
            {item.status === "inactive" && <span className="inline-block px-2 py-1 text-xs font-medium text-gray-500 bg-gray-100 rounded-full mt-1">Inactive</span>}
          </div>
          <div className={`text-sm font-medium ${daysRemaining < 30 ? "text-red-600" : "text-gray-600"}`}>{daysRemaining} days remaining</div>
        </div>

        <div className="space-y-2">
          <ProgressBar value={percentageUsed} />
          <div className="flex justify-between text-sm">
            <div className="space-x-4">
              <span className="font-medium">
                {(item.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })} /{" "}
                {(item.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </span>
            </div>
            <div className="text-gray-500 space-x-2">
              <span>{new Date(item.startAt).toLocaleDateString()}</span>
              <span>→</span>
              <span>{new Date(item.endAt).toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressBar = ({ value }) => {
  const getBarColor = (percentage) => {
    if (percentage >= 100) return "bg-red-600";
    if (percentage >= 80) return "bg-orange-500";
    if (percentage >= 60) return "bg-yellow-500";
    return "bg-green-500";
  };

  return (
    <div className="w-full flex items-center gap-2">
      <div className="w-full h-2.5 bg-gray-200 rounded-full overflow-hidden">
        <div className={`h-full rounded-full transition-all duration-300 ${getBarColor(value)}`} style={{ width: `${Math.min(value, 100)}%` }} />
      </div>
      <span className="text-sm font-medium min-w-[3rem]">{Math.round(value)}%</span>
    </div>
  );
};

const Budget = ({ selectedBudget, budgets }) => {
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedBudget) return;
      setLoading(true);
      try {
        const [activitiesRes, banksRes] = await Promise.all([
          api.post(`/activity/search`, { projectId: selectedBudget.projectId, budgetId: selectedBudget._id }),
          api.post(`/bank/search`, { projectId: selectedBudget.projectId, budgetId: selectedBudget._id }),
        ]);
        if (activitiesRes.ok) setActivities(activitiesRes.data);
        let banks2 = banksRes.data;
        banks2 = banks2.banks.filter((bank) => bank.category !== "INCOME");
        console.log(banks2);
        if (banksRes.ok) setBanks(banks2);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch budget details");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedBudget]);

  if (loading) return <div className="text-center py-8 text-gray-500">Loading...</div>;
  if (!selectedBudget) return <div />;

  const totalAmountActivities = activities?.map((activity) => Math.abs(activity.value)).reduce((acc, curr) => acc + curr, 0) || 0;
  const totalAmountBanks = banks?.banks?.reduce((acc, { amount }) => acc + Math.abs(amount), 0) || 0;
  const totalAmount = selectedBudget?.amount || 0;

  const finalRemainingAmount = Math.max(totalAmount - totalAmountActivities - totalAmountBanks, 0);

  const activitiesByMonth = activities.reduce((acc, activity) => {
    const month = new Date(activity.date).toLocaleString("default", { month: "long", year: "numeric" });
    if (!acc[month]) acc[month] = [];
    acc[month].push(activity);
    return acc;
  }, {});

  const banksByMonth =
    banks?.banks?.reduce((acc, bank) => {
      const month = new Date(bank.date).toLocaleString("default", { month: "long", year: "numeric" });
      if (!acc[month]) acc[month] = [];
      acc[month].push(bank);
      return acc;
    }, {}) || {};

  const allMonths = [...new Set([...Object.keys(activitiesByMonth), ...Object.keys(banksByMonth)])].sort((a, b) => new Date(a) - new Date(b));

  const monthlyData = allMonths.reduce((acc, month, index) => {
    const activitiesTotal = (activitiesByMonth[month] || []).reduce((sum, activity) => sum + Math.abs(activity.value), 0);
    const banksTotal = (banksByMonth[month] || []).reduce((sum, bank) => sum + Math.abs(bank.amount), 0);

    // Calculate expected spending (total budget divided evenly across months)
    const startDate = new Date(selectedBudget.startAt);
    const endDate = new Date(selectedBudget.endAt);
    const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth()) + 1;
    const expectedMonthlySpending = totalAmount / totalMonths;

    const previousCumulative = index > 0 ? acc[index - 1].cumulativeActual : 0;
    const previousExpectedCumulative = index > 0 ? acc[index - 1].cumulativeExpected : 0;

    return [
      ...acc,
      {
        month,
        actual: activitiesTotal + banksTotal,
        expected: expectedMonthlySpending,
        cumulativeActual: previousCumulative + activitiesTotal + banksTotal,
        cumulativeExpected: previousExpectedCumulative + expectedMonthlySpending,
      },
    ];
  }, []);

  const currentMonth = new Date().toLocaleString("default", { month: "long", year: "numeric" });

  let activitiesByUser = [];

  for (const activity of activities) {
    const userName = activity.userName || "Unknown User";
    const find = activitiesByUser.find((a) => a.userName === userName);
    if (!find) {
      activitiesByUser.push({ userName, userAvatar: activity.userAvatar, totalCost: Math.abs(activity.value), totalDays: activity.total / 8, userJobTitle: activity.userJobTitle });
      continue;
    }
    find.totalCost += Math.abs(activity.value);
    find.totalDays += activity.total / 8;
  }

  activitiesByUser = activitiesByUser.sort((a, b) => b.totalCost - a.totalCost);

  // Group banks by name and calculate total amount
  let banksByName = [];
  const groupedBanks = (banks || []).reduce((acc, bank) => {
    const name = bank.name || "Unknown";
    if (!acc[name]) {
      acc[name] = { name, totalAmount: 0, count: 0 };
    }
    acc[name].totalAmount += Math.abs(bank.amount);
    acc[name].count += 1;
    return acc;
  }, {});
  banksByName = Object.values(groupedBanks).sort((a, b) => b.totalAmount - a.totalAmount);

  console.log("banks", banks);
  console.log("banksByName", banksByName);

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-2 gap-8 w-full p-4">
        <div className="flex flex-col">
          <h2 className="text-2xl font-semibold mb-4">{selectedBudget?.name}</h2>
          <div className="text-3xl font-bold mb-6">{totalAmount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="text-black">
            <div className="font-medium mb-2">Timeline</div>
            <div className="font-light">Start Date: {new Date(selectedBudget.startAt).toLocaleDateString()}</div>
            <div className="font-light">End Date: {new Date(selectedBudget.endAt).toLocaleDateString()}</div>
          </div>
          <div className="text-black">
            <div className="font-medium mb-2">Status</div>
            <div className="font-light">Days Left: {Math.ceil((new Date(selectedBudget.endAt) - new Date()) / (1000 * 60 * 60 * 24))}</div>
            <div className="font-light">Remaining Amount: {finalRemainingAmount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          </div>
        </div>
      </div>

      <div className="">
        <h3 className="text-lg font-semibold mb-4">Cumulative Expenses Over Time</h3>
        <div className="h-64 w-full">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={(value) => new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", notation: "compact" }).format(value)} />
              <Tooltip
                formatter={(value, name) => {
                  const formatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });
                  return [formatter.format(value), name];
                }}
                labelFormatter={(label, payload) => {
                  const monthData = monthlyData.find((d) => d.month === label);
                  const actualValue = monthData?.actual || 0;
                  const formatter = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" });
                  return `${label} ${label === currentMonth ? "(Current Month)" : ""}\nMonthly Actual: ${formatter.format(actualValue)}`;
                }}
              />
              <Legend />
              <Bar dataKey="cumulativeActual" name="Cumulative Actual Expenses" fill="#3B82F6" />
              <Bar dataKey="cumulativeExpected" name="Cumulative Expected Expenses" fill="#9CA3AF" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Detailed Expenses</h3>

        <div className="grid grid-cols-2 gap-8">
          <div>
            <h4 className="text-md font-medium mb-2">Activities by User</h4>
            <div className="space-y-2">
              {activitiesByUser.map((user) => (
                <div key={user.userName} className="flex items-center justify-between p-2 bg-white rounded-md border">
                  <div className="flex items-center space-x-2">
                    <img src={user?.userAvatar || "/default-avatar.png"} alt={user.userName} className="w-8 h-8 rounded-full" />
                    <div className="text-sm">
                      <div>{user.userName}</div>
                      <div className="text-gray-400 text-xs">{user?.userJobTitle || "No position"}</div>
                    </div>
                  </div>
                  <div className="text-sm text-right">
                    <div>
                      {user.totalCost.toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      })}
                    </div>
                    <div className="text-gray-400 text-xs">{user.totalDays.toFixed(2)} days</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h4 className="text-md font-medium mb-2">Bank Expenses</h4>
            <div className="space-y-2">
              {banksByName.map((bank) => (
                <div key={bank.name} className="flex items-center justify-between p-2 bg-white rounded-md border">
                  <div className="flex items-center space-x-2">
                    <div className="text-sm">
                      <div>{bank.name}</div>
                      <div className="text-gray-400 text-xs">
                        {bank.count} transaction{bank.count > 1 ? "s" : ""}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm">
                    {bank.totalAmount.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
