import React from "react";
import MultiSelect from "../../../components/MultiSelect";

export const CATEGORIES = [
  { value: "GENERAL_COST", label: "General Cost" },
  { value: "VARIABLE_COST  ", label: "Variable Cost" },
  { value: "DISBURSEMENT", label: "Disbursement" },
  { value: "INCOME", label: "Income" },
  { value: "REFUND", label: "Refund" },
  { value: "", label: "" },
];

export default ({ values = [], onChange }) => {
  return <MultiSelect values={values} id="select-categories" options={CATEGORIES} onSelectedChange={onChange} placeholder="Select a category" />;
};
