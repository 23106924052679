import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { MdUpload } from "react-icons/md";
import { FaTrash, FaLink, FaLinkSlash } from "react-icons/fa6";

import api from "../../../services/api";
import { readFileAsync } from "../../../utils";

import Modal from "../../../components/modal";
import ModalCreationDoc from "./modalCreationDoc";

export default function Files({ project, setProject }) {
  const [documents, setDocuments] = useState(project?.documents || []);

  useEffect(() => {
    setDocuments(project?.documents);
  }, [project?.documents]);

  const onSubmit = async (documents) => {
    try {
      const { data } = await api.put(`/project/${project._id}`, { documents });
      setProject(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <div className="p-2">
      <Documents documents={documents} onChange={onSubmit} />
    </div>
  );
}

const Documents = ({ documents, onChange }) => {
  const { user } = useSelector((state) => state.Auth);

  const handleAddDocument = async (newDocument) => {
    const doc = {
      ...newDocument,
      userId: user._id,
      userName: user.name,
      userAvatar: user.avatar,
      created_at: new Date().toISOString(),
    };
    const updatedDocuments = [...documents, doc];
    await onChange(updatedDocuments);
  };

  const handleChange = async (doc) => {
    const docs = [...documents];
    const index = documents.findIndex((d) => doc._id === d._id);
    if (index >= 0) {
      docs[index] = doc;
    } else {
      doc.userId = user._id;
      doc.userName = user.name;
      doc.userAvatar = user.avatar;
      docs.push(doc);
    }
    await onChange(docs);
  };

  const handleDelete = async (doc) => {
    if (!confirm("Are you sure ?")) return;
    const docs = [...documents];
    await onChange(docs.filter((d) => d._id !== doc._id));
  };

  return (
    <div>
      <ModalCreationDoc onSubmit={handleAddDocument} />
      {documents.map((document, index) => (
        <Row key={index} document={document} onChange={handleChange} onDelete={() => handleDelete(document)} />
      ))}
    </div>
  );
};

const Row = ({ document, onChange, onDelete }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(document);

  const handleFileUpload = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    const rawBody = await readFileAsync(file);
    const { data } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder: "documents" });
    const updatedValues = { ...values, url: data, fileName: file.name };
    setValues(updatedValues); // Mise à jour de l'état local
    await onChange(updatedValues);
    setLoading(false);
  };

  return (
    <div className="grid grid-cols-7 gap-x-10 items-center mb-2">
      <div className="col-span-2">
        <input
          type="text"
          className="form-input w-full p-2 border rounded-md focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
          placeholder="Document name ..."
          value={values.name}
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          onBlur={() => onChange(values)}
        />
      </div>
      <div className="col-span-2">
        {values?.url && (
          <a className="underline whitespace-normal" target="_blank" href={values.url}>
            {values?.url?.split("/").pop()}
          </a>
        )}
      </div>
      <div>
        {document.userAvatar && (
          <div className="flex items-center">
            <img src={document.userAvatar} className="rounded-lg w-7 h-7 mr-2" alt="User Avatar" />
            <span>{document.userName}</span>
          </div>
        )}
      </div>
      <div className="px-4 py-2 text-sm font-medium text-gray-600">{document?.created_at?.slice(0, 10)}</div>
      <div className="flex items-center space-x-2">
        <label className="btn btn-primary p-2 h-auto cursor-pointer flex items-center" style={{ opacity: loading ? 0.5 : 1 }}>
          <input name="file-upload" type="file" disabled={loading} onChange={handleFileUpload} />
          <MdUpload className="text-lg" />
        </label>
        <button className="ml-2 p-2 border rounded-md hover:bg-blue-300" onClick={() => setIsModalOpen(true)}>
          {document?.url ? <FaLinkSlash /> : <FaLink />}
        </button>
        <button className="ml-2 p-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={onDelete}>
          <FaTrash />
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} className="max-w-sm w-full">
        <div className="bg-white p-4 rounded-md">
          <h2 className="text-lg font-bold mb-2">Update URL</h2>
          <input
            type="text"
            className="form-input w-full p-2 border rounded-md mb-2"
            placeholder="Enter URL"
            value={values.url}
            onChange={(e) => setValues({ ...values, url: e.target.value })}
          />
          <div className="flex justify-end">
            <button
              className="btn btn-primary"
              onClick={() => {
                onChange(values);
                setIsModalOpen(false);
              }}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
