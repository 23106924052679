import React, { useState } from "react";
import toast from "react-hot-toast";

import api from "../../../../services/api";
import Modal from "../../../../components/modal";

export default ({ onDone, company_id }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onSubmit() {
    try {
      const obj = { ...values };
      if (company_id) obj.company_id = company_id;
      const res = await api.post("/s_contact", obj);
      if (!res.ok) throw res;
      toast.success("Client created");
      setOpen(false);
      onDone();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }
  return (
    <div>
      <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={() => setOpen(true)}>
        Create a contact
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="min-w-96 p-6">
        <div>
          <div className="flex flex-col justify-between flex-wrap">
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">First Name</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="first_name"
                value={values.first_name}
                onChange={(e) => setValues({ ...values, first_name: e.target.value })}
              />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Last Name</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="last_name"
                value={values.last_name}
                onChange={(e) => setValues({ ...values, last_name: e.target.value })}
              />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Linkedin</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="linkedin"
                value={values.linkedin}
                onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
              />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Email</div>
              <input
                className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="email"
                value={values.email}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
              />
            </div>
            <div className="w-full mt-2">
              <div className="text-[14px] text-[#212325] font-medium">Description</div>
              <textarea
                className="w-full text-[14px] font-normal text-[#212325] rounded-[10px]"
                name="description"
                rows={4}
                value={values.description}
                onChange={(e) => setValues({ ...values, description: e.target.value })}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="w-full flex justify-end">
          <button className="bg-sky-700 text-white py-2 px-4 rounded-md" onClick={onSubmit}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};
