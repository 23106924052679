import React, { useEffect, useState } from "react";
import DebounceInput from "react-debounce-input";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";

import Modal from "../../components/modal";
import Paginator from "../../components/Paginator";
import Table from "../../components/Table";

import api from "../../services/api";
import { classNames } from "../../utils";

const categories = {
  PROPAL: <span className="text-yellow-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-yellow-500 bg-yellow-100">Propal</span>,
  QUOTE: <span className="text-green-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-green-500 bg-green-100">Quote</span>,
  CONTRACT: <span className="text-blue-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-blue-500 bg-blue-100">Contract</span>,
  USE_CASE: <span className="text-violet-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-violet-500 bg-violet-100">Use Case</span>,
  CV: <span className="text-red-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-red-500 bg-red-100">CV</span>,
  OTHER: <span className="text-orange-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-orange-500 bg-orange-100">Other</span>,
};

const List = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ search: "", category: "", project_id: "", page: 1, pageSize: 20 });
  const [projects, setProjects] = useState([]);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("propals");

  const getProjects = async () => {
    try {
      const { ok, data } = await api.post("/project/search", { status: "active" });
      if (!ok) throw new Error("Error fetching projects");
      setProjects(data.projects.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      console.error(error);
      toast.error("Error fetching projects");
    }
  };
  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = {};
        if (filters.category) query.category = filters.category;
        if (filters.project_id) query.project_id = filters.project_id;

        // const category = tab;

        //["PROPAL", "QUOTE", "CONTRACT", "USE_CASE", "OTHER"]

        let category = "";
        if (tab === "propals") category = "PROPAL";
        if (tab === "contracts") category = "CONTRACT";
        if (tab === "use-cases") category = "USE_CASE";
        if (tab === "cv") category = "CV";
        if (tab === "others") category = "OTHER";

        const { ok, data, total } = await api.post("/quote/search", {
          skip: (filters.page - 1) * filters.pageSize,
          limit: filters.pageSize,
          search: filters.search,
          tag: filters.tag,
          archived: false,
          category,
          project_id: filters.project_id,
        });

        if (!ok) return toast.error("Error while fetching quotes");
        setData(data);
        setTotal(total);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching quotes");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters, tab]);

  return (
    <div className="p-5 space-y-4">
      <div className="w-full flex items-center gap-4">
        <div className="w-full max-w-sm space-y-2">
          <DebounceInput
            debounceTimeout={300}
            name="search"
            id="search"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
            placeholder="Search"
            value={filters.search}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
            }}
          />
        </div>
        <div>
          <select
            name="tag"
            id="tag"
            className="block w-full  text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.tag || null}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, tag: e.target.value, page: 1 }));
            }}>
            <option key={null} value={null}>
              All
            </option>
            {[
              "to review",
              "fintech",
              "govtech",
              "healthtech",
              "edtech",
              "agritech",
              "sportech",
              "proptech",
              "heritagetech",
              "legaltech",
              "martech",
              "hrtech",
              "metabase",
              "teams",
              "mobile",
              "desktop",
              "extension",
              "ia",
              "urgent",
              "blog",
              "sortlist",
              "codeur",
            ].map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            name="project"
            id="project"
            className="block w-full text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.project_id || ""}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, project_id: e.target.value, page: 1 }));
            }}>
            <option key="" value="">
              All Projects
            </option>
            {projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full flex items-center gap-4 justify-end">
          <Create />
        </div>
      </div>
      <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
        <TabItem title="Propales" tab="propals" onClick={() => setTab("propals")} active={tab === "propals"} />
        <TabItem title="Contracts" tab="contracts" onClick={() => setTab("contracts")} active={tab === "contracts"} />
        <TabItem title="Use cases" tab="use-cases" onClick={() => setTab("use-cases")} active={tab === "use-cases"} />
        <TabItem title="CV" tab="cv" onClick={() => setTab("cv")} active={tab === "cv"} />
        <TabItem title="Others" tab="others" onClick={() => setTab("others")} active={tab === "others"} />
        <TabItem title="All" tab="all" onClick={() => setTab("all")} active={tab === "all"} />
      </nav>
      <section className="text-sm">
        <Table
          header={[
            { title: "Index" },
            { title: "Name" },
            { title: "Category" },
            { title: "Project" },
            { title: "Tags" },
            { title: "Score" },
            { title: "Assigned to" },
            { title: "Created by" },
            { title: "Created at" },
          ]}
          total={total}
          loading={loading}
          height="h-[32rem]"
          sticky={true}
          noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
          {data?.map((item, index) => (
            <tr className={`hover:bg-gray-50 cursor-pointer border-b bg-white`} key={item._id}>
              <a href={`/quote/${item._id}`} className="contents" target="_blank">
                <td className="px-4 py-2 max-w-xs truncate">{`#${item.index}`}</td>
                <td className="px-4 py-2 max-w-xs truncate">{item.name}</td>
                <td className="px-4 py-2 max-w-xs truncate">{categories[item.category]}</td>
                <td className="px-4 py-2 max-w-xs truncate">{item.project_name || "-"}</td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <div className="flex gap-2">
                    {(item.tags || []).map((tag) => {
                      const color = tag === "to review" ? "red" : "gray";
                      return (
                        <span key={tag} className={`text-xs font-semibold text-gray-500 bg-${color}-100 px-2 py-1 rounded-md`}>
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  {item.score ? (
                    <div className="flex items-center space-x-1">
                      <span>{item.score}</span>
                      <FaStar size={16} className="text-yellow-500" />
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <img src={item.assigned_user_avatar} className="rounded-lg w-7 h-7 mr-2 object-cover" />
                  {item.assigned_user_name}
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <img src={item.user_avatar} className="rounded-lg w-7 h-7 mr-2 object-cover" />
                  {item.user_name}
                </td>
                <td className="px-4 py-2 max-w-xs truncate">{new Date(item.created_at).toLocaleDateString("fr", { day: "numeric", month: "short", year: "numeric" })}</td>
              </a>
            </tr>
          ))}
        </Table>
        <div className="mt-1">
          <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
        </div>
      </section>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({ name: "" });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { ok, data } = await api.post("/quote", { name: values.name });
      if (!ok) throw new Error("Error creating template");
      toast.success("Success creating the quote");
      navigate(`/quote/${data._id}`);
    } catch (error) {
      console.error(error);
      toast.error("Error creating template");
    }
  };

  return (
    <>
      <button className="blue-btn" onClick={() => setOpen(true)}>
        Create a quote
      </button>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-2/3">
        <h1 className="text-2xl font-semibold m-4">Create a quote</h1>
        <div className="space-y-6 p-4 flex gap-2 h-[540px]">
          <div className="w-3/4 h-full flex flex-col justify-between items-end">
            <div className="flex items-center justify-between py-4 w-full">
              <label className="text-sm font-semibold">{values.template_name}</label>
              <div className="w-1/2 flex items-center gap-4">
                <label className="text-sm font-semibold">Name</label>
                <input type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} className="w-full p-2 border rounded" />
                <button className="blue-btn" onClick={handleSubmit}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default List;

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:text-gray-700",
      active ? "text-primary border-b-2 border-primary" : "",
    )}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
