import React, { useEffect, useState } from "react";

import Kanban from "./kanban";
import Stats from "./stats";

export default () => {
  const [tab, setTab] = useState("kanban");

  return (
    <div className="bg-white h-full">
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px list-none space-x-2">
          <li
            onClick={() => setTab("kanban")}
            className={`inline-block p-2 rounded-t-lg border-b-2 cursor-pointer hover:text-gray-600 dark:hover:text-gray-300 ${
              tab === "kanban" ? "border-primary text-primary" : "border-transparent"
            }`}>
            Kanban
          </li>
          <li
            onClick={() => setTab("stats")}
            className={`inline-block p-2 rounded-t-lg border-b-2 cursor-pointer hover:text-gray-600 dark:hover:text-gray-300 ${
              tab === "stats" ? "border-primary text-primary" : "border-transparent"
            }`}>
            Stats
          </li>
        </ul>
      </div>
      <div className="p-4">
        {tab === "kanban" && <Kanban />}
        {tab === "stats" && <Stats />}
      </div>
    </div>
  );
};
