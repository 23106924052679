import { useState, useEffect } from "react";

import Modal from "../../../../../components/modal";
import api from "../../../../../services/api";

export default ({ epic, setEpic }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState(epic.feedback || "");
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    try {
      setLoading(true);
      const feedBackContent = "INPUT :" + epic.title + "\n" + "OUTPUT :" + JSON.stringify(epic.suggestions) + "\n" + "FEEDBACK :" + feedback;
      if (epic.agent_feature_id) {
        const { data, ok } = await api.put(`/agent_feature/${epic.agent_feature_id}`, {
          description: feedBackContent,
        });
        if (!ok) throw new Error(data.error);
        const { data: newEpic, ok: okEpic } = await api.put(`/epic/${epic._id}`, { feedback });
        if (okEpic) setEpic(newEpic);
      } else {
        const { data, ok } = await api.post(`/agent_feature`, {
          title: "Epic Feedback",
          description: feedBackContent,
          tags: ["feedbacks"],
          agent_id: "670d58ceeddea1e0bc7d200c",
          agent_name: "Gerard",
        });
        if (!ok) throw new Error(data.error);
        const { data: newEpic, ok: okEpic } = await api.put(`/epic/${epic._id}`, { feedback, agent_feature_id: data._id });
        if (okEpic) setEpic(newEpic);
      }
      setIsOpen(false);
    } catch (e) {
      console.error("Error saving feedback:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="relative w-full max-w-2xl mx-auto bg-white px-4 py-2 border border-gray-200 rounded-md mt-4">
        <button className={`cursor-pointer text-blue-500 font-semibold rounded px-2 py-1 hover:bg-blue-100 ml-2 text-sm `} onClick={() => setIsOpen(true)}>
          Give feedback
        </button>

        {epic.feedback && (
          <>
            <h3 className="text-lg font-semibold mb-4">Previous Feedbacks</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-700 whitespace-pre-wrap">{epic.feedback}</p>
            </div>
          </>
        )}
      </div>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="p-4">
          <h1 className="text-lg font-semibold">Feedback</h1>
          <textarea className="w-full h-32 p-2 border border-gray-200 rounded-md mt-2" value={feedback} onChange={(e) => setFeedback(e.target.value)} />
          <div className="flex justify-center mt-4">
            <button className="blue-btn" onClick={handleSave}>
              {loading ? "Saving..." : "Save feedback"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
