import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoMdCloseCircle } from "react-icons/io";

import Loader from "../../components/loader";
import LoadingButton from "../../components/loadingButton";
import TabItem from "../../components/TabItem";
import SelectProject from "../../components/selectProject";

import api from "../../services/api";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";

export default () => {
  const { id } = useParams();
  const [uptimeRobot, setUptimeRobot] = useState();

  const [tab, setTab] = useState(INFORMATION);

  useEffect(() => {
    fetchUptimeRobot();
  }, [id]);

  const fetchUptimeRobot = async () => {
    try {
      const { data, ok } = await api.get(`/uptime_robot/${id}`);
      if (!ok) return toast.error("Error fetching uptime robot data.");
      setUptimeRobot(data);
    } catch (e) {
      toast.error("Error fetching uptime robot data.");
    }
  };

  if (!uptimeRobot) return <Loader />;

  return (
    <div className="p-5">
      <div className="w-full gap-x-4 gap-y-2 ">
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden my-2">
          <TabItem title="Information" tab="INFORMATION" onClick={() => setTab("INFORMATION")} active={tab === "INFORMATION"} />
          <TabItem title="Raw Data" tab="RAW_DATA" onClick={() => setTab("RAW_DATA")} active={tab === "RAW_DATA"} />
        </nav>
        <div className="bg-white rounded-md p-6 border border-primary-black-50 w-full">
          {tab === INFORMATION && <Information uptimeRobot={uptimeRobot} setUptimeRobot={setUptimeRobot} />}
          {tab === RAW_DATA && <RawData uptimeRobot={uptimeRobot} />}
        </div>
      </div>
    </div>
  );
};

const Information = ({ uptimeRobot, setUptimeRobot }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({ ...uptimeRobot, project_urls: uptimeRobot.project_urls?.length ? uptimeRobot.project_urls : [""] });
  const [btnLoading, setBtnLoading] = useState(false);

  const onUpdate = async () => {
    const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
    if (values?.project_urls?.some((url) => !urlRegex.test(url))) return toast.error("One or more URLs are invalid!");

    setBtnLoading(true);
    const { data, ok, code } = await api.put(`/uptime_robot/${uptimeRobot._id}`, values);
    if (!ok) return toast.error(code || "Error updating!");
    toast.success("Updated!");
    setBtnLoading(false);
    setUptimeRobot(data);
  };

  const onDelete = async () => {
    const { ok } = await api.remove(`/uptime_robot/${uptimeRobot._id}`);
    if (!ok) return toast.error("Error deleting uptime robot!");
    toast.success("Deleted!");
    navigate("/uptime-robot");
  };

  const addUrl = () => setValues({ ...values, project_urls: [...values?.project_urls, ""] });
  const removeUrl = (index) => setValues({ ...values, project_urls: values?.project_urls.filter((_, i) => i !== index) });
  const updateUrl = (index, value) => {
    const updatedUrls = [...values.project_urls];
    updatedUrls[index] = value;
    setValues({ ...values, project_urls: updatedUrls });
  };

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-3">
        <div className="w-ful">
          <div className="font-medium mb-2 text-sm">Select Project</div>
          <SelectProject className="w-full" value={values.project_id} onChange={(e) => setValues({ ...values, project_name: e.name, project_id: e._id })} />
        </div>

        <div className="w-full col-span-2 border-t pt-3 mt-2">
          <div className="text-sm font-medium mb-2">Project URLs</div>
          {values?.project_urls?.map((url, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input type="text" placeholder="https://..." className="input flex-grow" value={url} onChange={(e) => updateUrl(index, e.target.value)} />
              <IoMdCloseCircle className="text-red-500 cursor-pointer" size={24} onClick={() => removeUrl(index)} />
            </div>
          ))}
          <button className="bg-gray-50 text-sm px-4 py-1 rounded border cursor-pointer hover:bg-gray-100 border-gray-300 text-black" onClick={addUrl}>
            Add URL
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between space-x-2 mt-10 border-t pt-8">
        <button className="btn bg-red-500 text-white" onClick={onDelete}>
          Delete
        </button>

        <LoadingButton className="btn bg-primary text-white" loading={btnLoading} onClick={() => onUpdate()}>
          Update
        </LoadingButton>
      </div>
    </div>
  );
};

const RawData = ({ uptimeRobot }) => {
  return (
    <div className="text-sm bg-slate-800 p-5 rounded-md text-white">
      <pre className="break-all whitespace-pre-wrap">{JSON.stringify(uptimeRobot, null, 2)}</pre>
    </div>
  );
};
