import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { FaSync } from "react-icons/fa";
import Feedbacks from "./feedback";

import api from "../../../../../services/api";

export default ({ epic, setEpic }) => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    // loadGerard();
  }, [epic]);

  async function updateEpic(value) {
    try {
      const { data } = await api.put(`/epic/${epic._id}`, { suggestions: value });
      setEpic(data);
    } catch (e) {
      console.error("Update epic error:", e);
      toast.error(e.response?.data?.error || "An error occurred while updating epic");
      throw e;
    }
  }

  async function loadGerard() {
    if ((epic.suggestions || []).length > 0) return;
    if (loading) return;

    try {
      setLoading(true);
      console.log("LOAD GERARD");
      const { data, ok } = await api.get(`/epic/${epic._id}/gerard`);
      if (!ok) throw new Error(data.error);
      await updateEpic(data);
    } catch (e) {
      console.error("Gerard loading error:", e);
      toast.error(e.response?.data?.error || "An error occurred while loading suggestions");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="relative w-full max-w-2xl mx-auto bg-white px-4 py-2 border border-gray-200 rounded-md">
        <div className="flex flex-row items-center gap-3">
          <h1 className="text-lg font-semibold">AI Suggestions</h1>
          <div>
            <button className="cursor-pointer text-blue-500 font-semibold text-sm mt-4 mb-4 hover:bg-blue-100 rounded" onClick={loadGerard}>
              <FaSync className={`${loading && "animate-spin"}`} />
            </button>
          </div>
          {loading && <span>Gerard is thinking...</span>}
        </div>
        <div>
          {(epic.suggestions || []).map((suggestion, index) => {
            if (suggestion.status === "ACCEPTED") return null;
            if (suggestion.status === "REJECTED") return null;
            return (
              <div key={index} className="mt-4">
                <SuggestionSentence suggestion={suggestion} />
                <p className="text-gray-600 text-xs">{suggestion.why}</p>
                <div className="flex justify-between">
                  <div></div>
                  <div>
                    <button
                      className={`cursor-pointer text-blue-500 font-semibold rounded px-2 py-1 hover:bg-blue-100 text-sm`}
                      onClick={async () => {
                        const suggestions = [...epic.suggestions];
                        suggestions[index].status = "ACCEPTED";
                        const { data } = await api.put(`/epic/${epic._id}`, { [suggestion.field]: suggestion.to, suggestions });
                        setEpic(data);
                        toast.success("Suggestion accepted!");
                      }}>
                      Accept
                    </button>
                    <button
                      className={`cursor-pointer text-red-500 font-semibold rounded px-2 py-1 hover:bg-red-100 ml-2 text-sm `}
                      onClick={async () => {
                        const suggestions = [...epic.suggestions];
                        suggestions[index].status = "REJECTED";
                        const { data } = await api.put(`/epic/${epic._id}`, { suggestions });
                        setEpic(data);
                        toast.success("Suggestion rejected!");
                      }}>
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          {(epic.suggestions || []).some((e) => e.status === "PENDING") && (
            <button className="cursor-pointer text-green-500 font-semibold text-sm mt-4 px-2 py-1 hover:bg-green-100 rounded" onClick={() => toast.success("TODO")}>
              Accept All Suggestions
            </button>
          )}
        </div>
      </div>
      <Feedbacks epic={epic} setEpic={setEpic} />
    </div>
  );
};

const SuggestionSentence = ({ suggestion }) => {
  if (!suggestion.from) {
    return (
      <h4 className="text-sm">
        Set {suggestion.field} to <strong>{suggestion.to}</strong>
      </h4>
    );
  }
  return (
    <h4 className="text-sm">
      Change {suggestion.field} from <strong>{suggestion.from || "N/A"}</strong> to <strong>{suggestion.to}</strong>
    </h4>
  );
};
