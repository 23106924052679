import React, { useEffect, useState } from "react";
import api from "../../../services/api";

// Check daily invitation limit (40 per day)
//// Check weekly invitation limit (150 per week)
// Check daily visit limit (100 per day)

//linkedin_invite_sent
//linkedin_profil_visited
export default () => {
  const [stats, setStats] = useState({
    dailyInvites: 0,
    weeklyInvites: 0,
    dailyVisits: 0,
  });
  async function fetchStats() {
    // Get today's date at midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Get start of current week (7 days ago)
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - 7);
    startOfWeek.setHours(0, 0, 0, 0);

    // Get daily invites
    const { data: dailyInvitesData } = await api.post("/s_activity/search", {
      type: "linkedin_invite_sent",
      created_at: { $gte: today.toISOString() },
    });

    // Get weekly invites
    const { data: weeklyInvitesData } = await api.post("/s_activity/search", {
      type: "linkedin_invite_sent",
      created_at: { $gte: startOfWeek.toISOString() },
      limit: 300,
    });

    // Get daily visits
    const { data: dailyVisitsData } = await api.post("/s_activity/search", {
      type: "linkedin_profil_visited",
      created_at: { $gte: today.toISOString() },
    });

    setStats({
      dailyInvites: dailyInvitesData.length,
      weeklyInvites: weeklyInvitesData.length,
      dailyVisits: dailyVisitsData.length,
    });
  }

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className="bg-white h-full p-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="border rounded p-4">
          <h3 className="font-medium">Daily Invites</h3>
          <p className={`text-2xl font-bold ${stats.dailyInvites > 40 ? "text-red-600" : "text-green-600"}`}>{stats.dailyInvites}/40</p>
        </div>
        <div className="border rounded p-4">
          <h3 className="font-medium">Weekly Invites</h3>
          <p className={`text-2xl font-bold ${stats.weeklyInvites > 150 ? "text-red-600" : "text-green-600"}`}>{stats.weeklyInvites}/150</p>
        </div>
        <div className="border rounded p-4">
          <h3 className="font-medium">Daily Profile Visits</h3>
          <p className={`text-2xl font-bold ${stats.dailyVisits > 100 ? "text-red-600" : "text-green-600"}`}>{stats.dailyVisits}/100</p>
        </div>
      </div>
    </div>
  );
};
