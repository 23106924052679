import React, { useState } from "react";
import toast from "react-hot-toast";

import RichTextEditorSun from "../../../components/RichTextEditor";
import api from "../../../services/api";

export default ({ project, setProject }) => {
  const [values, setValues] = useState({
    spec_problems: project.spec_problems || "",
    spec_keys_features: project.spec_keys_features || "",
    spec_personnae: project.spec_personnae || "",
    spec_platforms: project.spec_platforms || "",
    spec_revenu_envisages: project.spec_revenu_envisages || "",
    spec_other: project.spec_other || "",
    spec_budget: project.spec_budget || "",
  });

  const onUpdate = async () => {
    try {
      const { ok, data } = await api.put(`/project/${project._id}`, values);
      if (!ok) return toast.error("Error Updating Brief");
      toast.success("Brief Updated");
      setProject(data);
    } catch (error) {
      console.error(error);
      toast.error("Error Updating Brief");
    }
  };

  const handleChange = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="font-medium mb-2">Problems to Solve</h3>
        <p className="text-gray-600 text-sm mb-2">Describe the key problems or pain points that your project aims to address.</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_problems}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_problems", e)}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Key Features</h3>
        <p className="text-gray-600 text-sm mb-2">List the main features and functionalities that will be implemented in your solution.</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_keys_features}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_keys_features", e)}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Target Users/Personas</h3>
        <p className="text-gray-600 text-sm mb-2">Define your target audience and user personas who will benefit from your solution.</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_personnae}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_personnae", e)}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Platforms & Channels</h3>
        <p className="text-gray-600 text-sm mb-2">Specify the platforms where your solution will be deployed (mobile app, web app, marketplace, etc).</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_platforms}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_platforms", e)}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Expected Revenue</h3>
        <p className="text-gray-600 text-sm mb-2">Outline your revenue model and projected earnings from the project.</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_revenu_envisages}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_revenu_envisages", e)}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Budget</h3>
        <p className="text-gray-600 text-sm mb-2">What is the approximate budget for the project?</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_budget}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_budget", e)}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Additional Information</h3>
        <p className="text-gray-600 text-sm mb-2">Any other relevant details or specifications about your project.</p>
        <RichTextEditorSun
          buttonList={[["font", "fontSize"], ["bold", "italic"], ["list"], ["link"]]}
          values={values.spec_other}
          options={{ minHeight: "150px", backgroundColor: "white" }}
          onChange={(e) => handleChange("spec_other", e)}
        />
      </div>

      <button onClick={onUpdate} className="blue-btn p-6 w-full">
        Update Brief
      </button>
    </div>
  );
};

// spec_problems: { type: String },
// spec_keys_features: { type: String },
// spec_personnae: { type: String },
// spec_platforms: { type: String },
// spec_revenu_envisages: { type: String },
// spec_other: { type: String },
