import React, { useEffect, useState } from "react";
import DebounceInput from "react-debounce-input";
import toast from "react-hot-toast";
import { FaStar } from "react-icons/fa";

import Paginator from "../../../components/Paginator";
import Table from "../../../components/Table";

import api from "../../../services/api";

const categories = {
  PROPAL: <span className="text-yellow-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-yellow-500 bg-yellow-100">Propal</span>,
  QUOTE: <span className="text-green-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-green-500 bg-green-100">Quote</span>,
  CONTRACT: <span className="text-blue-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-blue-500 bg-blue-100">Contract</span>,
  USE_CASE: <span className="text-violet-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-violet-500 bg-violet-100">Use Case</span>,
  CV: <span className="text-red-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-red-500 bg-red-100">CV</span>,
  OTHER: <span className="text-orange-500 font-medium p-0.5 text-xs px-3 rounded-xl border border-orange-500 bg-orange-100">Other</span>,
};

const List = ({ project }) => {
  const [filters, setFilters] = useState({ search: "", category: "", page: 1, pageSize: 20 });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { ok, data, total } = await api.post("/quote/search", {
          skip: (filters.page - 1) * filters.pageSize,
          limit: filters.pageSize,
          search: filters.search,
          tag: filters.tag,
          archived: false,

          project_id: project._id,
        });

        if (!ok) return toast.error("Error while fetching quotes");
        setData(data);
        setTotal(total);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching quotes");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <div className="p-5 space-y-4">
      <div className="w-full flex items-center gap-4">
        <div className="w-full max-w-sm space-y-2">
          <DebounceInput
            debounceTimeout={300}
            name="search"
            id="search"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
            placeholder="Search"
            value={filters.search}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
            }}
          />
        </div>
        <div>
          <select
            name="tag"
            id="tag"
            className="block w-full  text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.tag || null}
            onChange={(e) => {
              e.persist();
              setFilters((f) => ({ ...f, tag: e.target.value, page: 1 }));
            }}>
            <option key={null} value={null}>
              All
            </option>
            {[
              "to review",
              "fintech",
              "govtech",
              "healthtech",
              "edtech",
              "agritech",
              "sportech",
              "proptech",
              "heritagetech",
              "legaltech",
              "martech",
              "hrtech",
              "metabase",
              "teams",
              "mobile",
              "desktop",
              "extension",
              "ia",
              "urgent",
              "blog",
              "sortlist",
              "codeur",
            ].map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
      </div>

      <section className="text-sm">
        <Table
          header={[
            { title: "Index" },
            { title: "Name" },
            { title: "Category" },
            { title: "Tags" },
            { title: "Score" },
            { title: "Assigned to" },
            { title: "Created by" },
            { title: "Created at" },
          ]}
          total={total}
          loading={loading}
          height="h-[32rem]"
          sticky={true}
          noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
          {data?.map((item, index) => (
            <tr className={`hover:bg-gray-50 cursor-pointer border-b bg-white`} key={item._id}>
              <a href={`/quote/${item._id}`} className="contents" target="_blank">
                <td className="px-4 py-2 max-w-xs truncate">{`#${item.index}`}</td>
                <td className="px-4 py-2 max-w-xs truncate">{item.name}</td>
                <td className="px-4 py-2 max-w-xs truncate">{categories[item.category]}</td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <div className="flex gap-2">
                    {(item.tags || []).map((tag) => {
                      const color = tag === "to review" ? "red" : "gray";
                      return (
                        <span key={tag} className={`text-xs font-semibold text-gray-500 bg-${color}-100 px-2 py-1 rounded-md`}>
                          {tag}
                        </span>
                      );
                    })}
                  </div>
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  {item.score ? (
                    <div className="flex items-center space-x-1">
                      <span>{item.score}</span>
                      <FaStar size={16} className="text-yellow-500" />
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <img src={item.assigned_user_avatar} className="rounded-lg w-7 h-7 mr-2 object-cover" />
                  {item.assigned_user_name}
                </td>
                <td className="px-4 py-2 max-w-xs truncate">
                  <img src={item.user_avatar} className="rounded-lg w-7 h-7 mr-2 object-cover" />
                  {item.user_name}
                </td>
                <td className="px-4 py-2 max-w-xs truncate">{new Date(item.created_at).toLocaleDateString("fr", { day: "numeric", month: "short", year: "numeric" })}</td>
              </a>
            </tr>
          ))}
        </Table>
        <div className="mt-1">
          <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
        </div>
      </section>
    </div>
  );
};

export default List;
