import React, { useContext, useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";

import App, { AppContext } from "./context";
import toast from "react-hot-toast";

export default ({}) => {
  const context = useContext(AppContext);
  const [showExplanation, setShowExplanation] = useState(false);

  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden";
    return () => (document.querySelector("body").style.overflow = "auto");
  }, []);

  const step = context.workshop.steps[context.index - 1];

  const handleAnswerSelect = (question, answer) => {
    const updatedUserWorkshop = { ...context.userWorkshop };

    const questions = updatedUserWorkshop.questions || [];
    const questionIndex = updatedUserWorkshop.questions.findIndex((q) => q.key === question.key);

    //find index of the answer
    const answers = questions[questionIndex]?.answers || [];
    if (answers.length === 0) {
      questions.push({
        key: question.key,
        question: question.question,
        answers: [
          {
            key: answer.key,
            answer: answer.answer,
            user_answer: true,
            correct_answer: answer.isCorrect,
          },
        ],
      });
    } else {
      const answerIndex = answers.findIndex((a) => a.key === answer.key);

      if (answerIndex >= 0) {
        questions[questionIndex].answers[answerIndex] = {
          ...questions[questionIndex].answers[answerIndex],
          user_answer: !questions[questionIndex].answers[answerIndex].user_answer,
        };
      } else {
        questions[questionIndex].answers.push({
          key: answer.key,
          answer: answer.answer,
          user_answer: true,
          correct_answer: answer.isCorrect,
        });
      }
    }
    context.setUserWorkshop({ ...updatedUserWorkshop, questions });
  };

  const validate = () => {
    //update
    context.updateUserWorkshop();

    const questions = context.userWorkshop?.questions || [];
    //get the key of all questions in the step
    const stepKeys = step.elements.filter((e) => e.kind === "question").map((e) => e.value.key);
    //get the questions that are in the step
    const questionsInStep = questions.filter((q) => stepKeys.includes(q.key));
    //get the questions that have errors
    const errors = questionsInStep.filter((q) => {
      return q.answers.some((a) => a.correct_answer !== a.user_answer);
    });
    if (errors.length > 0) {
      toast.error("Please answer all questions correctly");
      setShowExplanation(true);
      return false;
    }
    return true;
  };

  const Content = () => {
    const elements = [];

    // some variables to check if an image exists
    let hasImage = false;
    let imageElement = null;
    let textElements = [];

    for (let i = 0; i < step.elements.length; i++) {
      const element = step.elements[i];

      //  check if the element is an image
      if (element.kind === "image" && element.value) {
        hasImage = true;
        imageElement = (
          <div className="w-[40%] mx-auto" key={`image-${i}`}>
            <img src={element.value?.rawBody || element.value} className="w-full rounded-md" />
          </div>
        );
      } else if (element.kind === "text") {
        textElements.push(
          <div className="w-2/3 mx-auto mb-20" key={`text-${i}`}>
            {i === 0 && ( // show only once on multi text page
              <div
                className={`rich-text text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold underline mb-5 text-primary`}
                dangerouslySetInnerHTML={{
                  __html: `${context.index - 1}. ${context.workshop.steps[context.index - 1]?.title}`,
                }}
              />
            )}
            <div className={`rich-text text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl`} dangerouslySetInnerHTML={{ __html: element.value }} />
          </div>,
        );
      } else if (element.kind === "code") {
        textElements.push(
          <div className="mb-20 w-full text-xs rounded-md" key={`code-${i}`}>
            <SyntaxHighlighter language="javascript" showLineNumbers={true} showInlineLineNumbers={true} style={a11yDark}>
              {element.value}
            </SyntaxHighlighter>
          </div>,
        );
      } else if (element.kind === "video") {
        textElements.push(
          <div className="mb-20 w-full text-xs rounded-md h-[400px] max-w-4xl mx-auto" key={`video-${i}`}>
            <video
              src={element.value}
              frameBorder="0"
              className="h-full w-full rounded-xl aspect-video"
              controls
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></video>
          </div>,
        );
      } else if (element.kind === "question") {
        textElements.push(
          <div className="mb-20 w-full text-xs rounded-md" key={`question-${element.value.question}`}>
            <div className="text-lg font-bold mb-2">{element.value.question}</div>
            <div className="flex flex-col gap-4">
              {element.value.answers.map((answer) => {
                //get the answers of the question and then the isSelected answers
                const question = context.userWorkshop?.questions?.find((question) => question.key === element.value.key);
                const isSelected = question?.answers.some((a) => a.key === answer.key && a.user_answer === true);
                return (
                  <label key={answer.key} className={`flex items-center gap-2 p-2 border rounded `} onClick={() => handleAnswerSelect(element.value, answer)}>
                    <input type="checkbox" checked={isSelected} readOnly className="form-checkbox" />
                    <span>{answer.answer}</span>
                    <span className="ml-6 text-red-500">{showExplanation ? answer.explanation : ""}</span>
                  </label>
                );
              })}
            </div>
          </div>,
        );
      }
    }

    // put image in first position
    if (hasImage) {
      elements.push(
        <div className="flex flex-col md:flex-row items-start gap-4 w-full">
          {imageElement}
          <div className="w-full md:w-2/3">{textElements}</div>
        </div>,
      );
    } else {
      elements.push(...textElements);
    }

    return <div className="grid grid-cols-1 items-center gap-4 pr-2">{elements}</div>;
  };

  const Footer = ({}) => {
    useEffect(() => {
      function handleSpaceBar(evt) {
        if (document.activeElement.tagName === "TEXTAREA" || document.activeElement.tagName === "INPUT") return;
        if (evt.key == " " || evt.code == "Space" || evt.code == 32) onContinue();
      }
      document.body.addEventListener("keyup", handleSpaceBar);
      return () => document.body.removeEventListener("keyup", handleSpaceBar);
    }, []);

    const onContinue = () => {
      if (validate()) {
        setShowExplanation(false);
        context.next();
      }
    };

    return (
      <div className="space-y-3 w-full p-3 fixed bottom-0 inset-x-0 bg-gray-100 border-t border-gray-200">
        <div className="flex items-center justify-center max-w-7xl mx-auto px-3 space-x-16">
          {context.index >= 2 ? (
            <button
              onClick={() => context.setIndex(context.index - 1)}
              className="group flex gap-2 items-center justify-around bg-primary disabled:pointer-events-none disabled:bg-half-grey disabled:cursor-not-allowed text-xl text-white px-9 py-3 rounded-md border shadow-sm transition-colors">
              <MdArrowBackIosNew className="group-hover:-translate-x-2 transition-transform" />
              Previous
            </button>
          ) : null}
          <button
            className="group flex gap-2 items-center justify-around bg-primary hover:bg-primary-500 text-xl text-white px-9 py-3 rounded-md border shadow-sm transition-colors"
            onClick={onContinue}>
            Continue
            <MdArrowForwardIos className="group-hover:translate-x-2 transition-transform" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="pr-2 w-full">
        <Content />
      </div>
      <Footer />
    </>
  );
};
