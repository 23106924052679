import React, { useEffect, useState } from "react";

import List from "./list";
import Stats from "./stats";
import Kanban from "./kanban";
import { FaUsers } from "react-icons/fa";
import { PiKanbanFill } from "react-icons/pi";
import { IoStatsChart } from "react-icons/io5";
import { classNames } from "../../utils";
import FunnelPipeline from "./funnel_pipeline";
import { Link } from "react-router-dom";

const TABS = [
  {
    title: "List",
    tab: "list",
    Icon: FaUsers,
  },
  {
    title: "Kanban",
    tab: "kanban",
    Icon: PiKanbanFill,
  },
  {
    title: "Stats",
    tab: "stats",
    Icon: IoStatsChart,
  },
  {
    title: "Pipeline",
    tab: "pipeline",
    Icon: IoStatsChart,
  },
];

export default () => {
  const [tab, setTab] = useState("list");

  return (
    <div>
      <div className="m-2">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                This view is being deprecated. Please use the new{" "}
                <Link to="/job" className="font-medium underline text-yellow-700 hover:text-yellow-600">
                  Job Board
                </Link>{" "}
                instead.
              </p>
            </div>
          </div>
        </div>
        <nav className="bg-white rounded-md flex flex-row w-full border-[1px] border-gray-200 overflow-hidden">
          {TABS.map((t) => (
            <TabItem key={t.tab} title={t.title} tab={t.tab} onClick={() => setTab(t.tab)} active={tab === t.tab} Icon={t.Icon} />
          ))}
        </nav>
        <div className="h-[calc(100%_-_100px)] overflow-auto">
          {tab === "kanban" && <Kanban />}
          {tab === "list" && <List />}
          {tab === "stats" && <Stats />}
          {tab === "pipeline" && <FunnelPipeline />}
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ title, onClick, active, Icon, Badge }) => (
  <button
    onClick={onClick}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm  hover:border-gray-300 hover:text-gray-700 border-b-2",
      active ? "text-primary  border-primary" : "border-transparent text-gray-500",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
