import React, { useEffect, useState, useCallback } from "react";
import { FaUser, FaLinkedin, FaGlobe, FaRegBuilding, FaEnvelope, FaCheck, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import Campaigns from "./campaigns";
import { RiMoneyEuroCircleFill } from "react-icons/ri";

import SelectUser from "../../../../components/selectUser";

import SelectSource from "../SelectSource";
import api from "../../../../services/api";

const TABS = {
  view: "view",
  edit: "edit",
  raw: "raw",
};

export default ({ company, setCompany, onClick }) => {
  const [tab, setTab] = useState(TABS.view);
  const [selected, setSelected] = useState(false);
  const [values, setValues] = useState(company);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!selected) return;
      if (e.target.closest(".company-container") !== null) return;
      setSelected(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [selected]);

  if (!company) return <div />;

  if (!selected) return <Preview values={company} onClick={() => setSelected(true)} />;

  return (
    <div className="bg-white shadow-md cursor-pointer company-container" onClick={onClick}>
      <div className="flex space-x-2 text-sm border-b">
        <button className={`px-3 py-1 ${tab === TABS.view ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.view)}>
          View
        </button>
        <button className={`px-3 py-1 ${tab === TABS.edit ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.edit)}>
          Edit
        </button>
        <button className={`px-3 py-1 ${tab === TABS.raw ? "border-b-2 border-blue-500 font-medium" : "text-gray-500 hover:text-gray-700"}`} onClick={() => setTab(TABS.raw)}>
          Raw
        </button>
      </div>
      <div className="p-4">
        {tab === TABS.view && <ViewTab values={values} company={company} setValues={setValues} setTab={setTab} />}
        {tab === TABS.edit && <EditTab values={values} setValues={setValues} company={company} setCompany={setCompany} setTab={setTab} />}
        {tab === TABS.raw && <RawTab values={values} />}
      </div>
    </div>
  );
};

const Preview = ({ values, onClick }) => {
  return (
    <div className="space-y-2 cursor-pointer company-container shadow-md p-4" onClick={onClick}>
      <div className="flex items-center">
        <FaUser className="mr-3" />
        <span>{values.name}</span>
      </div>
      <div className="flex items-center">
        {values.linkedin ? (
          <a href={values.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline flex items-center">
            <FaLinkedin className="mr-3" /> {values.linkedin_pseudo}
          </a>
        ) : (
          <span className="text-gray-500">No LinkedIn profile</span>
        )}
      </div>
      <div className="flex items-center">
        <FaGlobe className="mr-3" />
        {values.website ? (
          <a href={values.website.startsWith('http') ? values.website : `http://${values.website}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            {values.website}
          </a>
        ) : (
          <span className="text-gray-500">No website</span>
        )}
      </div>
      {values.personnae && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.personnae}</span>
        </div>
      )}
      {values.stage && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.stage}</span>
        </div>
      )}
      {values.source_name && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.source_name}</span>
        </div>
      )}
      {values.deal_amount && (
        <div className="flex items-center">
          <RiMoneyEuroCircleFill className="mr-3" />
          <div>{values.deal_amount} €</div>
        </div>
      )}
      {values?.linkedin_raw?.employees && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <div>{values.linkedin_raw.employees} employees</div>
        </div>
      )}
      <div>
        <p className="text-xs text-gray-600">{values.description || "No description"}</p>
      </div>
    </div>
  );
};

const ViewTab = ({ values, company, setValues, setTab }) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <FaUser className="mr-3" />
        <span>{values.name}</span>
      </div>
      <div className="flex items-center">
        {values.linkedin ? (
          <a href={values.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline flex items-center">
            <FaLinkedin className="mr-3" /> {values.linkedin_pseudo}
          </a>
        ) : (
          <span className="text-gray-500">No LinkedIn profile</span>
        )}
      </div>
      <div className="flex items-center">
        <FaGlobe className="mr-3" />
        {values.website ? (
          <a href={values.website.startsWith('http') ? values.website : `http://${values.website}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            {values.website}
          </a>
        ) : (
          <span className="text-gray-500">No website</span>
        )}
      </div>
      {values.personnae && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.personnae}</span>
        </div>
      )}
      {values.stage && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.stage}</span>
        </div>
      )}
      {values.source_name && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <span>{values.source_name}</span>
        </div>
      )}
      {values?.linkedin_raw?.employees && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <div>{values.linkedin_raw.employees} employees</div>
        </div>
      )}
      <div>
        <p className="text-xs text-gray-600">{values.description || "No description"}</p>
      </div>
    </div>
  );
};

const EditTab = ({ values, setValues, company, setCompany, setTab }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.put(`/s_company/${company._id}`, values);
      setCompany(data);
      toast.success("Updated!");
      setTab(TABS.view);
    } catch (error) {
      console.error(error);
      toast.error("Some Error!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="col-span-2">
      <div className="flex items-center">
        <FaUser className="mr-3" />
        <input className="input w-full" type="text" name="name" value={values.name} placeholder="Enter name" onChange={(e) => setValues({ ...values, name: e.target.value })} />
      </div>
      <div className="flex items-center">
        {!values.linkedin ? (
          <FaLinkedin className="mr-3" />
        ) : (
          <a href={`${values.linkedin}`} target="_blank" className="mr-3">
            <FaLinkedin />
          </a>
        )}
        <input
          className="input w-full"
          type="text"
          name="linkedin"
          value={values.linkedin}
          placeholder="LinkedIn profile URL"
          onChange={(e) => setValues({ ...values, linkedin: e.target.value })}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center">
          {!values.website ? (
            <FaGlobe className="mr-3" />
          ) : (
            <a href={values.website.startsWith("http") ? values.website : `http://${values.website}`} target="_blank" className="mr-3">
              <FaGlobe />
            </a>
          )}
          <input
            className="input w-full"
            type="text"
            name="website"
            value={values.website}
            placeholder="Website URL"
            onChange={(e) => setValues({ ...values, website: e.target.value })}
          />
        </div>
        <div className="flex items-center gap-2">
          <FaRegBuilding className="mr-3" />
          <input type="checkbox" id="pipeline" checked={values.pipeline} onChange={(e) => setValues({ ...values, pipeline: e.target.checked })} />
          <label htmlFor="pipeline">Add to pipeline</label>
        </div>
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.personnae} onChange={(e) => setValues({ ...values, personnae: e.target.value })}>
          <option value="">Select personae</option>
          <option value="STARTUPS_EARLYSTAGE">STARTUPS_EARLYSTAGE</option>
          <option value="STARTUPS_ADVANCED">STARTUPS_ADVANCED</option>
          <option value="BUSINESS_REFERRERS">BUSINESS_REFERRERS</option>
          <option value="INCUBATORS">INCUBATORS</option>
          <option value="CORPORATES">CORPORATES</option>
          <option value="GOVERNMENT">GOVERNMENT</option>
        </select>
      </div>
      <Stage values={values} setValues={setValues} />
      <div className="flex flex-col gap-2">
        {/* <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <select className="input w-full" value={values.situation || ""} onChange={(e) => setValues({ ...values, situation: e.target.value })}>
            <option value="">Select situation</option>
            <option value="todo">Todo</option>
          </select>
        </div> */}
        <div className="flex items-center">
          <RiMoneyEuroCircleFill className="mr-3" />
          <input
            type="number"
            className="input w-full"
            placeholder="Deal amount"
            value={values.deal_amount || ""}
            onChange={(e) => setValues({ ...values, deal_amount: e.target.value ? Number(e.target.value) : null })}
          />
        </div>
      </div>
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <SelectSource
          value={{ _id: values.source_id, name: values.source_name }}
          onChange={(e) => {
            console.log(e);
            setValues({ ...values, source_id: e._id, source_name: e.name });
          }}
        />
      </div>
      {values?.linkedin_raw?.employees && (
        <div className="flex items-center">
          <FaRegBuilding className="mr-3" />
          <div>{values?.linkedin_raw?.employees} employees</div>
        </div>
      )}
      <div className="w-full md:w-[260px] mt-2">
        <div className="text-sm text-[#212325] font-medium">Lead dev</div>
        <SelectUser
          value={{ _id: values.lead_user_id, name: values.lead_user_name, avatar: values.lead_user_avatar }}
          userName={values.lead_user_name}
          name="lead"
          onChange={(e) => {
            if (!e) return setValues({ ...values, lead_user_id: "", lead_user_name: "", lead_user_avatar: "", lead_user_email: "" });
            setValues({ ...values, lead_user_id: e._id, lead_user_name: e.name, lead_user_avatar: e.avatar, lead_user_email: e.email });
          }}
        />
      </div>
      <div className="col-span-2">
        <label className="block text-xs font-medium text-gray-700" htmlFor="description">
          Description
        </label>
        <textarea rows={4} className="input w-full" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
      </div>

      <Campaigns company={company} setCompany={setCompany} />

      <div className="flex gap-2">
        <button className="btn blue-btn" type="submit">
          Save
        </button>
        <button
          className="btn red-btn"
          type="button"
          onClick={() => {
            setValues(company);
            setTab(TABS.view);
          }}>
          Cancel
        </button>
      </div>
    </form>
  );
};

const Stage = ({ values, setValues }) => {
  if (values.personnae === "STARTUPS_EARLYSTAGE") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="idea">Idea</option>
          <option value="businessPlan">Business Plan</option>
          <option value="activeUsers">Active Users</option>
        </select>
      </div>
    );
  }

  if (values.personnae === "GOVERNMENT") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="investigation">Investigation</option>
          <option value="construction">Construction</option>
          <option value="perenisation">Perenisation</option>
          <option value="acceleration">Acceleration</option>
        </select>
      </div>
    );
  }

  if (values.personnae === "STARTUPS_EARLYSTAGE") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="idea">Idea</option>
          <option value="businessPlan">Business Plan</option>
          <option value="activeUsers">Active Users</option>
        </select>
      </div>
    );
  }

  if (values.personnae === "INCUBATORS") {
    return (
      <div className="flex items-center">
        <FaRegBuilding className="mr-3" />
        <select className="input w-full" value={values.stage} onChange={(e) => setValues({ ...values, stage: e.target.value })}>
          <option value="">Select stage</option>
          <option value="prospective">Prospective</option>
          <option value="active">Active</option>
          <option value="followUpNeeded">Follow-Up Needed</option>
          <option value="lost">Lost</option>
        </select>
      </div>
    );
  }
  return <div></div>;
};

const RawTab = ({ values }) => (
  <div className="space-y-4">
    <pre className="whitespace-pre-wrap text-xs font-mono bg-gray-50 p-4 rounded">{JSON.stringify(values, null, 2)}</pre>
  </div>
);
