import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Routes, Route, NavLink } from "react-router-dom";

import FrameworkTShirt from "./FrameworkTShirt";
import FrameworkTShirt2 from "./FrameworkTShirt2";
import Planning from "./Planning";
import FrameworkNNL from "./FrameworkNNL";
import FrameworkBudget from "./FrameworkBudget";
import FrameworkNNL2 from "./FrameworkNNL2";

const Roadmap = ({ project }) => {
  const [view, setView] = useState();

  const navigate = useNavigate();

  const { id, tab } = useParams();

  useEffect(() => {
    if (tab === "nnl") return setView("NNL");
    if (tab === "tshirt") return setView("TSHIRT");
    if (tab === "stats") return setView("STATS");
    if (tab === "budget") return setView("BUDGET");
    if (tab === "planning") return setView("PLANNING");
    setView("NNL");
  }, [tab]);

  return (
    <div className="space-y-2">
      <nav className="flex item-center gap-2 overflow-x-auto">
        <TabItem href="" title="Now,Next,Later" />
        <TabItem href="tshirt" title="Tshirt Sizing" />
        <TabItem href="nnl2" title="Now,Next,Later2" />
        <TabItem href="tshirt2" title="Tshirt Sizing2" />
        <TabItem href="budget" title="Budget" />
        <TabItem href="stats" title="Stats" />
        <TabItem href="planning" title="Planning" />
      </nav>

      <Routes>
        <Route exact path="" element={<FrameworkNNL project={project} />} />
        <Route path="nnl2" element={<FrameworkNNL2 project={project} />} />
        <Route path="tshirt" element={<FrameworkTShirt project={project} />} />
        <Route path="tshirt2" element={<FrameworkTShirt2 project={project} />} />
        <Route path="stats" element={<div>Stats</div>} />
        <Route path="planning" element={<Planning project={project} />} />
        <Route path="budget" element={<FrameworkBudget project={project} />} />
      </Routes>
    </div>
  );
};

const TabItem = ({ href, title }) => {
  const { id } = useParams();
  return (
    <NavLink to={`/project/${id}/roadmap${href ? `/${href}` : ""}`} end className={({ isActive }) => (isActive ? "blue-btn py-1 px-2" : "transparent-btn py-1 px-2")}>
      {title}
    </NavLink>
  );
};

export default Roadmap;
