import React, { useState } from 'react';
import api from "../../../services/api";

const statusColors = {
  "no_status": "bg-blue-500 text-white",
  "pending": "bg-yellow-500 text-black",
  "win": "bg-green-500 text-white",
  "lost": "bg-red-500 text-white",
  "to_respond": "bg-orange-500 text-white",
  "lead": "bg-yellow-500 text-white",
};

const statuses = ["no_status", "to_respond", "pending", "win","lost","lead"];

export default function StatusTags({ status = 'no_status', missionId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shownStatus, setShownStatus] = useState(status);

  async function updateMissionStatus(newStatus) {
  try {
    setIsLoading(true);
    const {ok} =  await api.put(`/mission_ao/status/${missionId}`, {status: newStatus});

    if (ok) {
      setIsEditing(false);
      console.log("Status updated:", result);
    } else {
      console.error("Failed to update status:", result.error);
    }
  } catch (error) {
    console.error("Error:", error);
  } finally {
        setIsLoading(false);
  }
}

  const handleStatusChange = async(e) => {
    const newStatus = e.target.value;
    await updateMissionStatus(newStatus);
    setShownStatus(newStatus)
  };

  return (
    <div className="col-span-2 flex items-center gap-2">
      {isEditing ? (
        <select
          value={status}
          onChange={handleStatusChange}
          className="p-1 border border-gray-300 rounded-md text-sm"
        >
          {statuses.map((s) => (
            <option key={s} value={s}>
              {s.replace("_", " ").toUpperCase()}
            </option>
          ))}
        </select>
      ) : (
        <div
          className={`px-3 py-1 rounded-md text-sm cursor-pointer ${statusColors[shownStatus]}`}
          onClick={() => setIsEditing(true)}
        >
          {shownStatus.replace("_", " ").toUpperCase()}
        </div>
      )}
    </div>
  );
}
